import {combineReducers, Reducer} from 'redux';
import {StoreState} from './types';
import {inicioReducer} from "routes/inicio/reducers";
import {accesoReducer} from "routes/acceso/reducers";
import {accesoVerificarReducer} from "routes/acceso-verificar/reducers";
import {formPerfilReducer} from "components/form-perfil/reducers";
import {adminSuperadminPermisosReducer} from "routes/admin-superadmin-permisos/reducers";
import {modalUsuarioBusquedaReducer} from "components/modal-usuario-busqueda/reducers";
import {adminEventosReducer} from "routes/admin-eventos/reducers";
import {formEventoAdminReducer} from "components/form-evento-admin/reducers";
import {adminReservasReducer} from "routes/admin-reservas/reducers";
import {drawerSelectorEventoReducer} from "components/drawer-selector-evento/reducers";
import {modalConfirmarReducer} from "components/modal-confirmar/reducers";
import {adminVentasReducer} from "routes/admin-ventas/reducers";
import {lectorQrReducer} from "components/lector-qr/reducers";
import {modalReservarReducer} from "components/modal-reservar/reducers";
import {modalVenderReducer} from "components/modal-vender/reducers";
import {adminMesaEnlacesReducer} from "routes/admin-mesa-enlaces/reducers";
import {adminMesaMesasReducer} from "routes/admin-mesa-mesas/reducers";
import {eventosReducer} from "routes/eventos/reducers";
import {eventoReducer} from "routes/evento/reducers";
import {reservasReducer} from "routes/reservas/reducers";

export const rootReducer: Reducer = combineReducers<StoreState>({
  // Rutas
  inicio: inicioReducer,
  acceso: accesoReducer,
  eventos: eventosReducer,
  evento: eventoReducer,
  reservas: reservasReducer,
  accesoVerificar: accesoVerificarReducer,
  adminSuperadminPermisos: adminSuperadminPermisosReducer,
  adminEventos: adminEventosReducer,
  adminReservas: adminReservasReducer,
  adminVentas: adminVentasReducer,
  adminMesaEnlaces: adminMesaEnlacesReducer,
  adminMesaMesas: adminMesaMesasReducer,

  // Componentes
  drawerSelectorEvento: drawerSelectorEventoReducer,
  formEventoAdmin: formEventoAdminReducer,
  formPerfil: formPerfilReducer,
  lectorQr: lectorQrReducer,
  modalConfirmar: modalConfirmarReducer,
  modalReservar: modalReservarReducer,
  modalUsuarioBusqueda: modalUsuarioBusquedaReducer,
  modalVender: modalVenderReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
