import React from "react";
import {AccesoRegistroCustomProps, FormRegistroProps, FormRegistroState} from "./types";
import {Button, DatePicker, Form, FormInstance, Input, notification, Select} from "antd";
import {withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {compose} from "redux";
import {Endpoints, post} from "utils/http";
import moment from "moment";
import {parseIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js/max";

class FormRegistro extends React.Component<FormRegistroProps, FormRegistroState> {
  constructor(props: FormRegistroProps) {
    super(props);

    this.onRegistro = this.onRegistro.bind(this);
  }

  private FORM_REGISTRO = React.createRef<FormInstance>();

  private onRegistro() {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      return false;
    }

    this.props.cambiarCargando(true).then(() => {
      executeRecaptcha('acceso_registro').then(token => {
        let form = this.FORM_REGISTRO.current;
        let fecha = form?.getFieldValue('fecha_nacimiento').format("YYYY-MM-DD");
        let telefono = parsePhoneNumber("+" + form?.getFieldValue("prefijo") + form?.getFieldValue("telefono"));

        post(Endpoints.ACCESO_REGISTRO, {
          'nombre': form?.getFieldValue('nombre'),
          'apellidos': form?.getFieldValue('apellidos'),
          'dni': form?.getFieldValue('dni'),
          'email': form?.getFieldValue('email'),
          'fecha_nacimiento': fecha,
          'telefono': telefono.formatInternational(),
          'recaptcha': token,
        })
          .then(res => this.props.cambiarCargando(false).then(() => {
            notification.success({
              message: 'La cuenta ha sido creada',
              description: 'Te hemos enviado un correo electrónico para activarla.',
              placement: 'topRight'
            });
          }))
          .catch(res => this.props.cambiarCargando(false).then(() => {
            notification.error({
              message: 'Error en el registro',
              description: res.mensaje,
              placement: 'topRight'
            });
          }));
      });
    });
  }

  render() {
    return <>
      <Form
        name="registro" labelCol={{span: 8}} wrapperCol={{span: 16}}
        size="large" onFinish={this.onRegistro} initialValues={{prefijo: "34"}} ref={this.FORM_REGISTRO}
      >
        <Form.Item
          label="Nombre Completo" name="nombre_completo" style={{marginBottom: 0}} required={true}
        >
          <Form.Item
            style={{display: 'inline-block', width: 'calc(35% - 12px)'}} name="nombre"
            rules={[{required: true, message: 'Introduce tu nombre'}]} hasFeedback
          >
            <Input placeholder="Nombre"/>
          </Form.Item>
          <span style={{display: 'inline-block', width: '24px'}}/>
          <Form.Item
            style={{display: 'inline-block', width: 'calc(65% - 12px)'}} name="apellidos"
            rules={[{required: true, message: 'Introduce tu nombre'}]} hasFeedback
          >
            <Input placeholder="Apellidos"/>
          </Form.Item>
        </Form.Item>

        {/* TODO: Validar DNI */}
        <Form.Item
          label="DNI / NIE / Pasaporte" name="dni" hasFeedback
          rules={[{required: true, message: 'Introduce tu DNI, NIE o Pasaporte'}]}
        >
          <Input placeholder="12346578Z"/>
        </Form.Item>

        <Form.Item
          label="Correo Electrónico" name="email" hasFeedback
          rules={[
            {required: true, message: 'Introduce tu correo electrónico'},
            {type: "email", message: 'Introduce un email válido'}
          ]}
        >
          <Input placeholder="Email"/>
        </Form.Item>

        <Form.Item
          label="Fecha de Nacimiento" rules={[{required: true, message: 'Introduce tu fecha de nacimiento'}]}
          name="fecha_nacimiento" hasFeedback
        >
          <DatePicker
            style={{width: "100%"}} allowClear={false}
            disabledDate={current => current && current > moment().endOf('day')}
            format="DD/MM/YYYY" showToday={false} showTime={false}
          />
        </Form.Item>

        <Form.Item
          name="telefono" label="Número de Móvil" hasFeedback
          rules={[
            {
              validator: (rule: any, value: string) => {
                let prefijo = this.FORM_REGISTRO.current?.getFieldValue("prefijo");
                try {
                  let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + value));
                  if (!numero || !numero.isValid()) throw new Error('Número no válido');
                  return Promise.resolve();
                } catch (error) {}
                return Promise.reject();
              }, message: 'Introduce un número de móvil válido', required: true
            }
          ]}
        >
          <Input addonBefore={<Form.Item noStyle name="prefijo">
            <Select style={{width: 100}}>
              <Select.Option value="34">+34</Select.Option>
            </Select>
          </Form.Item>} style={{width: '100%'}} onChange={e => {
            let prefijo = this.FORM_REGISTRO.current?.getFieldValue("prefijo");
            try {
              let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + e.target.value));
              if (numero) {
                this.FORM_REGISTRO.current?.setFieldsValue({telefono: numero.formatNational()});
              }
            } catch (error) {

            }
          }}/>
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit" style={{marginTop: 20}}>
            Registrarse
          </Button>
        </Form.Item>
      </Form>
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha)(FormRegistro) as React.ComponentType<AccesoRegistroCustomProps>;
