import React from "react";
import {AccesoResetCustomProps, FormResetProps, AccesoResetState} from "./types";
import {Button, Form, FormInstance, Input, notification, Select} from "antd";
import {withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {compose} from "redux";
import {Endpoints, post} from "utils/http";
import AppRoutes from "routes";

class FormReset extends React.Component<FormResetProps, AccesoResetState> {
  constructor(props: FormResetProps) {
    super(props);

    this.onResetear = this.onResetear.bind(this);
  }

  private FORM_RESET = React.createRef<FormInstance>();

  private onResetear() {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      return false;
    }

    this.props.cambiarCargando(true).then(() => {
      executeRecaptcha('acceso_clave').then(token => {
        let form = this.FORM_RESET.current;

        // TODO: PIN de Eventos
        post(Endpoints.ACCESO_CLAVE, {
          'dni': form?.getFieldValue('dni'),
          'recaptcha': token,
        })
          .then(res => this.props.cambiarCargando(false).then(() => {
            notification.success({
              message: 'Se ha solicitado el cambio de contraseña',
              description: 'Te hemos enviado un correo electrónico para cambiarla.',
              placement: 'topRight'
            });
            this.props.history.push(AppRoutes.INICIO);
          }))
          .catch(res => this.props.cambiarCargando(false).then(() => {
            notification.error({
              message: 'Error en el cambio de contraseña',
              description: res.mensaje,
              placement: 'topRight'
            });
          }));
      });
    });
  }

  render() {
    return <>
      <Form
        name="reset" labelCol={{span: 8}} wrapperCol={{span: 16}}
        size="large" onFinish={this.onResetear} ref={this.FORM_RESET}
      >
        <Form.Item
          label="DNI / NIE / Pasaporte" name="dni"
          rules={[{required: true, message: 'Introduce tu DNI, NIE o Pasaporte'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Evento" name="evento" tooltip="Selecciona el evento si no tienes cuenta con contraseña"
        >
          <Select allowClear>
            {/* TODO: Poner eventos visibles */}
            <Select.Option value="uuid1">Evento 1</Select.Option>
            <Select.Option value="uuid2">Evento 2</Select.Option>
            <Select.Option value="uuid3">Evento 3</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit">
            Resetear
          </Button>
        </Form.Item>
      </Form>
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha)(FormReset) as React.ComponentType<AccesoResetCustomProps>;
