import React from "react";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Endpoints, get} from "utils/http";
import {Card, Col, Divider, List, notification, Pagination, Row, Skeleton, Typography} from "antd";
import {Reserva} from "types/eventos";
import AppRoutes from "routes/index";
import {ReservasProps, ReservasState, RespuestaDTO} from "routes/reservas/types";
import {initialState, reservasReducer} from "routes/reservas/reducers";
import {cambiarCargando, cambiarMuestra, cambiarPagina, CONNECTOR, guardarDatos} from "routes/reservas/actions";
import {getEstadoReserva} from "routes/admin-reservas";

class Reservas extends React.Component<ReservasProps, ReservasState> {
  constructor(props: ReservasProps) {
    super(props);
    this.state = initialState;

    this.renderReservas = this.renderReservas.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);
  }

  private renderReservas(e: Reserva[], titulo: string) {
    if (e.length === 0)
      return <></>;

    return <>
      <Divider orientation="left">{titulo}</Divider>
      <Card bodyStyle={{padding: 0}}>
        <List
          bordered
          itemLayout="horizontal"
          dataSource={e}
          renderItem={(reserva, key) => {
            return <Link to={AppRoutes.EVENTO.replace(":uuid", reserva.evento.grupo?.id || "")} key={key}>
              <List.Item key={key}>
                <List.Item.Meta
                  title={<Typography>
                    {getEstadoReserva(reserva)} {reserva.evento.nombre} <Typography.Text type="secondary">
                    x{reserva.numEntradas} Entrada{reserva.numEntradas === 1 ? "" : "s"}
                  </Typography.Text>
                  </Typography>}
                  description={<Typography>
                    <Typography.Paragraph style={{marginBottom: 0}}>
                      Reserva hecha {reserva.fecha.local().fromNow()} <Typography.Text
                      type="secondary">{reserva.fecha.local().format("llll")}</Typography.Text>
                    </Typography.Paragraph>
                    {reserva.fechaAsignadas === null ? <></> : <Typography.Paragraph style={{marginBottom: 0}}>
                      Entradas asignadas {reserva.fechaAsignadas.local().fromNow()} <Typography.Text
                      type="secondary">{reserva.fechaAsignadas.local().format("llll")}</Typography.Text>
                    </Typography.Paragraph>}
                  </Typography>}
                />
              </List.Item>
            </Link>;
          }}
        />
      </Card>
    </>;
  }

  private cargarDatos() {
    get(`${Endpoints.RESERVAS}?page=${this.state.page}&size=${this.state.size}`)
      .then((res: RespuestaDTO) => {
        let state = reservasReducer(this.state, guardarDatos(res.reservas, res.total));
        this.setState(reservasReducer(state, cambiarCargando(false)));
      })
      .catch(e => notification.error({message: 'Error descargando los datos', description: e.mensaje}));
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return <Row>
      <Col span={24}>
        <Skeleton loading={this.state.cargando} active avatar/>
        {this.renderReservas(this.state.reservas.futuras, "Reservas de Eventos Futuros")}
        {this.renderReservas(this.state.reservas.enCurso, "Reservas de Eventos En Curso")}
        {this.renderReservas(this.state.reservas.completadas, "Reservas de Eventos Completados")}
        <Pagination
          showQuickJumper
          showSizeChanger
          hideOnSinglePage
          current={this.state.page}
          pageSize={this.state.size}
          total={this.state.total}
          onChange={(page, pageSize) => {
            let state = reservasReducer(this.state, cambiarPagina(page));
            state = reservasReducer(state, cambiarMuestra(pageSize));
            this.setState(reservasReducer(state, cambiarCargando(true)), () => this.cargarDatos());
          }}
          style={{marginTop: 25}}
        />
      </Col>
    </Row>;
  }
}

export default compose(withRouter, withGoogleReCaptcha, CONNECTOR)(Reservas) as React.ComponentType;
