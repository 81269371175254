import React from "react";
import {AdminSuperadminPermisosProps, AdminSuperadminPermisosState} from "./types";
import {withRouter} from "react-router-dom";
import {Button, Card, notification, Switch, Table} from "antd";
import {Permisos, PermisoSuper} from "utils/permisos";
import {adminSuperadminPermisosReducer, initialState} from "./reducers";
import {cambiarCargando, cambiarNuevoVisible, CONNECTOR, guardarPermisos} from "./actions";
import {Endpoints, get, post} from "utils/http";
import AppRoutes from "routes";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import ModalUsuario from "components/modal-usuario-busqueda";
import assert from "assert";

class AdminSuperadminPermisos extends React.Component<AdminSuperadminPermisosProps, AdminSuperadminPermisosState> {
  constructor(props: AdminSuperadminPermisosProps) {
    super(props);
    this.state = initialState;

    this.cargarDatos = this.cargarDatos.bind(this);
    this.mapearDatos = this.mapearDatos.bind(this);
    this.onPermisoChange = this.onPermisoChange.bind(this);
  }

  private cargarDatos() {
    get(Endpoints.ADMIN_PERMISOS)
      .then(res => {
        let state = adminSuperadminPermisosReducer(this.state, cambiarCargando(false));
        state = adminSuperadminPermisosReducer(state, guardarPermisos(res));
        this.setState(state);
      })
      .catch(res => {
        notification.error({
          message: 'Error',
          description: res.mensaje
        })
        this.props.history.push(AppRoutes.ADMIN);
      });
  }

  private static sortStrings(a: string, b: string): number {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  }

  private mapearDatos() {
    let a = this.state.datos.map(d => {
      let out: any = {'key': d.id, 'nombre': d.nombre};
      if (!d.permisos) d.permisos = [];
      Permisos.filter(p => p !== PermisoSuper).forEach(p => {
        out[p.permiso] = d.permisos.indexOf(p.permiso) > -1;
      });
      return out;
    });
    a.sort((a, b) => AdminSuperadminPermisos.sortStrings(a.nombre, b.nombre));
    return a;
  }

  private onPermisoChange(usuario: string, permiso: string, nuevoEstado: boolean) {
    this.setState(adminSuperadminPermisosReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_PERMISOS, {
        accion: nuevoEstado ? "NUEVO" : "ELIMINAR",
        usuario: usuario,
        permiso: permiso
      })
        .then(res => this.cargarDatos())
        .catch(res => {
          notification.error({
            message: 'Error',
            description: res.mensaje
          });
          this.cargarDatos();
        });
    });
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return <>
      <Card>
        <Table
          loading={this.state.cargando}
          dataSource={this.mapearDatos()}
          pagination={false}
          columns={[
            {title: 'Usuario', dataIndex: 'nombre', key: 'nombre', fixed: 'left'},
            ...Permisos.filter(p => p !== PermisoSuper).map(permiso => {
              return {
                align: 'center' as any,
                title: permiso.nombre,
                dataIndex: permiso.permiso,
                key: permiso.permiso,
                render: (tiene: boolean, record: any) => {
                  return <Switch
                    checked={tiene}
                    onClick={() => this.onPermisoChange(record.key, permiso.permiso, !tiene)}
                  />;
                }
              }
            })
          ]}
          title={() => <Button
            type="primary" shape="round" icon={<Icon path={MDI.mdiAccountPlus}/>}
            onClick={() => this.setState(adminSuperadminPermisosReducer(this.state, cambiarNuevoVisible()))}
          >
            Añadir Admin
          </Button>}
        />
      </Card>

      <ModalUsuario tipo="usuario" visible={this.state.nuevo} onCancelar={() => {
        this.setState(adminSuperadminPermisosReducer(this.state, cambiarNuevoVisible()));
      }} onSeleccion={datos => {
        let state = adminSuperadminPermisosReducer(this.state, cambiarNuevoVisible());
        state = adminSuperadminPermisosReducer(state, cambiarCargando(true));
        this.setState(state, () => {
          assert('id' in datos);
          post(Endpoints.ADMIN_PERMISOS, {accion: "NUEVO", usuario: datos.id})
            .then(res => this.cargarDatos())
            .catch(res => {
              notification.error({
                message: 'Error creando el nuevo admin',
                description: res.mensaje
              });
              this.cargarDatos();
            });
        });
      }}/>
    </>;
  }
}

export default CONNECTOR(withRouter(AdminSuperadminPermisos));
