import {
  CAMBIAR_CARGANDO,
  CAMBIAR_MUESTRA,
  CAMBIAR_PAGINA,
  GUARDAR_DATOS,
  ReservasActionTypes,
  ReservasState
} from "routes/reservas/types";
import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {Reserva} from "types/eventos";

export function cambiarCargando(cargando: boolean): ReservasActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarDatos(datos: Reserva[], total: number): ReservasActionTypes {
  return {
    type: GUARDAR_DATOS,
    payload: {datos, total}
  }
}

export function cambiarPagina(page: number): ReservasActionTypes {
  return {
    type: CAMBIAR_PAGINA,
    payload: {page}
  }
}

export function cambiarMuestra(size: number): ReservasActionTypes {
  return {
    type: CAMBIAR_MUESTRA,
    payload: {size}
  }
}

const mapState = (state: RootState): ReservasState => ({
  cargando: state.reservas.cargando,

  reservas: {
    futuras: state.reservas.reservas.futuras,
    enCurso: state.reservas.reservas.enCurso,
    completadas: state.reservas.reservas.completadas,
  },
  total: state.reservas.total,
  page: state.reservas.page,
  size: state.reservas.size,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarDatos: guardarDatos,
  cambiarPagina: cambiarPagina,
  cambiarMuestra: cambiarMuestra,
};

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;

