import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";
import {RouteComponentProps} from "react-router-dom";
import {EventoGrupo} from "types/eventos";
import {PropsFromRedux} from "routes/eventos/actions";

export interface EventosProps extends PropsFromRedux, IWithGoogleReCaptchaProps, RouteComponentProps {

}

export interface EventosState {
  cargando: boolean;
  eventos: {
    futuros: EventoGrupo[];
    enCurso: EventoGrupo[];
    completados: EventoGrupo[];
  };

  total: number;
  size: number;
  page: number;
}

export interface RespuestaDTO {
  total: number;
  eventos: EventoGrupo[];
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_EVENTOS = 'GUARDAR_EVENTOS';

export const CAMBIAR_PAGINA = 'CAMBIAR_PAGINA';
export const CAMBIAR_MUESTRA = 'CAMBIAR_MUESTRA';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando: boolean };
}

interface GuardarEventosAction {
  type: typeof GUARDAR_EVENTOS;
  payload: { total: number; eventos: EventoGrupo[]; };
}

interface CambiarPaginaAction {
  type: typeof CAMBIAR_PAGINA;
  payload: { page: number };
}

interface CambiarMuestraAction {
  type: typeof CAMBIAR_MUESTRA;
  payload: { size: number };
}

export type EventosActionTypes = CambiarCargandoAction
  | GuardarEventosAction
  | CambiarPaginaAction
  | CambiarMuestraAction;
