import {CAMBIAR_CARGANDO, GUARDAR_GRUPO, ModalVenderActionTypes, ModalVenderState} from "components/modal-vender/types";

export const initialState: ModalVenderState = {
  cargando: true,
  grupo: null
};


export function modalVenderReducer(state = initialState, action: ModalVenderActionTypes): ModalVenderState {
  let newState: ModalVenderState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      return newState;

    case GUARDAR_GRUPO:
      newState.grupo = action.payload.grupo || null;
      return newState;

    default:
      return state;
  }
}
