import React from "react";
import {AdminVentasProps, AdminVentasState, RespuestaDTO} from "routes/admin-ventas/types";
import {adminVentasReducer, DEFAULT_FILTROS_SIZE, initialState} from "routes/admin-ventas/reducers";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {
  cambiarCargando,
  cambiarDrawerVisible,
  cambiarLectorVisible,
  CONNECTOR,
  guardarDatos,
  guardarFiltros,
  mostrarNuevo,
  seleccionarAsistentes
} from "routes/admin-ventas/actions";
import {ColumnsType, FilterConfirmProps} from "antd/lib/table/interface";
import {Asistente, EventoGrupo} from "types/eventos";
import {
  Badge,
  Button,
  Descriptions,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Popover,
  Space,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography
} from "antd";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import assert from "assert";
import AppRoutes from "routes/index";
import {Endpoints, get, post} from "utils/http";
import DrawerSelectorEvento from "components/drawer-selector-evento";
import {getEstadoReserva} from "routes/admin-reservas";
import PopoverUsuario from "components/popover-usuario";
import LectorQr from "components/lector-qr";
import ModalVender from "components/modal-vender";


class AdminVentas extends React.Component<AdminVentasProps, AdminVentasState> {
  constructor(props: AdminVentasProps) {
    super(props);
    this.state = initialState;

    this.isFiltraEvento = this.isFiltraEvento.bind(this);
    this.cargarDniTmp = this.cargarDniTmp.bind(this);
    this.getColumnas = this.getColumnas.bind(this);
    this.getTituloTabla = this.getTituloTabla.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);

    this.onAceptarDrawer = this.onAceptarDrawer.bind(this);
    this.onCancelarDrawer = this.onCancelarDrawer.bind(this);

    this.onLeerQr = this.onLeerQr.bind(this);
    this.onCancelarLector = this.onCancelarLector.bind(this);

    this.onNotas = this.onNotas.bind(this);
    this.onPagar = this.onPagar.bind(this);
    this.onEntregar = this.onEntregar.bind(this);
    this.onCancelar = this.onCancelar.bind(this);

    this.onPagarBulk = this.onPagarBulk.bind(this);
    this.onEntregarBulk = this.onEntregarBulk.bind(this);
    this.onCancelarBulk = this.onCancelarBulk.bind(this);
    this.onNotificarBulk = this.onNotificarBulk.bind(this);
  }

  private setDni: (selectedKeys: React.Key[]) => void = selectedKeys => {
  };
  private setDniConfirm: (param?: FilterConfirmProps) => void = () => {
  };

  private isFiltraEvento(): boolean {
    return this.props.match.params.uuid !== undefined;
  }

  private cargarDniTmp(): boolean {
    if (this.props.location.hash === "") return false;
    const hash = this.props.location.hash.substring(1);
    if (hash === "") return false;
    this.setDni([hash]);
    window.location.hash = "";
    return true;
  }

  componentDidMount() {
    if (this.cargarDniTmp()) this.setDniConfirm();
    else this.cargarDatos();
  }

  private getColumnas(): ColumnsType<Asistente> {
    return [
      {
        title: 'Usuario / Invitado',
        dataIndex: 'usuario',
        key: 'usuario',
        render: (texto: string, record: Asistente) => <PopoverUsuario usuario={record.usuario || record.invitado}/>,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
          this.setDni = setSelectedKeys;
          this.setDniConfirm = confirm;
          return <div style={{padding: 8}}>
            <Tooltip title="Puedes buscar por Nombre, Apellidos, DNI, Email o Teléfono">
              <Input
                placeholder="Buscar..."
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{marginBottom: 8, display: 'block'}}
              />
            </Tooltip>
            <Space align="center" size="large">
              <Button size="small" block onClick={() => {
                if (clearFilters) clearFilters();
                confirm();
              }}>
                Resetear
              </Button>
              <Button
                type="primary" icon={<Icon path={MDI.mdiMagnify} size={0.8}/>} size="small"
                block onClick={() => confirm()}
              >
                Buscar
              </Button>
            </Space>
          </div>;
        },
        filterIcon: filtered => <Icon
          path={MDI.mdiMagnify} style={{color: filtered ? '#1890ff' : undefined}} size={0.8}
        />
      },
      {
        title: 'Evento',
        dataIndex: ['evento', 'nombre'],
        key: 'evento',
        render: (texto: string, record: Asistente) => <Tooltip title={record.evento.grupo?.nombre}>
          {record.evento.nombre}
        </Tooltip>
      },
      {
        title: 'Fecha Confirmación',
        dataIndex: 'fecha',
        key: 'fecha',
        render: (texto: string, record: Asistente, index: number) => {
          return <Popover
            title={record.fecha.local().fromNow()}
            content={<Typography.Text code>{record.fecha.toISOString()}</Typography.Text>}
          >
            {record.fecha.local().format("DD/MM/YYYY HH:mm")}
          </Popover>
        },
        sorter: (a: Asistente, b: Asistente) => 0
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        align: 'center',
        render: (texto: string, record: Asistente, index: number) => AdminVentas.getEstado(record),
        filters: [
          // {text: 'EN COLA', value: 'en_cola'},
          // {text: 'ASIGNADA', value: 'asignada'},
          // TODO: Resto de filtros? Quizás es mejor dejar que no se filtre, ya que no influye a reservas
          //       que esté pagado o no.
          {text: 'CONFIRMADA', value: 'confirmada'},
          {text: 'PAGADA', value: 'pagada'},
          {text: 'ENTREGADA', value: 'entregada'},
        ],
      },
      {
        title: '',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        render: (texto: string, record: Asistente) => <>
          <Popover title="Notas" content={<Typography.Text italic>{record.notas}</Typography.Text>}>
            <Badge dot count={record.notas === "" ? 0 : 1} offset={[-10, 5]}>
              <Button
                icon={<Icon path={MDI.mdiNotebookEdit} style={{marginBottom: 0, marginLeft: 3}}/>}
                style={{marginRight: 5}} type="link"
                onClick={() => this.onNotas(record)}
              />
            </Badge>
          </Popover>

          <Tooltip title="Pagar Entrada"><Button
            icon={<Icon
              path={MDI.mdiTicketPercent}
              style={{marginBottom: 0, marginLeft: 3}}
            />}
            style={{marginRight: 5}} disabled={record.estado !== "confirmada"} type="primary"
            onClick={() => this.onPagar(record)}
          /></Tooltip>

          <Tooltip title={record.estado === "confirmada" ? "Pagar y Entregar Entrada" : "Entregar Entrada"}><Button
            icon={<Icon path={record.estado === "confirmada" ? MDI.mdiTicket : MDI.mdiTicketConfirmation}
                        style={{marginBottom: 0, marginLeft: 3}}/>} style={{marginRight: 5}}
            onClick={() => this.onEntregar(record)}
            disabled={!record.evento.requiereEntrega || (record.estado !== "confirmada" && record.estado !== "pagada")}
          /></Tooltip>

          <Tooltip title="Cancelar Entrada"><Button
            icon={<Icon path={MDI.mdiCancel} style={{marginBottom: 0, marginLeft: 3}}/>} type="primary" danger
            onClick={() => this.onCancelar(record)}
            disabled={record.estado === "entregada"}
          /></Tooltip>
        </>,
      }
    ];
  }

  private static getEstado(asistente: Asistente) {
    let estado = {nombre: "OCULTO", color: "default", descripcion: ""};
    switch (asistente.estado) {
      case "confirmada":
        estado = {nombre: "CONFIRMADA", color: "green", descripcion: "Se ha confirmado el asistente"};
        break;
      case "pagada":
        assert(asistente.fechaPago !== null);
        estado = {
          nombre: "PAGADA",
          color: "blue",
          descripcion: "Se ha pagado la entrada " + asistente.fechaPago.local().fromNow()
        };
        break;
      case "entregada":
        assert(asistente.fechaEntrega !== null);
        estado = {
          nombre: "ENTREGADA",
          color: "purple",
          descripcion: "Se ha entregado la entrada física " +
            (asistente.entrada === "" ? "" : (asistente.entrada + " ")) +
            asistente.fechaEntrega.local().fromNow()
        };
        break;
    }
    return <Tooltip title={estado.descripcion}><Tag color={estado.color}>{estado.nombre}</Tag></Tooltip>;
  }

  private getTituloTabla() {
    if (this.state.seleccionados.length > 0) {
      return <Space>
        <Tooltip title="Se marcarán como pagadas las entradas seleccionadas">
          <Button
            disabled={this.state.seleccionados.find(r => r.estado !== "confirmada") !== undefined}
            shape="round" type="primary" icon={<Icon path={MDI.mdiTicketPercent}/>}
            onClick={() => this.onPagarBulk()}
          >
            Pagar Entradas
          </Button>
        </Tooltip>
        <Tooltip
          title="Se marcarán como entregadas las entradas seleccionadas">
          <Button
            disabled={this.state.seleccionados.find(r => !r.evento.requiereEntrega || (r.estado !== "confirmada" && r.estado !== "pagada")) !== undefined}
            shape="round"
            icon={<Icon
              path={this.state.seleccionados.find(r => r.estado === "confirmada") !== undefined ? MDI.mdiTicket : MDI.mdiTicketConfirmation}/>}
            onClick={() => this.onEntregarBulk()}
          >
            {this.state.seleccionados.find(r => r.estado === "confirmada") !== undefined ? "Pagar y Entregar Entradas" : "Entregar Entradas"}
          </Button>
        </Tooltip>
        <Tooltip title="Se cancelarán las entradas si no se han pagado o entregado">
          <Button
            disabled={this.state.seleccionados.find(r => r.estado === "entregada") !== undefined}
            shape="round" type="primary" danger icon={<Icon path={MDI.mdiCancel}/>}
            onClick={() => this.onCancelarBulk()}
          >
            Cancelar Entradas
          </Button>
        </Tooltip>
        <Button
          shape="round" type="primary" icon={<Icon path={MDI.mdiMessageDraw}/>}
          onClick={() => this.onNotificarBulk()}
        >
          Mandar Mensaje
        </Button>
      </Space>;
    }

    return <Space>
      <Button
        type="primary" shape="round"
        icon={<Icon path={this.isFiltraEvento() ? MDI.mdiArrowLeft : MDI.mdiCalendarArrowRight}/>}
        onClick={() => {
          if (this.isFiltraEvento()) {
            this.props.history.push(AppRoutes.ADMIN_VENTAS);
            this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
              this.cargarDatos();
            });
          } else {
            this.setState(adminVentasReducer(this.state, cambiarDrawerVisible(true)));
          }
        }}
      >
        {this.isFiltraEvento() ? "Volver" : "Filtrar Evento"}
      </Button>
      {this.isFiltraEvento() ? <Button
        type="default" shape="round"
        icon={<Icon path={MDI.mdiViewGridPlus}/>}
        onClick={() => {
          this.setState(adminVentasReducer(this.state, mostrarNuevo(true)));
        }}
      >
        Nuevo Asistente
      </Button> : <></>}
      <Button
        shape="round" icon={<Icon path={MDI.mdiQrcodeScan}/>}
        onClick={() => this.setState(adminVentasReducer(this.state, cambiarLectorVisible(true)))}
      >
        Escanear QR
      </Button>
    </Space>;
  }

  private onNotas(asistente: Asistente) {
    let u = asistente.usuario || asistente.invitado;
    assert(u !== null);

    let form = React.createRef<FormInstance>();

    Modal.info({
      title: 'Editar notas de ' + u.nombre, closable: true, maskClosable: true,
      content: <Form size="middle" ref={form} initialValues={{notas: asistente.notas}}>
        <Form.Item name="notas" style={{marginTop: 15, marginBottom: 5}}>
          <Input.TextArea/>
        </Form.Item>
      </Form>,
      onOk: () => {
        form.current?.validateFields().then(() => {
          Modal.destroyAll();
          this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
            post(Endpoints.ADMIN_VENTAS_NOTAS, {
              asistente: asistente.id,
              notas: form.current?.getFieldValue('notas') || ""
            })
              .then(res => {
                notification.success({message: 'Listo', description: 'Se ha actualizado la nota'});
                this.cargarDatos();
              })
              .catch(e => {
                notification.error({message: 'Error cambiando la nota', description: e.descripcion});
                this.setState(adminVentasReducer(this.state, cambiarCargando(false)));
              })
          });
        });
        return true;
      }
    });
  }

  private onPagar(asistente: Asistente) {
    let u = asistente.usuario || asistente.invitado;
    assert(u !== null);

    Modal.success({
      title: 'Pagar entradas', closable: true, maskClosable: true,
      content: <Statistic title="Precio" value={asistente.evento.precio} suffix=" €"/>,
      onOk: () => {
        this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
          post(Endpoints.ADMIN_VENTAS_PAGAR, {asistente: asistente.id})
            .then(res => {
              notification.success({message: 'Listo', description: 'Se ha pagado la entrada'});
              this.cargarDatos();
            })
            .catch(e => {
              notification.error({message: 'Error pagando la entrada', description: e.descripcion});
              this.setState(adminVentasReducer(this.state, cambiarCargando(false)));
            })
        });
      }
    });
  }

  private onEntregar(asistente: Asistente) {
    let form = React.createRef<FormInstance>();

    Modal.success({
      title: asistente.estado === "confirmada" ? 'Pagar y Entregar entrada' : 'Entregar entrada',
      closable: true, maskClosable: true,
      content: <>
        {asistente.estado === "confirmada" ? <Statistic
          title="Precio" value={asistente.evento.precio} suffix=" €" style={{marginBottom: 15}}
        /> : <></>}
        <Form size="middle" ref={form}>
          <Form.Item
            label="# de Entrada" name="entrada"
            tooltip="Si es una entrada física, puedes indicar el número o identificador de la entrada"
          >
            <Input placeholder="####"/>
          </Form.Item>
        </Form>
      </>,
      onOk: () => {
        form.current?.validateFields().then(() => {
          Modal.destroyAll();
          this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
            post(Endpoints.ADMIN_VENTAS_ENTREGAR, {
              asistente: asistente.id,
              entrada: form.current?.getFieldValue('entrada') || ""
            })
              .then(res => {
                notification.success({message: 'Listo', description: 'Se ha entregado la entrada'});
                this.cargarDatos();
              })
              .catch(e => {
                notification.error({message: 'Error entregando la entrada', description: e.descripcion});
                this.setState(adminVentasReducer(this.state, cambiarCargando(false)));
              })
          });
        });
        return true;
      }
    });
  }

  private onCancelar(asistente: Asistente) {
    let u = asistente.usuario || asistente.invitado;
    assert(u !== null);

    Modal.error({
      title: 'Cancelar entrada de ' + u.nombre, closable: true, maskClosable: true,
      content: <Typography.Text>
        <Typography.Text strong>¿Estás seguro de querer cancelar la entrada?</Typography.Text> Quedará un registro de
        la acción, con el momento y el administrador.
      </Typography.Text>,
      onOk: () => {
        this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
          post(Endpoints.ADMIN_VENTAS_CANCELAR, {asistente: asistente.id})
            .then(res => {
              notification.success({message: 'Listo', description: 'Se ha cancelado la entrada'});
              this.cargarDatos();
            })
            .catch(e => {
              notification.error({message: 'Error cancelando la entrada', description: e.descripcion});
              this.setState(adminVentasReducer(this.state, cambiarCargando(false)));
            });
        });
      }
    });
  }

  private onPagarBulk() {
    let eventos: any = {};

    this.state.seleccionados.forEach(r => {
      if (!(("" + r.evento.id) in eventos)) {
        eventos["" + r.evento.id] = 0;
      }

      eventos["" + r.evento.id] += r.evento.precio;
    });

    Modal.success({
      title: 'Pagar ' + this.state.seleccionados.length + ' entrada' + (this.state.seleccionados.length !== 1 ? "s" : ""),
      closable: true, maskClosable: true,
      content: <Descriptions bordered>
        {Object.entries(eventos).map(k => {
          let eId = k[0], d: any = k[1];
          return <>
            <Descriptions.Item
              label={<Typography.Text strong>
                {this.state.seleccionados.find(r => r.evento.id === parseInt(eId))?.evento.nombre}
              </Typography.Text>}
              key={eId}
            >
              <Typography.Text>{d} €</Typography.Text>
            </Descriptions.Item>
          </>;
        })}
      </Descriptions>,
      onOk: () => {
        this.setState(adminVentasReducer(this.state, cambiarCargando(true)), async () => {
          for (let asistente of this.state.seleccionados) {
            try {
              await post(Endpoints.ADMIN_VENTAS_PAGAR, {asistente: asistente.id});
            } catch (e: any) {
              notification.error({
                message: 'Error pagando la entrada de ' + (asistente.usuario?.dni || asistente.invitado?.dni) + ' para ' + asistente.evento.nombre,
                description: e.descripcion
              });
              this.cargarDatos();
              break;
            }
          }

          notification.success({message: 'Listo', description: 'Se han pagado las entradas'});
          this.cargarDatos();
        });
      }
    });
  }

  private onEntregarBulk() {
    let eventos: any = {};
    let eventosPagos: any = {};

    this.state.seleccionados.forEach(r => {
      if (!(("" + r.evento.id) in eventos)) {
        eventos["" + r.evento.id] = 0;
      }
      eventos["" + r.evento.id] += 1;

      if (r.estado === "confirmada") {
        if (!(("" + r.evento.id) in eventosPagos)) {
          eventosPagos["" + r.evento.id] = 0;
        }
        eventosPagos["" + r.evento.id] += r.evento.precio;
      }
    });

    Modal.success({
      title: 'Entregar ' + this.state.seleccionados.length + ' entrada' + (this.state.seleccionados.length !== 1 ? "s" : ""),
      closable: true, maskClosable: true,
      content: <Descriptions bordered>
        {Object.entries(eventos).map(k => {
          let eId = k[0], d: any = k[1];
          return <Descriptions.Item
            label={<Typography.Text strong>
              {this.state.seleccionados.find(r => r.evento.id === parseInt(eId))?.evento.nombre}
            </Typography.Text>}
            key={eId}
          >
            <Typography.Text>
              {d} Entradas{("" + eId) in eventosPagos ? (" (" + eventosPagos["" + eId] + " €)") : ""}
            </Typography.Text>
          </Descriptions.Item>;
        })}
      </Descriptions>,
      onOk: () => {
        this.setState(adminVentasReducer(this.state, cambiarCargando(true)), async () => {
          for (let asistente of this.state.seleccionados) {
            try {
              await post(Endpoints.ADMIN_VENTAS_ENTREGAR, {asistente: asistente.id});
            } catch (e: any) {
              notification.error({
                message: 'Error entregando la entrada de ' + (asistente.usuario?.dni || asistente.invitado?.dni) + ' para ' + asistente.evento.nombre,
                description: e.descripcion
              });
              this.cargarDatos();
              break;
            }
          }

          notification.success({message: 'Listo', description: 'Se han entregado las entradas'});
          this.cargarDatos();
        });
      }
    });
  }

  private onCancelarBulk() {
    Modal.error({
      title: 'Cancelar ' + this.state.seleccionados.length + ' entrada' + (this.state.seleccionados.length === 1 ? "" : "s"),
      closable: true, maskClosable: true,
      content: <>
        <Typography.Text>
          <Typography.Text strong>¿Estás seguro de querer cancelar todas estas entradas?</Typography.Text> Quedará un
          registro de la acción, con el momento y el administrador.
        </Typography.Text>
      </>,
      onOk: () => {
        this.setState(adminVentasReducer(this.state, cambiarCargando(true)), async () => {
          for (let asistente of this.state.seleccionados) {
            try {
              await post(Endpoints.ADMIN_VENTAS_CANCELAR, {asistente: asistente.id});
            } catch (e: any) {
              notification.error({
                message: 'Error cancelando la entrada de ' + (asistente.usuario?.dni || asistente.invitado?.dni) + ' para ' + asistente.evento.nombre,
                description: e.descripcion
              });
              this.cargarDatos();
              break;
            }
          }

          notification.success({message: 'Listo', description: 'Se han cancelado las entradas'});
          this.cargarDatos();
        });
      }
    });
  }

  private onNotificarBulk() {
    let form = React.createRef<FormInstance>();

    Modal.info({
      title: 'Mandar notificaciones a los usuarios notificados',
      closable: true, maskClosable: true,
      content: <Form size="middle" ref={form}>
        <Form.Item name="mensaje" style={{marginTop: 15, marginBottom: 5}} required>
          <Input.TextArea/>
        </Form.Item>
      </Form>,
      onOk: () => {
        form.current?.validateFields().then(() => {
          Modal.destroyAll();
          this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
            post(Endpoints.ADMIN_VENTAS_NOTIFICAR, {
              asistentes: this.state.seleccionados.map(r => r.id),
              mensaje: form.current?.getFieldValue('mensaje'),
            })
              .then(res => {
                notification.success({message: 'Listo', description: 'Se han enviado las notificaciones'});
                this.cargarDatos();
              })
              .catch(e => {
                notification.error({message: 'Error enviando las notas', description: e.descripcion});
                this.setState(adminVentasReducer(this.state, cambiarCargando(false)));
              })
          });
        });
        return true;
      }
    })
  }

  private cargarDatos(
    page: number = this.state.filtros.page, size: number = this.state.filtros.size,
    sort = this.state.filtros.sort, order = this.state.filtros.order,
    filtroEstado = this.state.filtros.filtroEstado, filtroUsuario = this.state.filtros.filtroUsuario
  ) {
    let state = adminVentasReducer(this.state, guardarFiltros(page, size, sort, order, filtroEstado, filtroUsuario));
    state = adminVentasReducer(state, seleccionarAsistentes([]));
    this.setState(state, () => {
      let url = Endpoints.ADMIN_VENTAS + `?page=${page}&size=${size}`;
      if (sort) url += `&sort=${sort}&order=${order}`;
      if (this.isFiltraEvento()) url += `&evento=${this.props.match.params.uuid}`;
      if (filtroEstado && filtroEstado.length > 0) url += `&estado=${filtroEstado.join(",")}`;
      if (filtroUsuario) url += `&usuario=${encodeURIComponent(filtroUsuario)}`;
      get(url)
        .then((res: RespuestaDTO) => {
          let state = adminVentasReducer(this.state, guardarDatos(res));
          state = adminVentasReducer(state, cambiarCargando(false));
          this.setState(state);
        })
        .catch(res => {
          notification.error({
            message: 'Error',
            description: res.descripcion
          });
          this.setState(adminVentasReducer(this.state, cambiarCargando(false)));
        });
    });
  }

  private onCancelarDrawer() {
    this.setState(adminVentasReducer(this.state, cambiarDrawerVisible(false)));
  }

  private onLeerQr(datos: any) {
    this.setState(adminVentasReducer(this.state, cambiarLectorVisible(false)), () => {
      let dni: undefined | string, grupo: undefined | string;
      try {
        grupo = datos.evento.grupo;
        dni = datos.usuario?.dni || datos.invitado?.dni;
      } catch (e) {
      }

      if (dni) {
        this.setDni([dni]);
        this.setDniConfirm();
      }

      if (grupo) {
        this.props.history.push(AppRoutes.ADMIN_VENTAS_EVENTO.replace(":uuid", grupo));
        this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
          this.cargarDatos();
        });
      }
    });

  }

  private onCancelarLector() {
    this.setState(adminVentasReducer(this.state, cambiarLectorVisible(false)));
  }

  private onAceptarDrawer(e: EventoGrupo) {
    this.setState(adminVentasReducer(this.state, cambiarDrawerVisible(false)), () => {
      this.props.history.push(AppRoutes.ADMIN_VENTAS_EVENTO.replace(":uuid", e.id));
      this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
        this.cargarDatos();
      });
    });
  }

  render() {
    return <>
      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys: this.state.seleccionados.map(r => r.id),
          onChange: (key, record) => {
            this.setState(adminVentasReducer(this.state, seleccionarAsistentes(record)));
          }
        }}
        loading={this.state.cargando}
        columns={this.getColumnas()}
        dataSource={this.state.datos}
        rowClassName="ant-table-row-clickable"
        size="middle"
        title={() => this.getTituloTabla()}
        pagination={{
          total: this.state.total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} entradas confirmadas`,
          defaultPageSize: DEFAULT_FILTROS_SIZE,
        }}
        onChange={(pagination, filters, sorter) => {
          let usuario: string | undefined;
          if ('usuario' in filters && filters.usuario !== null) {
            usuario = filters.usuario.toString();
          }

          let estado: string[] = [];
          if ('estado' in filters && filters.estado !== null) {
            estado = filters.estado.map(e => e.toString());
          }

          this.setState(adminVentasReducer(this.state, cambiarCargando(true)), () => {
            if (Array.isArray(sorter)) sorter = sorter[0];
            this.cargarDatos(
              pagination.current, pagination.pageSize,
              sorter.column?.key as (string | undefined), sorter.order || undefined,
              estado, usuario
            );
          });
        }}
        expandable={{
          expandedRowRender: record => {
            let r = record.reserva;
            assert(r !== null);
            let u = r.usuario || r.invitado;
            assert(u !== null);

            return <Descriptions title="Detalles de la Reserva" layout="vertical" bordered size="small" column={5}>
              <Descriptions.Item label={<Typography.Text strong>Usuario / Invitado</Typography.Text>}>
                <PopoverUsuario usuario={u}/>
              </Descriptions.Item>
              <Descriptions.Item label={<Typography.Text strong># Entradas</Typography.Text>}>
                {r.numEntradas} Entradas
              </Descriptions.Item>
              <Descriptions.Item label={<Typography.Text strong>Fecha Reserva</Typography.Text>}>
                <Popover
                  title={r.fecha.local().fromNow()}
                  content={<Typography.Text code>{r.fecha.toISOString()}</Typography.Text>}
                >
                  {r.fecha.local().format("DD/MM/YYYY HH:mm")}
                </Popover>
              </Descriptions.Item>
              <Descriptions.Item label={<Typography.Text strong>Estado</Typography.Text>}>
                {getEstadoReserva(r)}
              </Descriptions.Item>
              <Descriptions.Item label={<Typography.Text strong>Notas</Typography.Text>}>
                <Typography.Text italic>{r.notas}</Typography.Text>
              </Descriptions.Item>
            </Descriptions>;
          },
          rowExpandable: record => record.reserva !== null,
        }}
      />

      <DrawerSelectorEvento
        visible={this.state.drawerVisible}
        onEvento={this.onAceptarDrawer} onCerrar={this.onCancelarDrawer}
      />

      <LectorQr
        visible={this.state.lectorVisible}
        onLeer={this.onLeerQr} onCancelar={this.onCancelarLector}
      />

      <ModalVender
        mostrar={this.state.nuevo}
        evento={this.props.match.params.uuid}
        onCancelar={() => this.setState(adminVentasReducer(this.state, mostrarNuevo(false)))}
        onVender={asistentes => {
          this.setState(adminVentasReducer(adminVentasReducer(
            this.state,
            cambiarCargando(true)),
            mostrarNuevo(false)), async () => {
            for (let asistente of asistentes) {
              try {
                await post(Endpoints.ADMIN_VENTAS_CREAR, asistente);
                notification.success({
                  message: "Reserva creada",
                  description: "Se ha creado el asistente del evento " + asistente.evento.nombre
                });
              } catch (e: any) {
                notification.error({
                  message: "Error creando el asistente para " + asistente.evento.nombre,
                  description: e.mensaje
                });
              }
            }

            this.cargarDatos();
          });
        }}
      />
    </>;
  }
}


export default compose(withRouter, withGoogleReCaptcha, CONNECTOR)(AdminVentas) as React.ComponentType;
