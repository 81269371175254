import {
  CAMBIAR_CARGANDO,
  GUARDAR_GRUPO,
  ModalReservarActionTypes,
  ModalReservarState
} from "components/modal-reservar/types";

export const initialState: ModalReservarState = {
  cargando: true,
  grupo: null
};


export function modalReservarReducer(state = initialState, action: ModalReservarActionTypes): ModalReservarState {
  let newState: ModalReservarState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      return newState;

    case GUARDAR_GRUPO:
      newState.grupo = action.payload.grupo || null;
      return newState;

    default:
      return state;
  }
}
