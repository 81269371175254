import {parsearFechasReservas} from "components/form-evento-admin/utils";
import {
  CAMBIAR_CARGANDO,
  CAMBIAR_MUESTRA,
  CAMBIAR_PAGINA,
  GUARDAR_DATOS,
  ReservasActionTypes,
  ReservasState
} from "routes/reservas/types";
import moment from "moment";

export const DEFAULT_FILTROS_PAGE = 1;
export const DEFAULT_FILTROS_SIZE = 50;

export const initialState: ReservasState = {
  cargando: true,

  reservas: {
    futuras: [],
    enCurso: [],
    completadas: [],
  },
  total: 0,
  page: DEFAULT_FILTROS_PAGE,
  size: DEFAULT_FILTROS_SIZE,
}

export function reservasReducer(state = initialState, action: ReservasActionTypes): ReservasState {
  let newState: ReservasState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      break;

    case GUARDAR_DATOS:
      action.payload.datos = parsearFechasReservas(action.payload.datos);
      newState.total = action.payload.total;
      newState.reservas = {futuras: [], enCurso: [], completadas: []};
      const now = moment.utc();
      for (let reserva of action.payload.datos) {
        const inicio = reserva.fecha.add(-30, 'minute');
        const fin = reserva.fecha.add(3, 'hour');
        if (inicio.isBefore(now) && fin.isAfter(now)) {
          newState.reservas.enCurso.push(reserva);
        } else if (fin.isBefore(now)) {
          newState.reservas.completadas.push(reserva);
        } else if (inicio.isAfter(now)) {
          newState.reservas.futuras.push(reserva);
        }
      }
      break;

    case CAMBIAR_PAGINA:
      newState.page = action.payload.page;
      break;

    case CAMBIAR_MUESTRA:
      newState.size = action.payload.size;
      break;

    default:
      return state;
  }

  newState.reservas.futuras = parsearFechasReservas(newState.reservas.futuras);
  newState.reservas.enCurso = parsearFechasReservas(newState.reservas.enCurso);
  newState.reservas.completadas = parsearFechasReservas(newState.reservas.completadas);
  return newState;
}
