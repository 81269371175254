import React from "react";
import {PopoverUsuarioCustomProps, PopoverUsuarioProps, PopoverUsuarioState} from "components/popover-usuario/types";
import {withRouter} from "react-router-dom";
import {List, Popover, Typography} from "antd";
import {parsePhoneNumber} from "libphonenumber-js";

class PopoverUsuario extends React.Component<PopoverUsuarioProps, PopoverUsuarioState> {
  constructor(props: PopoverUsuarioProps) {
    super(props);
    this.state = {};
  }

  render() {
    let u = this.props.usuario;

    if (!u) return <></>;

    return <Popover title={u.dni} content={<List>
      <List.Item>
        <Typography.Text strong>Email</Typography.Text>: <Typography.Link
        href={"mailto:" + u.email} target="_blank"
      >
        {u.email}
      </Typography.Link>
      </List.Item>
      <List.Item>
        <Typography.Text strong>Teléfono</Typography.Text>: <Typography.Link
        target="_blank" href={"https://wa.me/" + u.telefono.replace("+", "")}
      >
        {parsePhoneNumber(u.telefono, "ES").formatNational()}
      </Typography.Link>
      </List.Item>
    </List>}>
      {u.nombre + " " + u.apellidos}
    </Popover>;
  }
}

export default withRouter(PopoverUsuario) as React.ComponentType<PopoverUsuarioCustomProps>;
