import React from "react";
import {AccesoProps, AccesoState} from "./types";
import {accesoReducer, initialState} from "./reducers";
import {cambiarCargando, CONNECTOR} from "./actions";
import {withRouter} from "react-router-dom";
import {Spin, Tabs} from "antd";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import './acceso.less';
import AccesoLogin from "components/form-login";
import AccesoRegistro from "components/form-registro";
import AccesoReset from "components/form-reset";

class Acceso extends React.Component<AccesoProps, AccesoState> {
  constructor(props: AccesoProps) {
    super(props);
    this.state = initialState;

    this.cambiarCargando = this.cambiarCargando.bind(this);
  }

  private cambiarCargando(cargando?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.setState(accesoReducer(this.state, cambiarCargando(cargando)), () => resolve());
    });
  }

  render() {
    return <>
      <Spin tip="Cargando..." spinning={this.state.cargando} size="large">
        <div className="card-container">
          <Tabs type="card">
            <Tabs.TabPane
              tab={<span><Icon path={MDI.mdiAccountLock} size={1}/> Iniciar Sesión</span>} key={1}
              disabled={this.state.cargando}
            >
              <AccesoLogin cambiarCargando={this.cambiarCargando}/>
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={<span><Icon path={MDI.mdiAccountPlus} size={1}/> Registrarse</span>} key={2}
              disabled={this.state.cargando}
            >
              <AccesoRegistro cambiarCargando={this.cambiarCargando}/>
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={<span><Icon path={MDI.mdiAccountQuestion} size={1}/> Reset Clave/PIN</span>} key={3}
              disabled={this.state.cargando}
            >
              <AccesoReset cambiarCargando={this.cambiarCargando}/>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Spin>
    </>;
  }
}

export default CONNECTOR(withRouter(Acceso));
