import {RouteComponentProps} from "react-router-dom";
import {EventoGrupo} from "types/eventos";

export interface FormEventoAdminCustomProps {
  visible?: boolean;
  uuid?: string;

  cargarFuncion(fn: () => Promise<EventoGrupo>): void;
}

export interface FormEventoAdminProps extends RouteComponentProps, FormEventoAdminCustomProps {
}

export interface FormEventoAdminState {
  editable: boolean;
  cargando: boolean;
  imagen: string | null;

  numEventos: number;
  nombreEventos: string[];
  spreadsheets: {evento: number, hoja: (string | null)}[];
  nuevaSpreadsheet: number | null;
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const CAMBIAR_EDITABLE = 'CAMBIAR_EDITABLE';
export const CAMBIAR_IMAGEN = 'CAMBIAR_IMAGEN';

export const CAMBIAR_NUMERO_EVENTOS = 'CAMBIAR_NUMERO_EVENTOS';
export const CAMBIAR_NOMBRE_EVENTO = 'CAMBIAR_NOMBRE_EVENTO';
export const CAMBIAR_SPREADSHEET_EVENTO = 'CAMBIAR_SPREADSHEET_EVENTO';
export const NUEVA_SPREADSHEET_EVENTO = 'NUEVA_SPREADSHEET_EVENTO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface CambiarEditableAction {
  type: typeof CAMBIAR_EDITABLE;
  payload: { editable?: boolean }
}

interface CambiarImagenAction {
  type: typeof CAMBIAR_IMAGEN;
  payload: { imagen: string | null }
}

interface CambiarNumeroEventosAction {
  type: typeof CAMBIAR_NUMERO_EVENTOS;
  payload: { numero: number };
}

interface CambiarNombreEventoAction {
  type: typeof CAMBIAR_NOMBRE_EVENTO;
  payload: { id: number, nombre: string };
}

interface CambiarSpreadsheetEventoAction {
  type: typeof CAMBIAR_SPREADSHEET_EVENTO;
  payload: { id: number, evento: number, hoja: string | null };
}

interface NuevaSpreadsheetEventoAction {
  type: typeof NUEVA_SPREADSHEET_EVENTO;
  payload: { evento: number | null };
}

export type FormEventoAdminActionTypes = CambiarCargandoAction
  | CambiarEditableAction
  | CambiarImagenAction
  | CambiarNumeroEventosAction
  | CambiarNombreEventoAction
  | CambiarSpreadsheetEventoAction
  | NuevaSpreadsheetEventoAction;
