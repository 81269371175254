import {
  CAMBIAR_CARGANDO,
  CAMBIAR_MUESTRA,
  CAMBIAR_PAGINA,
  EventosActionTypes,
  GUARDAR_EVENTOS
} from "routes/eventos/types";
import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {EventoGrupo} from "types/eventos";

export function cambiarCargando(cargando: boolean): EventosActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarDatos(total: number, eventos: EventoGrupo[]): EventosActionTypes {
  return {
    type: GUARDAR_EVENTOS,
    payload: {total, eventos},
  }
}

export function cambiarPagina(page: number): EventosActionTypes {
  return {
    type: CAMBIAR_PAGINA,
    payload: {page}
  }
}

export function cambiarMuestra(size: number): EventosActionTypes {
  return {
    type: CAMBIAR_MUESTRA,
    payload: {size}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.eventos.cargando,
  eventos: {
    futuros: state.eventos.eventos.futuros,
    enCurso: state.eventos.eventos.enCurso,
    completados: state.eventos.eventos.completados,
  },

  total: state.eventos.total,
  page: state.eventos.page,
  size: state.eventos.size,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarDatos: guardarDatos,
  cambiarPagina: cambiarPagina,
  cambiarMuestra: cambiarMuestra,
};

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
