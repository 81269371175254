import React from "react";
import {LectorQrCustomProps, LectorQrProps, LectorQrState} from "components/lector-qr/types";
import {initialState, lectorQrReducer} from "components/lector-qr/reducers";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {anadirCamara, CONNECTOR, seleccionarCamara} from "components/lector-qr/actions";
import {Col, Modal, Row, Select} from "antd";
import Webcam from "react-webcam";
import jsQR from "jsqr";

class LectorQr extends React.Component<LectorQrProps, LectorQrState> {
  constructor(props: LectorQrProps) {
    super(props);
    this.state = initialState;

    this.escanear = this.escanear.bind(this);
  }

  private WEBCAM_REF = React.createRef<Webcam>();

  private escanear() {
    const lector = this.WEBCAM_REF.current;
    const canvas = lector?.getCanvas();
    const ctx = canvas?.getContext("2d");

    if (lector && canvas && ctx) {
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      let decode = jsQR(imgData.data, canvas.width, canvas.height);
      if (decode !== null) {
        try {
          let data = JSON.parse(decode.data);
          this.props.onLeer(data);
          return;
        } catch (e) {}
      }
    }

    if (this.props.visible) {
      setTimeout(this.escanear, 300);
    }
  }

  componentDidMount() {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      let state = this.state;
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        if (device.kind === 'videoinput') {
          state = lectorQrReducer(state, anadirCamara(device.deviceId, device.label || 'Cámara ' + (i + 1)));
        }
      }
      if (state.camaras.length > 0) state = lectorQrReducer(state, seleccionarCamara(state.camaras[0].id));
      this.setState(state);
    });
  }

  componentDidUpdate(prevProps: Readonly<LectorQrProps>, prevState: Readonly<LectorQrState>, snapshot?: any) {
    if (!prevProps.visible && this.props.visible) {
      this.escanear();
    }
  }

  render() {
    return <Modal
      title="Escanear Código QR"
      visible={this.props.visible}
      onCancel={this.props.onCancelar}
      okButtonProps={{style: {display: "none"}}}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <Select
            value={this.state.camaraSeleccionada} style={{width: "100%"}}
            onChange={camara => this.setState(lectorQrReducer(this.state, seleccionarCamara(camara)))}
          >
            {this.state.camaras.map(c => <Select.Option key={c.id} value={c.id}>{c.nombre}</Select.Option>)}
          </Select>
        </Col>
      </Row>
      <Row style={{marginTop: 20}}>
        <Col span={24}>
          <Webcam
            ref={this.WEBCAM_REF} width="100%" audio={false}
            videoConstraints={{deviceId: this.state.camaraSeleccionada}}
          />
        </Col>
      </Row>
    </Modal>;
  }
}

export default compose(withRouter, CONNECTOR)(LectorQr) as React.ComponentType<LectorQrCustomProps>;
