import {PropsFromRedux} from "routes/reservas/actions";
import {RouteComponentProps} from "react-router-dom";
import {Reserva} from "types/eventos";

export interface ReservasProps extends PropsFromRedux, RouteComponentProps {

}

export interface ReservasState {
  cargando: boolean;

  reservas: {
    futuras: Reserva[];
    enCurso: Reserva[];
    completadas: Reserva[];
  };
  total: number;
  page: number;
  size: number;
}

export interface RespuestaDTO {
  total: number;
  reservas: Reserva[];
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_DATOS = 'GUARDAR_DATOS';
export const CAMBIAR_PAGINA = 'CAMBIAR_PAGINA';
export const CAMBIAR_MUESTRA = 'CAMBIAR_MUESTRA';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando: boolean };
}

interface GuardarDatosAction {
  type: typeof GUARDAR_DATOS;
  payload: { datos: Reserva[]; total: number; };
}

interface CambiarPaginaAction {
  type: typeof CAMBIAR_PAGINA;
  payload: { page: number };
}

interface CambiarMuestraAction {
  type: typeof CAMBIAR_MUESTRA;
  payload: { size: number };
}


export type ReservasActionTypes = CambiarCargandoAction
  | GuardarDatosAction
  | CambiarPaginaAction
  | CambiarMuestraAction;
