import {Layout, Menu} from "antd";
import React from "react";
import {NavbarProps, NavbarState} from "components/navbar/types";
import {withRouter} from "react-router-dom";
import logoNavbar from "assets/navbar.svg";
import {AppRoutesMenu, mapKeysToRoutes, mapRoutesToKeys} from "routes";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import './navbar.less';
import {getNombre, getToken, isSesionUsuario} from "utils/token";
import {Permisos} from "utils/permisos";


export class Navbar extends React.Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props);
    this.state = {};
  }

  private navigateTo(key: string): void {
    this.props.history.push(mapKeysToRoutes(key));
  }

  private getAdminMenu() {
    let token = getToken();
    if (!token || (typeof token === 'string') || !('usuario' in token) || !token.usuario.permisos) {
      return <></>;
    }
    let permisos = token.usuario.permisos;

    let rutas: JSX.Element[] = Permisos.filter(p => permisos.indexOf(p.permiso) > -1).map(p => {
      if (!p.submenu)
        return <Menu.Item key={mapRoutesToKeys(p.ruta)}>{p.nombre}</Menu.Item>

      return <Menu.SubMenu key={mapRoutesToKeys(p.ruta)} title={p.nombre}>
        {p.submenu.map(s => <Menu.Item key={mapRoutesToKeys(s.ruta)}>{s.nombre}</Menu.Item>)}
      </Menu.SubMenu>;
    });

    return <>
      <Menu.Item key={AppRoutesMenu.ADMIN}>Administración</Menu.Item>
      {rutas}
    </>;
  }

  render() {
    return (
      <Layout.Header className="header">
        <div className="logo-container">
          <img className="logo" src={logoNavbar} alt="..."/>
        </div>

        <Menu
          theme="dark" mode="horizontal" triggerSubMenuAction="hover"
          selectedKeys={[mapRoutesToKeys(this.props.location.pathname)]}
          onClick={e => this.navigateTo(e.key.toString())}
        >
          {this.getAdminMenu()}

          {isSesionUsuario() ?
            <Menu.SubMenu
              key="sub1" icon={<Icon path={MDI.mdiAccountOutline} size={1}/>} title={"Hola " + getNombre()}
              className="menu-right"
            >
              <Menu.Item key={AppRoutesMenu.YO}>Mi Perfil</Menu.Item>
              <Menu.Item key={AppRoutesMenu.CERRAR_SESION}>Cerrar Sesión</Menu.Item>
            </Menu.SubMenu> : <Menu.Item key={AppRoutesMenu.ACCESO} icon={<Icon path={MDI.mdiLogin} size={1}/>}
                                         className="menu-right">
              Acceder
            </Menu.Item>}
        </Menu>
      </Layout.Header>
    );
  }
}

export default withRouter(Navbar);
