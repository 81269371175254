import React from "react";
import {withRouter} from "react-router-dom";
import {AdminEventoProps, AdminEventoState} from "routes/admin-evento/types";
import FormEventoAdmin from "components/form-evento-admin";
import {Card, Col, Row} from "antd";
import {EventoGrupo} from "types/eventos";

class AdminEvento extends React.Component<AdminEventoProps, AdminEventoState> {
  constructor(props: AdminEventoProps) {
    super(props);
    this.state = {};

    this.onGuardarEventoGrupo = this.onGuardarEventoGrupo.bind(this);
  }

  private onGuardarEventoGrupo(e: EventoGrupo) {

  }

  render() {
    return <>
      <Row>
        <Col span={24}>
          <Card>
            <FormEventoAdmin uuid={this.props.match.params.uuid} cargarFuncion={fn => {}}/>
          </Card>
        </Col>
      </Row>
    </>;
  }
}

export default withRouter(AdminEvento);
