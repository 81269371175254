import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {CAMBIAR_CARGANDO, GUARDAR_USUARIO, ModalUsuarioBusquedaActionTypes, RESETEAR_SELECCION} from "./types";

export function cambiarCargando(cargando?: boolean): ModalUsuarioBusquedaActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarUsuario(id: string): ModalUsuarioBusquedaActionTypes {
  return {
    type: GUARDAR_USUARIO,
    payload: {id}
  }
}

export function resetearSeleccion(): ModalUsuarioBusquedaActionTypes {
  return {
    type: RESETEAR_SELECCION
  }
}

const mapState = (state: RootState) => ({
  cargando: state.modalUsuarioBusqueda.cargando,
  seleccion: state.modalUsuarioBusqueda.seleccion
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarUsuario: guardarUsuario,
  resetearSeleccion: resetearSeleccion,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
