import {Reserva} from "types/eventos";
import {PropsFromRedux} from "routes/admin-reservas/actions";
import {RouteComponentProps} from "react-router-dom";
import {Invitado} from "types/usuarios";

export interface ModalConfirmarCustomProps {
  reserva: Reserva | null;
  title?: string;

  onConfirmar(invitados: Invitado[], numero?: number): void;

  onCancelar(): void;
}

export interface ModalConfirmarProps extends PropsFromRedux, RouteComponentProps, ModalConfirmarCustomProps {
}

export interface ModalConfirmarState {
  numAsistentes: number;
  nominativas: boolean;
}

export const CAMBIAR_NUMERO_ASISTENTES = 'CAMBIAR_NUMERO_ASISTENTES';
export const CAMBIAR_ENTRADAS_NOMINATIVAS = 'CAMBIAR_ENTRADAS_NOMINATIVAS';

interface CambiarNumeroAsistentesAction {
  type: typeof CAMBIAR_NUMERO_ASISTENTES;
  payload: { num: number };
}

interface CambiarEntradasNominativasAction {
  type: typeof CAMBIAR_ENTRADAS_NOMINATIVAS;
  payload: { nominativas: boolean };
}


export type ModalConfirmarActionTypes = CambiarNumeroAsistentesAction
  | CambiarEntradasNominativasAction;
