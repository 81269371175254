import React from 'react';
import ReactDOM from 'react-dom';
import 'index.less';
import 'root.less';
import App from 'components/app';
import {ConfigProvider} from "antd";
import esES from 'antd/lib/locale/es_ES';
import 'moment/locale/es';
// import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={esES}>
            <App/>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('siresu')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
