import React from "react";
import {
  FormEventoAdminCustomProps,
  FormEventoAdminProps,
  FormEventoAdminState
} from "components/form-evento-admin/types";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Image,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {formEventoAdminReducer, initialState} from "components/form-evento-admin/reducers";
import {
  cambiarCargando,
  cambiarEditable,
  cambiarImagen,
  cambiarNombreEvento,
  cambiarNumeroEventos,
  cambiarSpreadsheetEvento,
  nuevaSpreadsheetEvento
} from "components/form-evento-admin/actions";
import pasoPng from "assets/paso.png";
import moment, {Moment} from "moment";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import assert from "assert";
import {Endpoints, get, post} from "utils/http";
import AppRoutes from "routes";
import {isTienePermiso, PermisoReservas} from "utils/permisos";
import {Evento, EventoGrupo} from "types/eventos";

class FormEventoAdmin extends React.Component<FormEventoAdminProps, FormEventoAdminState> {
  constructor(props: FormEventoAdminProps) {
    super(props);
    this.state = initialState;

    this.parsearEventoGrupo = this.parsearEventoGrupo.bind(this);
    this.parsearEvento = this.parsearEvento.bind(this);
    this.esNuevo = this.esNuevo.bind(this);
    this.onBtnEditar = this.onBtnEditar.bind(this);
    this.onBtnGuardar = this.onBtnGuardar.bind(this);
    this.onBtnVolcar = this.onBtnVolcar.bind(this);
    this.onBtnAsignar = this.onBtnAsignar.bind(this);
  }

  private FORM_REF = React.createRef<FormInstance>();
  private FORM_SPREADSHEET_REF = React.createRef<FormInstance>();

  private esNuevo(): boolean {
    return this.props.uuid === undefined;
  }

  private parsearEventoGrupo() {
    let form = this.FORM_REF.current;
    assert(form);

    let nombre: string = form.getFieldValue('nombre');
    nombre = nombre.trim();

    let descripcion: string | null = (form.getFieldValue('descripcion') || "") as string;
    descripcion = descripcion.trim();
    if (descripcion === "") descripcion = null;

    let imagen: string | null = (form.getFieldValue('imagen') || "") as string;
    imagen = imagen.trim();
    if (imagen === "") imagen = null;

    let fechaApertura: Moment = form.getFieldValue('fechaApertura');
    let fechaCierre: Moment | null = form.getFieldValue('fechaCierre') || null;
    let numEntradasAdmin: number = parseInt(form.getFieldValue('numEntradasAdmin'));
    let visible: boolean = form.getFieldValue('visible') || false;
    let activo: boolean = form.getFieldValue('activo') || true;

    let grupo: EventoGrupo = {
      id: "", nombre: nombre, descripcion: descripcion, imagen: imagen,
      fechaApertura: fechaApertura, fechaCierre: fechaCierre, numEntradasAdmin: numEntradasAdmin,
      visible: visible, activo: activo,
      eventos: []
    };

    for (let i = 1; i <= this.state.numEventos; i++) {
      grupo.eventos?.push(this.parsearEvento(i));
    }

    return grupo;
  }

  private parsearEvento(id: number): Evento {
    let form = this.FORM_REF.current;
    assert(form);

    let nombre: string = form.getFieldValue('eNombre' + id);
    nombre = nombre.trim();

    let localizacion: string = form.getFieldValue('eLocalizacion' + id);
    localizacion = localizacion.trim();

    let fecha: Moment = form.getFieldValue('eFecha' + id);
    let precio: number = parseFloat(form.getFieldValue('ePrecio' + id).replace(',', '.'));

    let requiere18: boolean = form.getFieldValue('eRequiere18' + id);
    if (requiere18 === undefined) requiere18 = false;
    let requiereNoCovid: boolean = form.getFieldValue('eRequiereNoCovid' + id);
    if (requiereNoCovid === undefined) requiereNoCovid = true;
    let requiereCuenta: boolean = form.getFieldValue('eRequiereCuenta' + id);
    if (requiereCuenta === undefined) requiereCuenta = false;
    let requiereNominativas: boolean = form.getFieldValue('eRequiereNominativas' + id);
    if (requiereNominativas === undefined) requiereNominativas = false;
    let requiereEntrega: boolean = form.getFieldValue('eRequiereEntrega' + id);
    if (requiereEntrega === undefined) requiereEntrega = true;

    let entradasTotales: number = parseInt(form.getFieldValue('eEntradasTotales' + id));
    let entradasMax: number = parseInt(form.getFieldValue('eEntradasMax' + id));

    return {
      id: 0, nombre: nombre, localizacion: localizacion, fecha: fecha,

      precio: precio,
      requiere18: requiere18, requiereNoCovid: requiereNoCovid, requiereCuenta: requiereCuenta,
      requiereNominativas: requiereNominativas, requiereEntrega: requiereEntrega,

      entradasTotales: entradasTotales, entradasMax: entradasMax,

      entradasReservadas: 0, entradasAsignadas: 0, entradasConfirmadas: 0, entradasPagadas: 0, entradasEntregadas: 0,
      hayEntradasDisponibles: true,
    };
  }

  private onBtnEditar() {
    if (!this.state.editable) {
      this.setState(formEventoAdminReducer(this.state, cambiarEditable(true)));
    } else {
      let state = formEventoAdminReducer(this.state, cambiarEditable(false));
      state = formEventoAdminReducer(state, cambiarCargando(true));
      this.setState(state, () => this.cargarEventoGrupo());
    }
  }

  private onBtnGuardar() {
    this.FORM_REF.current?.validateFields()
      .then(() => {
        let state = formEventoAdminReducer(this.state, cambiarCargando(true));
        state = formEventoAdminReducer(state, cambiarEditable(false));
        this.setState(state, () => {
          let grupo = this.parsearEventoGrupo();
          post(Endpoints.ADMIN_EVENTO.replace(":uuid", this.props.uuid || "_"), grupo)
            .then(res => {
              notification.success({message: 'Actualizado'});
              this.cargarEventoGrupo();
            })
            .catch(e => {
              notification.error({message: 'Error', description: e.mensaje});
              let state = formEventoAdminReducer(this.state, cambiarCargando(false));
              state = formEventoAdminReducer(state, cambiarEditable(true));
              this.setState(state);
            });
        });
      })
      .catch(() => {
      });
  }

  private onBtnVolcar() {
    this.setState(formEventoAdminReducer(this.state, cambiarCargando(true)), () => {
      get(Endpoints.ADMIN_EVENTO_VOLCAR.replace(":uuid", this.props.uuid || ""))
        .then(r => {
          notification.success({message: 'Listo', description: 'Se han volcado las nuevas entradas'});
          this.setState(formEventoAdminReducer(this.state, cambiarCargando(false)));
        })
        .catch(e => {
          notification.error({message: 'Error', description: e.mensaje});
          this.setState(formEventoAdminReducer(this.state, cambiarCargando(false)));
        });
    });
  }

  private onBtnAsignar() {
    this.setState(formEventoAdminReducer(this.state, cambiarCargando(true)), () => {
      get(Endpoints.ADMIN_EVENTO_ASIGNAR.replace(":uuid", this.props.uuid || ""))
        .then(r => {
          notification.success({message: 'Listo', description: 'Se ha ejecutado la asignación de entradas automática'});
          this.setState(formEventoAdminReducer(this.state, cambiarCargando(false)));
        })
        .catch(e => {
          notification.error({message: 'Error', description: e.mensaje});
          this.setState(formEventoAdminReducer(this.state, cambiarCargando(false)));
        })
    });
  }

  private cargarEventoGrupo() {
    get(Endpoints.ADMIN_EVENTO.replace(":uuid", this.props.uuid || ""))
      .then((res: EventoGrupo) => {
        let state = formEventoAdminReducer(this.state, cambiarNumeroEventos(res.eventos?.length || 0));
        res.eventos?.forEach((e, i) => {
          state = formEventoAdminReducer(state, cambiarNombreEvento(i + 1, e.nombre));
          state = formEventoAdminReducer(state, cambiarSpreadsheetEvento(i + 1, e.id, e.spreadsheet || null));
        });
        this.setState(state, () => {
          let fields: any = {};
          fields['nombre'] = res.nombre;
          fields['descripcion'] = res.descripcion;
          fields['imagen'] = res.imagen;
          fields['fechaApertura'] = moment.utc(res.fechaApertura).local();
          fields['fechaCierre'] = res.fechaCierre ? moment.utc(res.fechaCierre).local() : null;
          fields['numEntradasAdmin'] = res.numEntradasAdmin;
          fields['visible'] = res.visible;
          fields['activo'] = res.activo;
          res.eventos?.forEach((e, i) => {
            fields['eNombre' + (i + 1)] = e.nombre;
            fields['eLocalizacion' + (i + 1)] = e.localizacion;
            fields['eFecha' + (i + 1)] = moment.utc(e.fecha).local();
            fields['eLocalizacion' + (i + 1)] = e.localizacion;

            fields['ePrecio' + (i + 1)] = e.precio.toString();
            fields['eRequiere18' + (i + 1)] = e.requiere18;
            fields['eRequiereNoCovid' + (i + 1)] = e.requiereNoCovid;
            fields['eRequiereCuenta' + (i + 1)] = e.requiereCuenta;
            fields['eRequiereNominativas' + (i + 1)] = e.requiereNominativas;
            fields['eRequiereEntrega' + (i + 1)] = e.requiereEntrega;

            fields['eEntradasTotales' + (i + 1)] = e.entradasTotales;
            fields['eEntradasMax' + (i + 1)] = e.entradasMax;
          });
          this.FORM_REF.current?.setFieldsValue(fields);
          let state = formEventoAdminReducer(this.state, cambiarImagen(res.imagen));
          this.setState(formEventoAdminReducer(state, cambiarCargando(false)));
        });
      })
      .catch(e => {
        notification.error({
          message: 'Error',
          description: e.mensaje
        });
        this.props.history.push(AppRoutes.ADMIN_EVENTOS);
      })
  }

  componentDidMount() {
    if (this.props.cargarFuncion) this.props.cargarFuncion(() => {
      return new Promise<EventoGrupo>((resolve, reject) => {
        this.FORM_REF.current?.validateFields()
          .then(() => resolve(this.parsearEventoGrupo()))
          .catch(res => reject());
      });
    });

    let state = this.state;
    if (!this.props.uuid) {
      state = formEventoAdminReducer(state, cambiarCargando(false));
      state = formEventoAdminReducer(state, cambiarEditable(true));
    }

    this.setState(state, () => {
      if (this.props.uuid) this.cargarEventoGrupo();
    });
  }

  render() {
    return <>
      <Spin spinning={this.state.cargando}>
        <Row justify="space-between">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row>
              <Col flex="auto" style={{textAlign: "center"}}>
                <Image src={this.state.imagen || pasoPng} style={{maxWidth: 120}}/>
              </Col>
            </Row>
            {this.props.uuid === undefined ? <></> : <>
              <Row style={{marginTop: 20}}>
                <Col flex="auto" style={{textAlign: "center"}}>
                  <Button onClick={e => this.onBtnEditar()} size="large">
                    {this.state.editable ? "Cancelar" : "Editar"}
                  </Button>
                  <Button
                    style={{margin: '0 16px'}} type="primary" disabled={!this.state.editable}
                    onClick={() => this.onBtnGuardar()} size="large"
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
              <Row style={{marginTop: 10, padding: 20}}>
                <Col flex="auto" style={{textAlign: "center"}}>
                  <Button
                    type="primary" disabled={this.state.editable} icon={<Icon path={MDI.mdiGoogleSpreadsheet}/>}
                    onClick={() => this.onBtnVolcar()} size="large" block
                    style={{backgroundColor: "#34a853", borderColor: "#34a853"}}
                  >
                    Volcar Reservas
                  </Button>
                </Col>
              </Row>

              {isTienePermiso(PermisoReservas) ? <>
                <Row style={{marginTop: -20, padding: 20}}>
                  <Col flex="auto" style={{textAlign: "center"}}>
                    <Button
                      type="primary" disabled={this.state.editable} icon={<Icon path={MDI.mdiAccountCheck}/>}
                      onClick={() => this.onBtnAsignar()} size="large" block
                      style={{backgroundColor: "#ee7802", borderColor: "#ee7802"}}
                    >
                      Asignar Entradas
                    </Button>
                  </Col>
                </Row>

                <Row style={{padding: 20}}>
                  <Col flex="auto" style={{textAlign: "center"}}>
                    <Button
                      type="primary" disabled={this.state.editable} icon={<Icon path={MDI.mdiAccountClock}/>}
                      size="large" block
                      onClick={() => this.props.history.push(AppRoutes.ADMIN_RESERVAS_EVENTO.replace(":uuid", this.props.uuid || ""))}
                    >
                      Gestionar Reservas
                    </Button>
                  </Col>
                </Row>
              </> : <></>}
            </>}
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Form
              name="evento" labelCol={{span: 8}} wrapperCol={{span: 16}} size="large" ref={this.FORM_REF}
              initialValues={{descripcion: "", imagen: "", numEntradasAdmin: 1}}
            >
              <Card>
                <Form.Item
                  name="nombre" label="Nombre" hasFeedback={this.state.editable}
                  rules={[{required: true, message: 'Introduce el nombre del grupo de eventos'}]}
                >
                  <Input placeholder="Nombre" disabled={!this.state.editable}/>
                </Form.Item>

                <Form.Item name="descripcion" label="Descripción" hasFeedback={this.state.editable}>
                  <Input.TextArea placeholder="Descripción del grupo de eventos..." disabled={!this.state.editable}/>
                </Form.Item>

                <Form.Item
                  name="imagen" label="URL de Imagen" hasFeedback={this.state.editable}
                  rules={[{type: 'url', message: 'Introduce una URL con una imagen válida o deja el campo en blanco'}]}
                >
                  <Input
                    placeholder="https://..." disabled={!this.state.editable}
                    onChange={e => {
                      this.FORM_REF.current?.validateFields(['imagen'])
                        .then(res => {
                          this.setState(formEventoAdminReducer(this.state, cambiarImagen(res.imagen)));
                        })
                        .catch(e => {
                          this.setState(formEventoAdminReducer(this.state, cambiarImagen(null)));
                        })
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="fechaApertura" label="Fecha de Apertura" hasFeedback={this.state.editable}
                  rules={[{required: true, message: 'Introduce la fecha de apertura del formulario'}]}
                >
                  <DatePicker
                    showTime format="DD/MM/YYYY HH:mm" disabled={!this.state.editable} style={{width: "100%"}}
                    disabledDate={current => current && current <= moment.utc().add(-1, 'day')}
                  />
                </Form.Item>

                <Form.Item name="fechaCierre" label="Fecha de Cierre" hasFeedback={this.state.editable}>
                  <DatePicker
                    showTime format="DD/MM/YYYY HH:mm" disabled={!this.state.editable} style={{width: "100%"}}
                    allowClear disabledDate={current => current && current <= moment.utc().add(-1, 'day')}
                  />
                </Form.Item>

                <Form.Item
                  name="numEntradasAdmin" label="Entradas Paso" hasFeedback={this.state.editable}
                  rules={[{required: true, message: 'Introduce el número máximo de entradas para gente del paso'}]}
                  tooltip="Este ajuste permite a gente del Paso pedir entradas antes de abrir el formulario. Poniendo el valor a 0 lo desactiva."
                >
                  <InputNumber min={0} placeholder="Entradas" disabled={!this.state.editable}/>
                </Form.Item>

                {this.props.uuid === undefined ? <></> : <>
                  <Form.Item name="visible" label="Visible" hasFeedback={this.state.editable} valuePropName="checked">
                    <Switch disabled={!this.state.editable}/>
                  </Form.Item>

                  <Form.Item
                    name="activo" label="Activo" hasFeedback={this.state.editable} valuePropName="checked"
                    tooltip="Si está activo, se asignarán las entradas de forma automática"
                  >
                    <Switch disabled={!this.state.editable}/>
                  </Form.Item>
                </>}
              </Card>

              <Divider>Eventos</Divider>

              <Collapse defaultActiveKey={this.props.uuid ? [] : [1]} style={{marginBottom: 20}}>
                {Array.from({length: this.state.numEventos}, (v, k) => k + 1).map(i => <Collapse.Panel
                  key={i}
                  header={this.state.nombreEventos[i - 1] === "" ? ("Evento " + i) : this.state.nombreEventos[i - 1]}
                  extra={this.esNuevo() ? <>{
                    !this.props.uuid && i > 1 && i === this.state.numEventos ? <Button
                      type="link" size="small"
                      onClick={() => this.setState(formEventoAdminReducer(this.state, cambiarNumeroEventos(this.state.numEventos - 1)))}
                    >Eliminar</Button> : <></>
                  }</> : <>{
                    this.state.spreadsheets[i - 1].hoja !== null ? <Button
                      type="link" size="small" danger icon={<Icon path={MDI.mdiGoogleSpreadsheet}/>}
                      onClick={e => {
                        e.stopPropagation();
                        this.setState(formEventoAdminReducer(this.state, cambiarCargando(true)), () => {
                          post(Endpoints.ADMIN_EVENTO_SPREADSHEET
                            .replace(":uuid", this.props.uuid || "")
                            .replace(":id", this.state.spreadsheets[i - 1].evento.toString()), {})
                            .then(r => {
                              notification.success({message: 'Listo', description: 'Se ha desvinculado la hoja'});
                              this.cargarEventoGrupo();
                            })
                            .catch(e => {
                              notification.error({message: 'Error', description: e.mensaje});
                              this.setState(formEventoAdminReducer(this.state, cambiarCargando(false)));
                            })
                        });
                      }}
                    >
                      Desvincular Hoja
                    </Button> : <Button
                      type="link" size="small" icon={<Icon path={MDI.mdiGoogleSpreadsheet}/>}
                      onClick={e => {
                        e.stopPropagation();
                        this.setState(formEventoAdminReducer(this.state, nuevaSpreadsheetEvento(this.state.spreadsheets[i - 1].evento)));
                      }}
                    >
                      Vincular Hoja
                    </Button>
                  }</>}
                >
                  <Form.Item
                    name={"eNombre" + i} label="Nombre" hasFeedback={this.state.editable}
                    rules={[{required: true, message: 'Introduce el nombre del evento'}]}
                  >
                    <Input
                      placeholder="Nombre del evento" disabled={!this.state.editable}
                      onBlur={e => {
                        this.setState(formEventoAdminReducer(this.state, cambiarNombreEvento(i, e.target.value)));
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name={"eLocalizacion" + i} label="Localización" hasFeedback={this.state.editable}
                    rules={[{required: true, message: 'Introduce la ubicación del evento'}]}
                  >
                    <Input
                      placeholder="Ubicación" disabled={!this.state.editable}
                      prefix={<Icon path={MDI.mdiMapMarker} size={0.7}/>}
                    />
                  </Form.Item>

                  <Form.Item
                    name={"eFecha" + i} label="Fecha" hasFeedback={this.state.editable}
                    rules={[{required: true, message: 'Introduce la fecha del evento'}]}
                  >
                    <DatePicker
                      showTime format="DD/MM/YYYY HH:mm" disabled={!this.state.editable} style={{width: "100%"}}
                      disabledDate={current => current && current <= moment.utc().add(-1, 'day')}
                    />
                  </Form.Item>

                  <Form.Item name={"eRequiere" + i} label="Requiere" required>
                    <Descriptions bordered size="small" layout="vertical">
                      <Descriptions.Item label="+18 Años">
                        <Form.Item name={"eRequiere18" + i} valuePropName="checked">
                          <Switch disabled={!this.state.editable}/>
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label="Certificado COVID">
                        <Form.Item name={"eRequiereNoCovid" + i} valuePropName="checked">
                          <Switch defaultChecked disabled={!this.state.editable}/>
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label="Cuenta del Paso">
                        <Form.Item name={"eRequiereCuenta" + i} valuePropName="checked">
                          <Switch disabled={!this.state.editable}/>
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label="Entradas Nominativas">
                        <Form.Item name={"eRequiereNominativas" + i} valuePropName="checked">
                          <Switch disabled={!this.state.editable}/>
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label="Entradas Físicas">
                        <Form.Item name={"eRequiereEntrega" + i} valuePropName="checked">
                          <Switch defaultChecked disabled={!this.state.editable}/>
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>

                  <Form.Item
                    name={"ePrecio" + i} label="Precio" hasFeedback={this.state.editable}
                    rules={[
                      {required: true, message: 'Introduce el precio del eventos'},
                      {
                        type: 'string',
                        message: 'Introduce un número válido',
                        pattern: /^\b(?![0]\b)\d+\b([,.]\d{0,2})?$/
                      },
                    ]}
                  >
                    <Input placeholder="Precio" disabled={!this.state.editable} suffix="€"/>
                  </Form.Item>

                  <Form.Item
                    label="Entradas" name="entradas" style={{marginBottom: 0}} required={true}
                    tooltip="Fijando los valores a 0 elimina los límites (tanto de entradas totales como de entradas máximas)"
                  >
                    <Form.Item
                      style={{display: 'inline-block', width: 'calc(35% - 12px)'}} name={"eEntradasTotales" + i}
                      hasFeedback={this.state.editable}
                      rules={[
                        {required: true, message: 'Introduce el número máximo de entradas disponibles'},
                      ]}
                    >
                      <InputNumber placeholder="Totales" min={0} disabled={!this.state.editable}/>
                    </Form.Item>
                    <span style={{display: 'inline-block', width: '24px'}}/>
                    <Form.Item
                      style={{display: 'inline-block', width: 'calc(65% - 12px)'}} name={"eEntradasMax" + i}
                      hasFeedback={this.state.editable}
                      rules={[
                        {required: true, message: 'Introduce el número máximo de entradas por reserva'},
                      ]}
                    >
                      <InputNumber placeholder="Máximas" min={0} disabled={!this.state.editable}/>
                    </Form.Item>
                  </Form.Item>
                </Collapse.Panel>)}
              </Collapse>

              {!this.props.uuid ? <Button
                type="dashed" icon={<Icon path={MDI.mdiPlus}/>} block
                onClick={() => this.setState(formEventoAdminReducer(this.state, cambiarNumeroEventos(this.state.numEventos + 1)))}
              >
                Añadir Evento
              </Button> : <></>}
            </Form>
          </Col>
        </Row>
      </Spin>

      <Modal
        title="Vincular hoja con las reservas" visible={this.state.nuevaSpreadsheet !== null}
        onCancel={() => this.setState(formEventoAdminReducer(this.state, nuevaSpreadsheetEvento()))}
        onOk={() => {
          this.FORM_SPREADSHEET_REF.current?.validateFields()
            .then(() => {
              let evento = this.state.nuevaSpreadsheet;
              assert(evento !== null);
              let state = formEventoAdminReducer(this.state, cambiarCargando(true));
              state = formEventoAdminReducer(state, nuevaSpreadsheetEvento());
              this.setState(state, () => {
                let form = this.FORM_SPREADSHEET_REF.current;
                assert(form !== null);
                let body = {
                  hoja: form.getFieldValue('hoja'), colNombre: form.getFieldValue('colNombre'),
                  colApellidos: form.getFieldValue('colApellidos'), colDni: form.getFieldValue('colDni'),
                  colEmail: form.getFieldValue('colEmail'), colTelefono: form.getFieldValue('colTelefono'),
                  colEntradas: form.getFieldValue('colEntradas')
                }
                post(Endpoints.ADMIN_EVENTO_SPREADSHEET
                  .replace(":uuid", this.props.uuid || "")
                  .replace(":id", (evento || "").toString()), body)
                  .then(r => {
                    notification.success({message: 'Listo', description: 'Se ha vinculado la hoja'});
                    this.FORM_SPREADSHEET_REF.current?.resetFields();
                    this.cargarEventoGrupo();
                  })
                  .catch(e => {
                    notification.error({message: 'Error', description: e.mensaje});
                    let state = formEventoAdminReducer(this.state, cambiarCargando(false));
                    state = formEventoAdminReducer(state, nuevaSpreadsheetEvento(evento || undefined));
                    this.setState(state);
                  })
              });
            })
            .catch(e => {
            })
        }}
      >
        <Form
          name="spreadsheet" ref={this.FORM_SPREADSHEET_REF}
          initialValues={{
            colTimestamp: 'A', colNombre: 'B', colApellidos: 'C', colDni: 'D', colEmail: 'E', colTelefono: 'F',
            colEntradas: 'G'
          }}
        >
          <Form.Item
            name="hoja" label="ID de la Hoja" hasFeedback
            rules={[{required: true, message: 'Introduce el ID de la hoja de Google Spreadsheet'}]}
            tooltip="Escribe SÓLO el identificador de la hoja de cálculo (ha de tener permisos de lectura para todo el mundo)"
          >
            <Input placeholder="docs.google.com/spreadsheets/d/HOJA_ID/edit"/>
          </Form.Item>

          <Descriptions title="Columnas" layout="vertical" bordered column={4} size="small">
            <Descriptions.Item label={
              <Tooltip title="La marca temporal de las respuestas han de estar en la zona horaria de Europe/Madrid">
                <Icon path={MDI.mdiAlert} size={0.8}/> Marca Temporal
              </Tooltip>
            }>
              <Form.Item name="colTimestamp" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input disabled name="colTimestamp" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="Nombre">
              <Form.Item name="colNombre" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input name="colNombre" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="Apellidos">
              <Form.Item name="colApellidos" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input name="colApellidos" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="DNI">
              <Form.Item name="colDni" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input name="colDni" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="Email">
              <Form.Item name="colEmail" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input name="colEmail" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="Teléfono">
              <Form.Item name="colTelefono" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input name="colTelefono" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="# Entradas">
              <Form.Item name="colEntradas" hasFeedback rules={[{required: true, message: 'Introduce la columna'}]}>
                <Input name="colEntradas" maxLength={1}/>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </Modal>
    </>;
  }
}

export default (compose(withRouter))(FormEventoAdmin) as React.ComponentType<FormEventoAdminCustomProps>;
