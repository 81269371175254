import {DatePicker, Form, FormInstance, Input} from "antd";
import React from "react";
import {EditableCellProps, EditableRowProps} from "components/editable-cell/types";
import "./editable-cell.less";

const EditableContext = React.createContext<FormInstance | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<EditableCellProps<{}>> = ({
                                                                title,
                                                                editable,
                                                                children,
                                                                dataIndex,
                                                                record,
                                                                handleSave,
                                                                timePicker,
                                                                ...restProps
                                                              }) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef(null);
  const form = React.useContext(EditableContext)!;

  React.useEffect(() => {
    if (editing) {
      // @ts-ignore
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({[dataIndex]: record[dataIndex]});
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({...record, ...values});
    } catch (errInfo) {
      console.log('Error guardando:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{margin: 0}} name={dataIndex}>
        {timePicker ?
          <DatePicker ref={inputRef} onBlur={save} showTime format="DD/MM/YYYY HH:mm"/> :
          <Input ref={inputRef} onPressEnter={save} onBlur={save}/>}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
