import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  CAMBIAR_ENTRADAS_NOMINATIVAS,
  CAMBIAR_NUMERO_ASISTENTES,
  ModalConfirmarActionTypes
} from "components/modal-confirmar/types";

export function cambiarNumeroAsistentes(num: number): ModalConfirmarActionTypes {
  return {
    type: CAMBIAR_NUMERO_ASISTENTES,
    payload: {num}
  }
}

export function cambiarEntradasNominativas(nominativas: boolean): ModalConfirmarActionTypes {
  return {
    type: CAMBIAR_ENTRADAS_NOMINATIVAS,
    payload: {nominativas}
  }
}

const mapState = (state: RootState) => ({
  numAsistentes: state.modalConfirmar.numAsistentes,
  nominativas: state.modalConfirmar.nominativas,
});

const mapDispatch = {
  cambiarNumeroAsistentes: cambiarNumeroAsistentes,
  cambiarEntradasNominativas: cambiarEntradasNominativas,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
