import {
  AdminReservasActionTypes,
  AdminReservasState,
  CAMBIAR_CARGANDO,
  CAMBIAR_DRAWER_VISIBLE,
  CONFIRMAR_RESERVA,
  GUARDAR_DATOS,
  GUARDAR_FILTROS, NUEVA_RESERVA,
  SELECCIONAR_RESERVAS,
} from "./types";
import {parsearFechasReservas} from "components/form-evento-admin/utils";

export const DEFAULT_FILTROS_PAGE = 1;
export const DEFAULT_FILTROS_SIZE = 50;

export const initialState: AdminReservasState = {
  cargando: true,
  datos: [],
  total: 0,
  drawerVisible: false,
  confirmarReserva: null,

  filtros: {
    page: DEFAULT_FILTROS_PAGE,
    size: DEFAULT_FILTROS_SIZE,
  },

  seleccionadas: [],
  nueva: false
}

export function adminReservasReducer(state = initialState, action: AdminReservasActionTypes): AdminReservasState {
  let newState: AdminReservasState = JSON.parse(JSON.stringify(state));
  newState.datos = parsearFechasReservas(newState.datos);
  newState.confirmarReserva = newState.confirmarReserva !== null ? parsearFechasReservas([newState.confirmarReserva])[0] : null;
  newState.seleccionadas = parsearFechasReservas(newState.seleccionadas);

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case GUARDAR_DATOS:
      newState.total = action.payload.total;
      newState.datos = parsearFechasReservas(action.payload.reservas);
      return newState;

    case CAMBIAR_DRAWER_VISIBLE:
      newState.drawerVisible = action.payload.visible;
      return newState;

    case CONFIRMAR_RESERVA:
      newState.confirmarReserva = action.payload.reserva;
      return newState;

    case GUARDAR_FILTROS:
      newState.filtros = {
        page: action.payload.page,
        size: action.payload.size,
        sort: action.payload.sort,
        order: action.payload.order,
        filtroEstado: action.payload.filtroEstado,
      }
      return newState;

    case SELECCIONAR_RESERVAS:
      newState.seleccionadas = action.payload.reservas;
      return newState;

    case NUEVA_RESERVA:
      newState.nueva = action.payload.mostrar;
      return newState;

    default:
      return state;
  }
}
