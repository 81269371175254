import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  CAMBIAR_AVATAR,
  CAMBIAR_CARGANDO,
  CAMBIAR_EDITABLE,
  DESACTIVAR_CAMPOS,
  EDITAR_CLAVE,
  FormPerfilActionTypes
} from "./types";

export function cambiarCargando(cargando?: boolean): FormPerfilActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function cambiarEditable(editable?: boolean): FormPerfilActionTypes {
  return {
    type: CAMBIAR_EDITABLE,
    payload: {editable}
  }
}

export function cambiarAvatar(avatar: string): FormPerfilActionTypes {
  return {
    type: CAMBIAR_AVATAR,
    payload: {avatar}
  }
}

export function desactivarCampos(): FormPerfilActionTypes {
  return {
    type: DESACTIVAR_CAMPOS
  }
}

export function editarClave(): FormPerfilActionTypes {
  return {
    type: EDITAR_CLAVE
  }
}

const mapState = (state: RootState) => ({
  cargando: state.formPerfil.cargando,
  editable: state.formPerfil.editable,
  avatar: state.formPerfil.avatar,
  desactivar: state.formPerfil.desactivar,
  clave: state.formPerfil.clave
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  cambiarEditable: cambiarEditable,
  cambiarAvatar: cambiarAvatar,
  desactivarCampos: desactivarCampos,
  editarClave: editarClave
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
