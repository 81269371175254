import {AdminMesaEnlacesActionTypes, CAMBIAR_CARGANDO, GUARDAR_ENLACES,} from "routes/admin-mesa-enlaces/types";
import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {Enlace} from "types/misc";

export function cambiarCargando(cargando: boolean): AdminMesaEnlacesActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarEnlaces(enlaces: Enlace[]): AdminMesaEnlacesActionTypes {
  return {
    type: GUARDAR_ENLACES,
    payload: {enlaces}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.adminMesaEnlaces.cargando,
  enlaces: state.adminMesaEnlaces.enlaces,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarEnlaces: guardarEnlaces,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;

