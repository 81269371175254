import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {Reserva} from "types/eventos";
import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";

export interface AdminReservasRouteProps {
  uuid?: string;
}

export interface AdminReservasProps extends PropsFromRedux, IWithGoogleReCaptchaProps, RouteComponentProps<AdminReservasRouteProps> {
}

export interface AdminReservasState {
  cargando: boolean;
  datos: Reserva[];
  total: number;
  drawerVisible: boolean;
  confirmarReserva: Reserva | null;

  filtros: {
    page: number;
    size: number;
    sort?: string;
    order?: 'ascend' | 'descend';
    filtroEstado?: string[];
    filtroUsuario?: string;
  }

  seleccionadas: Reserva[];
  nueva: boolean;
}

export interface RespuestaDTO {
  total: number;
  reservas: Reserva[];
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_DATOS = 'GUARDAR_DATOS';
export const CAMBIAR_DRAWER_VISIBLE = 'CAMBIAR_DRAWER_VISIBLE';

export const CONFIRMAR_RESERVA = 'CAMBIAR_CONFIRMAR_RESERVA';
export const GUARDAR_FILTROS = 'GUARDAR_FILTROS';
export const SELECCIONAR_RESERVAS = 'SELECCIONAR_RESERVAS';
export const NUEVA_RESERVA = 'NUEVA_RESERVA';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface GuardarDatosAction {
  type: typeof GUARDAR_DATOS;
  payload: { total: number, reservas: Reserva[] };
}

interface CambiarDrawerVisibleAction {
  type: typeof CAMBIAR_DRAWER_VISIBLE;
  payload: { visible: boolean };
}

interface ConfirmarReservaAction {
  type: typeof CONFIRMAR_RESERVA;
  payload: { reserva: Reserva | null };
}

interface GuardarFiltrosAction {
  type: typeof GUARDAR_FILTROS,
  payload: {
    page: number; size: number;
    sort?: string; order?: 'ascend' | 'descend';
    filtroEstado?: string[]; filtroUsuario?: string;
  };
}

interface SeleccionarReservasAction {
  type: typeof SELECCIONAR_RESERVAS;
  payload: { reservas: Reserva[]; };
}

interface NuevaReservaAction {
  type: typeof NUEVA_RESERVA;
  payload: { mostrar: boolean; };
}


export type AdminReservasActionTypes = CambiarCargandoAction
  | GuardarDatosAction
  | CambiarDrawerVisibleAction
  | ConfirmarReservaAction
  | GuardarFiltrosAction
  | SeleccionarReservasAction
  | NuevaReservaAction;
