import React from "react";
import {PerfilProps, PerfilState} from "./types";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import Perfil from "components/form-perfil";

class Yo extends React.Component<PerfilProps, PerfilState> {
  constructor(props: PerfilProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <>
      <Perfil/>
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha)(Yo) as React.ComponentType;
