import {
  CAMBIAR_AVATAR,
  CAMBIAR_CARGANDO,
  CAMBIAR_EDITABLE,
  DESACTIVAR_CAMPOS,
  EDITAR_CLAVE,
  FormPerfilActionTypes,
  FormPerfilState
} from "./types";

export const initialState: FormPerfilState = {
  cargando: true,
  editable: false,
  avatar: "",

  desactivar: false,
  clave: false
}

export function formPerfilReducer(state = initialState, action: FormPerfilActionTypes): FormPerfilState {
  let newState: FormPerfilState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case CAMBIAR_EDITABLE:
      newState.editable = action.payload.editable || !state.editable;
      return newState;

    case CAMBIAR_AVATAR:
      newState.avatar = action.payload.avatar;
      return newState;

    case DESACTIVAR_CAMPOS:
      newState.desactivar = true;
      return newState;

    case EDITAR_CLAVE:
      newState.clave = !state.clave;
      return newState;

    default:
      return state;
  }
}
