import {
  CAMBIAR_CARGANDO,
  CAMBIAR_MUESTRA,
  CAMBIAR_PAGINA,
  EventosActionTypes,
  EventosState,
  GUARDAR_EVENTOS
} from "routes/eventos/types";
import {parsearFechasEventoGrupo} from "components/form-evento-admin/utils";
import moment, {Moment} from "moment";
import {EventoGrupo} from "types/eventos";
import assert from "assert";

export const DEFAULT_FILTROS_PAGE = 1;
export const DEFAULT_FILTROS_SIZE = 50;

export const initialState: EventosState = {
  cargando: true,
  eventos: {
    futuros: [],
    enCurso: [],
    completados: [],
  },

  total: 0,
  page: DEFAULT_FILTROS_PAGE,
  size: DEFAULT_FILTROS_SIZE,
}

function getFechasPrimeraUltima(g: EventoGrupo) {
  assert(g.eventos !== undefined);
  let primero: Moment = g.eventos[0].fecha, ultimo: Moment = g.eventos[0].fecha;
  g.eventos.forEach(e => {
    if (e.fecha.isBefore(primero)) primero = e.fecha;
    if (e.fecha.isAfter(ultimo)) ultimo = e.fecha;
  });

  // Cambiar esto para la tolerancia de "evento en progreso"
  primero = primero.clone().add(-30, 'minute');
  ultimo = ultimo.clone().add(3, 'hour');
  return [primero, ultimo];
}

export function eventosReducer(state = initialState, action: EventosActionTypes): EventosState {
  let newState: EventosState = JSON.parse(JSON.stringify(state));
  newState.eventos.futuros = parsearFechasEventoGrupo(newState.eventos.futuros);
  newState.eventos.enCurso = parsearFechasEventoGrupo(newState.eventos.enCurso);
  newState.eventos.completados = parsearFechasEventoGrupo(newState.eventos.completados);

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      return newState;

    case GUARDAR_EVENTOS:
      action.payload.eventos = parsearFechasEventoGrupo(action.payload.eventos);
      newState.total = action.payload.total;
      newState.eventos = {futuros: [], enCurso: [], completados: []};
      const now = moment.utc();
      for (let grupo of action.payload.eventos) {
        let [inicio, fin] = getFechasPrimeraUltima(grupo);
        if (inicio.isAfter(now) && fin.isBefore(now)) {
          newState.eventos.enCurso.push(grupo);
        } else if (fin.isBefore(now)) {
          newState.eventos.completados.push(grupo);
        } else if (inicio.isAfter(now)) {
          newState.eventos.futuros.push(grupo);
        }
      }
      return newState;

    case CAMBIAR_PAGINA:
      newState.page = action.payload.page;
      return newState;

    case CAMBIAR_MUESTRA:
      newState.size = action.payload.size;
      return newState;

    default:
      return state;
  }
}
