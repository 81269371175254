import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  AdminReservasActionTypes,
  CAMBIAR_CARGANDO,
  CAMBIAR_DRAWER_VISIBLE,
  CONFIRMAR_RESERVA,
  GUARDAR_DATOS,
  GUARDAR_FILTROS,
  NUEVA_RESERVA,
  RespuestaDTO,
  SELECCIONAR_RESERVAS
} from "./types";
import {Reserva} from "types/eventos";

export function cambiarCargando(cargando?: boolean): AdminReservasActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarDatos(respuesta: RespuestaDTO): AdminReservasActionTypes {
  return {
    type: GUARDAR_DATOS,
    payload: {...respuesta}
  }
}

export function cambiarDrawerVisible(visible: boolean): AdminReservasActionTypes {
  return {
    type: CAMBIAR_DRAWER_VISIBLE,
    payload: {visible}
  }
}

export function confirmarReserva(reserva?: Reserva): AdminReservasActionTypes {
  return {
    type: CONFIRMAR_RESERVA,
    payload: {reserva: reserva || null}
  }
}

export function guardarFiltros(
  page: number, size: number,
  sort?: string, order?: 'ascend' | 'descend',
  filtroEstado?: string[], filtroUsuario?: string
): AdminReservasActionTypes {
  return {
    type: GUARDAR_FILTROS,
    payload: {page, size, sort, order, filtroEstado, filtroUsuario}
  }
}

export function seleccionarReservas(reservas: Reserva[]): AdminReservasActionTypes {
  return {
    type: SELECCIONAR_RESERVAS,
    payload: {reservas}
  }
}

export function nuevaReserva(mostrar: boolean): AdminReservasActionTypes {
  return {
    type: NUEVA_RESERVA,
    payload: {mostrar}
  }
}


const mapState = (state: RootState) => ({
  cargando: state.adminReservas.cargando,
  datos: state.adminReservas.datos,
  total: state.adminReservas.total,
  drawerVisible: state.adminReservas.visible,
  confirmarReserva: state.adminReservas.confirmarReserva,
  filtros: {
    page: state.adminReservas.filtros.page,
    size: state.adminReservas.filtros.size,
    sort: state.adminReservas.filtros.sort,
    order: state.adminReservas.filtros.order,
    filtroEstado: state.adminReservas.filtros.filtroEstado,
    filtroUsuario: state.adminReservas.filtros.filtroUsuario,
  },
  seleccionadas: state.adminReservas.seleccionadas,
  nueva: state.adminReservas.nueva,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarDatos: guardarDatos,
  cambiarDrawerVisible: cambiarDrawerVisible,
  confirmarReserva: confirmarReserva,
  guardarFiltros: guardarFiltros,
  seleccionarReservas: seleccionarReservas,
  nuevaReserva: nuevaReserva,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
