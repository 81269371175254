import {
  CAMBIAR_ENTRADAS_NOMINATIVAS,
  CAMBIAR_NUMERO_ASISTENTES,
  ModalConfirmarActionTypes,
  ModalConfirmarState
} from "components/modal-confirmar/types";

export const initialState: ModalConfirmarState = {
  numAsistentes: 1,
  nominativas: true
};


export function modalConfirmarReducer(state = initialState, action: ModalConfirmarActionTypes): ModalConfirmarState {
  let newState: ModalConfirmarState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_NUMERO_ASISTENTES:
      newState.numAsistentes = action.payload.num;
      return newState;

    case CAMBIAR_ENTRADAS_NOMINATIVAS:
      newState.nominativas = action.payload.nominativas;
      return newState;

    default:
      return state;
  }
}
