import {AccesoVerificarActionTypes, AccesoVerificarState, CAMBIAR_CARGANDO} from "./types";

export const initialState: AccesoVerificarState = {
  cargando: true
}

export function accesoVerificarReducer(state = initialState, action: AccesoVerificarActionTypes): AccesoVerificarState {
  let newState: AccesoVerificarState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    default:
      return state;
  }
}
