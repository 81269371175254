import {withRouter} from "react-router-dom";
import React from "react";
import {
  PopoverEventoEntradasCustomProps,
  PopoverEventoEntradasProps,
  PopoverEventoEntradasState
} from "components/popover-evento-entradas/types";
import {Descriptions, Popover, Typography} from "antd";

class PopoverEventoEntradas extends React.Component<PopoverEventoEntradasProps, PopoverEventoEntradasState> {
  constructor(props: PopoverEventoEntradasProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <Popover
      content={<Descriptions bordered size="small">
        <Descriptions.Item label="Reservadas">{this.props.evento.entradasReservadas}</Descriptions.Item>
        <Descriptions.Item label="Asignadas">{this.props.evento.entradasAsignadas}</Descriptions.Item>
        <Descriptions.Item label="Confirmadas">{this.props.evento.entradasConfirmadas}</Descriptions.Item>
        <Descriptions.Item label="Pagadas">{this.props.evento.entradasPagadas}</Descriptions.Item>
        {this.props.evento.requiereEntrega ? <Descriptions.Item label="Entregadas">
          {this.props.evento.entradasEntregadas}
        </Descriptions.Item> : <></>}
      </Descriptions>}
      title={<Typography.Text strong>
        {this.props.evento.entradasTotales === 0 ? "Sin Límite" : (this.props.evento.entradasTotales + " Entradas")}
      </Typography.Text>}>
      {this.props.children ? this.props.children : this.props.evento.nombre}
    </Popover>;
  }
}

export default withRouter(PopoverEventoEntradas) as React.ComponentType<PopoverEventoEntradasCustomProps>;
