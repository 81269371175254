import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {Button, List, Modal, notification, Spin, Table, Tag, Tooltip} from "antd";
import {AdminEventosProps, AdminEventosState, RespuestaDTO} from "routes/admin-eventos/types";
import {adminEventosReducer, initialState} from "routes/admin-eventos/reducers";
import {
  cambiarCargando,
  CONNECTOR,
  guardarDatos,
  guardarFuncionNuevoEvento,
  mostrarNuevoEvento
} from "routes/admin-eventos/actions";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import moment from "moment";
import {Endpoints, get, post} from "utils/http";
import FormEventoAdmin from "components/form-evento-admin";
import AppRoutes from "routes/index";
import {EventoGrupo} from "types/eventos";
import {ColumnsType} from "antd/lib/table/interface";
import PopoverEventoEntradas from "components/popover-evento-entradas";

class AdminEventos extends React.Component<AdminEventosProps, AdminEventosState> {
  constructor(props: AdminEventosProps) {
    super(props);
    this.state = initialState;

    this.getColumnas = this.getColumnas.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);
    this.nuevoEvento = this.nuevoEvento.bind(this);
  }

  private static getEstado(grupo: EventoGrupo) {
    let estado = {nombre: "OCULTO", color: "default", descripcion: ""};
    if (!grupo.eventos) return estado;

    if (grupo.visible) {
      if (grupo.fechaApertura.isAfter(moment.utc())) {
        estado = {nombre: "CERRADO", color: "red", descripcion: "No está abierto al público"};
      } else if (grupo.eventos[0].fecha.isBefore(moment.utc())) {
        estado = {nombre: "COMPLETADO", color: "blue", descripcion: "Ya ha pasado la fecha del evento"};
      } else {
        if (grupo.eventos.find(e => e.hayEntradasDisponibles)) {
          estado = {nombre: "ABIERTO", color: "green", descripcion: "Hay entradas disponibles"};
        } else {
          estado = {nombre: "LLENO", color: "volcano", descripcion: "Se han asignado todas las entradas"};
        }
      }
    }

    return <Tooltip title={estado.descripcion}><Tag color={estado.color}>{estado.nombre}</Tag></Tooltip>;
  }

  private getColumnas(): ColumnsType<EventoGrupo> {
    return [
      {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        // TODO: Filtros con búsqueda por nombre
        // https://ant.design/components/table/#components-table-demo-custom-filter-panel
      },
      {
        title: 'Fecha de Apertura',
        dataIndex: 'fechaApertura',
        key: 'fechaApertura',
        render: (texto: string, record: EventoGrupo, index: number) => {
          return <Tooltip title={record.fechaApertura.local().fromNow()}>
            {record.fechaApertura.local().format("DD/MM/YYYY HH:mm")}
          </Tooltip>
        },
        // La ordenación ocurre en servidor
        sorter: (a: EventoGrupo, b: EventoGrupo) => 0
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        align: 'center',
        render: (texto: string, record: EventoGrupo, index: number) => AdminEventos.getEstado(record)
      },
    ];
  }

  private cargarDatos(page: number = 1, size: number = 25, sort?: string, order?: 'ascend' | 'descend') {
    let url = Endpoints.ADMIN_EVENTOS + `?page=${page}&size=${size}`;
    if (sort) url += `&sort=${sort}&order=${order}`;
    get(url)
      .then((res: RespuestaDTO) => {
        let state = adminEventosReducer(this.state, guardarDatos(res));
        state = adminEventosReducer(state, cambiarCargando(false));
        this.setState(state);
      })
      .catch(res => {
        notification.error({
          message: 'Error',
          description: res.descripcion
        });
        this.setState(adminEventosReducer(this.state, cambiarCargando(false)));
      });
  }

  private nuevoEvento(grupo: EventoGrupo) {
    this.setState(adminEventosReducer(this.state, cambiarCargando(true)), () => {
      let body: any = grupo;
      body.fechaApertura = grupo.fechaApertura.toISOString();
      body.fechaCierre = grupo.fechaCierre === null ? null : grupo.fechaCierre.toISOString();
      body.eventos.forEach((e: any) => {
        e.fecha = e.fecha.toISOString();
      });

      post(Endpoints.ADMIN_EVENTOS, body)
        .then((grupo: EventoGrupo) => {
          this.props.history.push(AppRoutes.ADMIN_EVENTO.replace(":uuid", grupo.id));
        })
        .catch(e => {
          notification.error({
            message: 'Error',
            description: e.mensaje,
          })
          this.setState(adminEventosReducer(this.state, cambiarCargando(false)));
        })
    });
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return <>
      <Table
        rowKey="id"
        size="middle"
        loading={this.state.cargando}
        columns={this.getColumnas()}
        dataSource={this.state.datos}
        rowClassName="ant-table-row-clickable"
        expandable={{
          expandedRowRender: record => <List
            dataSource={record.eventos}
            renderItem={e => <PopoverEventoEntradas
              evento={e}><List.Item>{e.nombre}</List.Item></PopoverEventoEntradas>}
          />,
        }}
        onRow={(record) => {
          return {
            onClick: (event: any) => this.props.history.push(AppRoutes.ADMIN_EVENTO.replace(":uuid", record.id))
          };
        }}
        title={() => <Button
          type="primary" shape="round" icon={<Icon path={MDI.mdiCalendarPlus}/>}
          onClick={() => this.setState(adminEventosReducer(this.state, mostrarNuevoEvento(true)))}
        >
          Nuevo Evento
        </Button>}
        pagination={{
          total: this.state.total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} eventos`
        }}
        onChange={(pagination, filters, sorter) => {
          this.setState(adminEventosReducer(this.state, cambiarCargando()), () => {
            if (Array.isArray(sorter)) sorter = sorter[0];
            this.cargarDatos(
              pagination.current, pagination.pageSize,
              sorter.column?.key as (string | undefined), sorter.order || undefined
            );
          });
        }}
      />

      <Modal
        title="Nuevo Evento" centered visible={this.state.nuevo.visible} width={1000}
        onCancel={() => this.setState(adminEventosReducer(this.state, mostrarNuevoEvento(false)))}
        onOk={() => {
          if (this.state.nuevo.getEventoGrupo === undefined) return;
          this.state.nuevo.getEventoGrupo().then(this.nuevoEvento);
        }}
      >
        <Spin spinning={this.state.cargando}>
          <FormEventoAdmin
            visible={this.state.nuevo.visible}
            cargarFuncion={fn => {
              this.setState(adminEventosReducer(this.state, guardarFuncionNuevoEvento(fn)));
            }}
          />
        </Spin>
      </Modal>
    </>;
  }
}

export default compose(withRouter, CONNECTOR)(AdminEventos) as React.ComponentType;
