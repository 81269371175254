import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  BORRAR_SELECCION,
  CAMBIAR_CARGANDO,
  CAMBIAR_SELECCION,
  DrawerSelectorEventosActionTypes,
  GUARDAR_DATOS
} from "./types";
import {EventoGrupo} from "types/eventos";

export function cambiarCargando(cargando?: boolean): DrawerSelectorEventosActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarDatos(grupos: EventoGrupo[]): DrawerSelectorEventosActionTypes {
  return {
    type: GUARDAR_DATOS,
    payload: {grupos}
  }
}

export function guardarSeleccion(seleccion?: EventoGrupo): DrawerSelectorEventosActionTypes {
  if (seleccion) return {
    type: CAMBIAR_SELECCION,
    payload: {seleccion}
  }

  return {
    type: BORRAR_SELECCION
  }
}


const mapState = (state: RootState) => ({
  cargando: state.drawerSelectorEvento.cargando,
  grupos: state.drawerSelectorEvento.grupos,
  seleccion: state.drawerSelectorEvento.seleccion,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarDatos: guardarDatos,
  guardarSeleccion: guardarSeleccion,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
