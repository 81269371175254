import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  CAMBIAR_CARGANDO,
  GUARDAR_ENLACES,
  GUARDAR_EVENTOS,
  GUARDAR_MESAS,
  GUARDAR_RESERVAS,
  InicioActionTypes,
  InicioState
} from "./types";
import {Asistente, EventoGrupo, Reserva} from "types/eventos";
import {Enlace, Mesa} from "types/misc";

export function cambiarCargando(cargando: boolean): InicioActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarEventos(eventos: EventoGrupo[]): InicioActionTypes {
  return {
    type: GUARDAR_EVENTOS,
    payload: {eventos}
  }
}

export function guardarReservas(reservas: Reserva[], asistentes: Asistente[]): InicioActionTypes {
  return {
    type: GUARDAR_RESERVAS,
    payload: {reservas, asistentes}
  }
}

export function guardarEnlaces(enlaces: Enlace[]): InicioActionTypes {
  return {
    type: GUARDAR_ENLACES,
    payload: {enlaces}
  }
}

export function guardarMesas(mesas: Mesa[]): InicioActionTypes {
  return {
    type: GUARDAR_MESAS,
    payload: {mesas}
  }
}

const mapState = (state: RootState): InicioState => ({
  cargando: state.inicio.cargando,

  eventos: state.inicio.eventos,
  reservas: state.inicio.reservas,
  mesas: state.inicio.mesas,
  enlaces: state.inicio.enlaces,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,

  guardarEventos: guardarEventos,
  guardarReservas: guardarReservas,
  guardarEnlaces: guardarEnlaces,
  guardarMesas: guardarMesas,
};

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
