import {RouteComponentProps} from "react-router-dom";
import {Mesa} from "types/misc";

export interface AdminMesaMesasProps extends RouteComponentProps {
}

export interface AdminMesaMesasState {
  cargando: boolean;
  mesas: Mesa[];
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_MESAS = 'GUARDAR_MESAS';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando: boolean };
}

interface GuardarMesasAction {
  type: typeof GUARDAR_MESAS;
  payload: { mesas: Mesa[] };
}

export type AdminMesaMesasActionTypes = CambiarCargandoAction
  | GuardarMesasAction;
