import {EventoGrupo} from "types/eventos";
import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "components/drawer-selector-evento/actions";

export interface DrawerSelectorEventoCustomProps {
  visible: boolean;

  onEvento(e: EventoGrupo): void;

  onCerrar(): void;
}

export interface DrawerSelectorEventoProps extends PropsFromRedux, RouteComponentProps, DrawerSelectorEventoCustomProps {
}

export interface DrawerSelectorEventoState {
  cargando: boolean;
  seleccion: EventoGrupo | null;
  grupos: EventoGrupo[];
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_DATOS = 'GUARDAR_DATOS';

export const CAMBIAR_SELECCION = 'CAMBIAR_SELECCION';
export const BORRAR_SELECCION = 'BORRAR_SELECCION';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface GuardarDatosAction {
  type: typeof GUARDAR_DATOS;
  payload: { grupos: EventoGrupo[] };
}

interface CambiarSeleccionAction {
  type: typeof CAMBIAR_SELECCION;
  payload: { seleccion: EventoGrupo };
}

interface BorrarSeleccionAction {
  type: typeof BORRAR_SELECCION;
}


export type DrawerSelectorEventosActionTypes = CambiarCargandoAction
  | GuardarDatosAction
  | CambiarSeleccionAction
  | BorrarSeleccionAction;
