import {
  AdminMesaMesasActionTypes,
  AdminMesaMesasState,
  CAMBIAR_CARGANDO,
  GUARDAR_MESAS,
} from "routes/admin-mesa-mesas/types";
import {parsearFechasMesas} from "components/form-evento-admin/utils";

export const initialState: AdminMesaMesasState = {
  cargando: true,
  mesas: [],
}

export function adminMesaMesasReducer(state = initialState, action: AdminMesaMesasActionTypes): AdminMesaMesasState {
  let newState: AdminMesaMesasState = JSON.parse(JSON.stringify(state));
  newState.mesas = parsearFechasMesas(newState.mesas);

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      return newState;

    case GUARDAR_MESAS:
      newState.mesas = action.payload.mesas;
      return newState;

    default:
      return state;
  }
}
