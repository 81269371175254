import {
  CAMBIAR_CARGANDO,
  CAMBIAR_EDITABLE,
  CAMBIAR_IMAGEN,
  CAMBIAR_NOMBRE_EVENTO,
  CAMBIAR_NUMERO_EVENTOS,
  CAMBIAR_SPREADSHEET_EVENTO,
  FormEventoAdminActionTypes,
  FormEventoAdminState,
  NUEVA_SPREADSHEET_EVENTO
} from "components/form-evento-admin/types";

export const initialState: FormEventoAdminState = {
  cargando: true,
  editable: false,
  imagen: null,

  numEventos: 1,
  nombreEventos: [""],
  spreadsheets: [{evento: 0, hoja: null}],
  nuevaSpreadsheet: null
}

export function formEventoAdminReducer(state = initialState, action: FormEventoAdminActionTypes): FormEventoAdminState {
  let newState: FormEventoAdminState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case CAMBIAR_EDITABLE:
      newState.editable = action.payload.editable || !state.editable;
      return newState;

    case CAMBIAR_IMAGEN:
      newState.imagen = action.payload.imagen;
      return newState;

    case CAMBIAR_NUMERO_EVENTOS:
      if (action.payload.numero > 0) {
        newState.numEventos = action.payload.numero;
        while (newState.nombreEventos.length > newState.numEventos) newState.nombreEventos.pop();
        while (newState.nombreEventos.length < newState.numEventos) newState.nombreEventos.push("");
      }
      return newState;

    case CAMBIAR_NOMBRE_EVENTO:
      if (action.payload.id > state.numEventos) return newState;
      newState.nombreEventos[action.payload.id - 1] = action.payload.nombre;
      return newState;

    case CAMBIAR_SPREADSHEET_EVENTO:
      if (action.payload.id > state.numEventos) return newState;
      newState.spreadsheets[action.payload.id - 1] = {evento: action.payload.evento, hoja: action.payload.hoja};
      return newState;

    case NUEVA_SPREADSHEET_EVENTO:
      newState.nuevaSpreadsheet = action.payload.evento;
      return newState;

    default:
      return state;
  }
}