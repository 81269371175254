import {
  parsearFechasAsistentes,
  parsearFechasEventoGrupo,
  parsearFechasReservas
} from "components/form-evento-admin/utils";
import {
  CAMBIAR_CARGANDO,
  CARGAR_EVENTO_GRUPO,
  COMPROBAR_INSCRIPCIONES_ABIERTAS,
  EventoActionTypes,
  EventoState,
  GUARDAR_RESERVA,
  MOSTRAR_MODAL
} from "routes/evento/types";
import assert from "assert";

export const initialState: EventoState = {
  cargando: true,
  grupo: null,
  abierto: false,

  reservas: null,
  asistentes: null,

  modal: {
    reservar: false,
    reserva: false,
    confirmar: null,
    pin: false,
  }
}

export function eventoReducer(state = initialState, action: EventoActionTypes): EventoState {
  let newState: EventoState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      break;

    case CARGAR_EVENTO_GRUPO:
      newState.grupo = action.payload.grupo;
      break;

    case COMPROBAR_INSCRIPCIONES_ABIERTAS:
      newState.abierto = action.payload.abierto;
      break;

    case GUARDAR_RESERVA:
      newState.reservas = action.payload.reserva;
      newState.asistentes = action.payload.asistentes;
      break;

    case MOSTRAR_MODAL:
      newState.modal = {reservar: false, reserva: false, confirmar: null, pin: false};
      // Por algún motivo no funciona sacar factor común
      if (action.payload.modal === "confirmar") {
        assert(action.payload.visible !== true && action.payload.visible !== false);
        newState.modal[action.payload.modal] = action.payload.visible;
      } else {
        assert(action.payload.visible === true || action.payload.visible === false);
        newState.modal[action.payload.modal] = action.payload.visible;
      }
      break;

    default:
      return state;
  }

  newState.grupo = newState.grupo !== null ? parsearFechasEventoGrupo([newState.grupo])[0] : null;
  newState.reservas = newState.reservas !== null ? parsearFechasReservas(newState.reservas) : null;
  newState.asistentes = newState.asistentes !== null ? parsearFechasAsistentes(newState.asistentes) : null;
  return newState;
}
