import jwtDecode from "jwt-decode";

type StorageType = "sessionStorage" | "localStorage";
const STORAGE_TYPE_KEY = "siresu_storage";
const STORAGE_KEY = "siresu_token";

function getStorage() {
  switch (localStorage.getItem(STORAGE_TYPE_KEY) as null | StorageType) {
    case "sessionStorage":
      return sessionStorage;
    default:
    case "localStorage":
      return localStorage;
  }
}

export function isSesionTemporal(): boolean {
  return getToken() !== null && getStorage() === sessionStorage;
}

export function isSesionUsuario(): boolean {
  return getToken() !== null && getStorage() === localStorage;
}

export function getToken(raw: boolean = false) {
  let token = getStorage().getItem(STORAGE_KEY);
  if (token === null) return null;
  try {
    let decoded: Token = jwtDecode(token);
    // TODO: Validar exp, iss y aud
    if (raw) {
      return token;
    }
    return decoded;
  } catch (InvalidTokenError) {
    borrarToken();
  }
  return null;
}

export function borrarToken() {
  getStorage().removeItem(STORAGE_KEY);
}

export function guardarToken(recordar: boolean, token: string) {
  if (recordar) {
    localStorage.setItem(STORAGE_TYPE_KEY, "localStorage");
  } else {
    localStorage.setItem(STORAGE_TYPE_KEY, "sessionStorage");
  }

  getStorage().setItem(STORAGE_KEY, token);
}

export function getNombre(): null | string {
  let token = getToken() as Token;
  if (token === null || !('usuario' in token)) return null;
  return token.usuario.nombre;
}

interface TokenBase {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
}

export type Token = TokenUsuario | TokenInvitado;

export interface TokenUsuario extends TokenBase {
  usuario: {
    dni: string;
    nombre: string;
    uuid: string;
    permisos: null | string[];
  }
}

export interface TokenInvitado extends TokenBase {
  invitado: {
    id: number;
    dni: string;
  }
}
