import React from "react";
import {EventosProps, EventosState, RespuestaDTO} from "routes/eventos/types";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {cambiarCargando, cambiarMuestra, cambiarPagina, CONNECTOR, guardarDatos} from "routes/eventos/actions";
import {eventosReducer, initialState} from "routes/eventos/reducers";
import {Endpoints, get} from "utils/http";
import {
  Avatar,
  Card,
  Col,
  Divider,
  List,
  notification,
  Pagination,
  Row,
  Skeleton,
  Tag,
  Tooltip,
  Typography
} from "antd";
import {EventoGrupo} from "types/eventos";
import AppRoutes from "routes/index";
import moment from "moment";

class Eventos extends React.Component<EventosProps, EventosState> {
  constructor(props: EventosProps) {
    super(props);
    this.state = initialState;

    this.renderEventos = this.renderEventos.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);
  }

  private renderEventos(e: EventoGrupo[], titulo: string) {
    if (e.length === 0)
      return <></>;

    return <>
      <Divider orientation="left">{titulo}</Divider>
      <Card bodyStyle={{padding: 0}}>
        <List
          bordered
          itemLayout="horizontal"
          dataSource={e}
          renderItem={(evento, key) => {
            return <Link to={AppRoutes.EVENTO.replace(":uuid", evento.id)} key={key}>
              <List.Item
                actions={[
                  <Tooltip title={evento.fechaApertura.local().format("LLLL")}>
                    {evento.fechaApertura.isAfter(moment.utc()) ? "Apertura" : "Abrió"}: <Typography.Text>
                    {evento.fechaApertura.local().fromNow()}
                  </Typography.Text>
                  </Tooltip>
                ]}
              >
                <List.Item.Meta
                  avatar={evento.imagen ? <Avatar src={evento.imagen}/> : undefined}
                  title={<>
                    {evento.eventos ? <Tag>{evento.eventos[0].fecha.local().format("L")}</Tag> : ""} {evento.nombre}
                  </>}
                  description={<Typography.Text ellipsis className="description-color">
                    {evento.descripcion}
                  </Typography.Text>}
                />
              </List.Item>
            </Link>;
          }}
        />
      </Card>
    </>;
  }

  private cargarDatos() {
    get(`${Endpoints.EVENTOS}?page=${this.state.page}&size=${this.state.size}`)
      .then((res: RespuestaDTO) => {
        let state = eventosReducer(this.state, guardarDatos(res.total, res.eventos));
        this.setState(eventosReducer(state, cambiarCargando(false)));
      })
      .catch(e => notification.error({message: 'Error descargando los datos', description: e.mensaje}));
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return <Row>
      <Col span={24}>
        <Skeleton loading={this.state.cargando} active avatar/>
        {this.renderEventos(this.state.eventos.futuros, "Eventos Futuros")}
        {this.renderEventos(this.state.eventos.enCurso, "Eventos En Curso")}
        {this.renderEventos(this.state.eventos.completados, "Eventos Completados")}
        <Pagination
          showQuickJumper
          showSizeChanger
          hideOnSinglePage
          current={this.state.page}
          pageSize={this.state.size}
          total={this.state.total}
          onChange={(page, pageSize) => {
            let state = eventosReducer(this.state, cambiarPagina(page));
            state = eventosReducer(state, cambiarMuestra(pageSize));
            this.setState(eventosReducer(state, cambiarCargando(true)), () => this.cargarDatos());
          }}
          style={{marginTop: 25}}
        />
      </Col>
    </Row>;
  }
}

export default compose(withRouter, withGoogleReCaptcha, CONNECTOR)(Eventos) as React.ComponentType;
