import {
  AdminSuperadminPermisosActionTypes,
  AdminSuperadminPermisosState,
  CAMBIAR_CARGANDO,
  CAMBIAR_NUEVO_VISIBLE,
  GUARDAR_PERMISOS
} from "./types";

export const initialState: AdminSuperadminPermisosState = {
  cargando: true,
  nuevo: false,
  datos: []
}

export function adminSuperadminPermisosReducer(state = initialState, action: AdminSuperadminPermisosActionTypes): AdminSuperadminPermisosState {
  let newState: AdminSuperadminPermisosState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case CAMBIAR_NUEVO_VISIBLE:
      newState.nuevo = !state.nuevo;
        return newState;

    case GUARDAR_PERMISOS:
      newState.datos = action.payload.permisos;
      return newState;

    default:
      return state;
  }
}
