import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {Enlace, Mesa} from "types/misc";
import {Asistente, EventoGrupo, Reserva} from "types/eventos";

export interface InicioProps extends PropsFromRedux, RouteComponentProps {

}

export interface InicioState {
  cargando: boolean;

  eventos: EventoGrupo[];
  reservas: (Reserva | Asistente)[];
  enlaces: Enlace[];
  mesas: Mesa[];
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_EVENTOS = 'GUARDAR_EVENTOS';
export const GUARDAR_RESERVAS = 'GUARDAR_RESERVAS';
export const GUARDAR_ENLACES = 'GUARDAR_ENLACES';
export const GUARDAR_MESAS = 'GUARDAR_MESAS';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando: boolean };
}

interface GuardarEventosAction {
  type: typeof GUARDAR_EVENTOS;
  payload: { eventos: EventoGrupo[] };
}

interface GuardarReservasAction {
  type: typeof GUARDAR_RESERVAS;
  payload: { reservas: Reserva[], asistentes: Asistente[] };
}

interface GuardarEnlacesAction {
  type: typeof GUARDAR_ENLACES;
  payload: { enlaces: Enlace[] };
}

interface GuardarMesasAction {
  type: typeof GUARDAR_MESAS;
  payload: { mesas: Mesa[] };
}

export type InicioActionTypes = CambiarCargandoAction
  | GuardarEventosAction
  | GuardarReservasAction
  | GuardarEnlacesAction
  | GuardarMesasAction;
