import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  CAMBIAR_CARGANDO,
  CAMBIAR_EDITABLE,
  CAMBIAR_IMAGEN,
  CAMBIAR_NOMBRE_EVENTO,
  CAMBIAR_NUMERO_EVENTOS,
  CAMBIAR_SPREADSHEET_EVENTO,
  FormEventoAdminActionTypes,
  NUEVA_SPREADSHEET_EVENTO
} from "./types";

export function cambiarCargando(cargando?: boolean): FormEventoAdminActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function cambiarEditable(editable?: boolean): FormEventoAdminActionTypes {
  return {
    type: CAMBIAR_EDITABLE,
    payload: {editable}
  }
}

export function cambiarImagen(imagen: string | null): FormEventoAdminActionTypes {
  return {
    type: CAMBIAR_IMAGEN,
    payload: {imagen}
  }
}

export function cambiarNumeroEventos(numero: number): FormEventoAdminActionTypes {
  return {
    type: CAMBIAR_NUMERO_EVENTOS,
    payload: {numero}
  }
}

export function cambiarNombreEvento(id: number, nombre: string): FormEventoAdminActionTypes {
  return {
    type: CAMBIAR_NOMBRE_EVENTO,
    payload: {id, nombre}
  }
}

export function cambiarSpreadsheetEvento(id: number, evento: number, hoja: string | null): FormEventoAdminActionTypes {
  return {
    type: CAMBIAR_SPREADSHEET_EVENTO,
    payload: {id, evento, hoja}
  }
}

export function nuevaSpreadsheetEvento(evento?: number): FormEventoAdminActionTypes {
  return {
    type: NUEVA_SPREADSHEET_EVENTO,
    payload: {evento: evento || null}
  }
}


const mapState = (state: RootState) => ({
  cargando: state.formEventoAdmin.cargando,
  editable: state.formEventoAdmin.editable,
  imagen: state.formEventoAdmin.imagen,

  numEventos: state.formEventoAdmin.numEventos,
  nombreEventos: state.formEventoAdmin.nombreEventos,
  spreadsheets: state.formEventoAdmin.spreadsheets,
  nuevaSpreadsheet: state.formEventoAdmin.nuevaSpreadsheet,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  cambiarEditable: cambiarEditable,
  cambiarImagen: cambiarImagen,

  cambiarNumeroEventos: cambiarNumeroEventos,
  cambiarNombreEvento: cambiarNombreEvento,
  cambiarSpreadsheetEvento: cambiarSpreadsheetEvento,
  nuevaSpreadsheetEvento: nuevaSpreadsheetEvento,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
