import {ANADIR_CAMARA, CAMBIAR_CARGADO, LectorQrActionTypes, SELECCIONAR_CAMARA} from "components/lector-qr/types";
import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";

export function cambiarCargado(cargado: boolean): LectorQrActionTypes {
  return {
    type: CAMBIAR_CARGADO,
    payload: {cargado}
  }
}

export function anadirCamara(id: string, nombre: string): LectorQrActionTypes {
  return {
    type: ANADIR_CAMARA,
    payload: {camara: {id, nombre}}
  }
}

export function seleccionarCamara(camara: string): LectorQrActionTypes {
  return {
    type: SELECCIONAR_CAMARA,
    payload: {camara}
  }
}

const mapState = (state: RootState) => ({
  cargado: state.lectorQr.cargado,
  camaras: state.lectorQr.camaras,
  camaraSeleccionada: state.lectorQr.camaraSeleccionada,
});

const mapDispatch = {
  cambiarCargado: cambiarCargado,
  anadirCamara: anadirCamara,
  seleccionarCamara: seleccionarCamara,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;

