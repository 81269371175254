import {
  CAMBIAR_CARGANDO,
  GUARDAR_ENLACES,
  GUARDAR_EVENTOS,
  GUARDAR_MESAS,
  GUARDAR_RESERVAS,
  InicioActionTypes,
  InicioState
} from "./types";
import {
  parsearFechasEventoGrupo,
  parsearFechasMesas,
  parsearFechasReservasAsistentes
} from "components/form-evento-admin/utils";
import {Asistente, Reserva} from "types/eventos";

export const initialState: InicioState = {
  cargando: true,

  eventos: [],
  reservas: [],
  enlaces: [],
  mesas: [],
}

export function inicioReducer(state = initialState, action: InicioActionTypes): InicioState {
  let newState: InicioState = JSON.parse(JSON.stringify(state));
  newState.eventos = parsearFechasEventoGrupo(newState.eventos);
  newState.reservas = parsearFechasReservasAsistentes(newState.reservas);
  newState.mesas = parsearFechasMesas(newState.mesas);

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      return newState;

    case GUARDAR_EVENTOS:
      newState.eventos = action.payload.eventos;
      return newState;

    case GUARDAR_RESERVAS:
      let reservas: (Reserva | Asistente)[] = [];
      let eventos: number[] = [];

      for (let asistente of action.payload.asistentes) {
        if (eventos.indexOf(asistente.evento.id) > -1) continue;
        reservas.push(asistente);
        eventos.push(asistente.evento.id);
      }
      for (let reserva of action.payload.reservas) {
        if (eventos.indexOf(reserva.evento.id) > -1) continue;
        reservas.push(reserva);
        eventos.push(reserva.evento.id);
      }

      newState.reservas = reservas
        .sort((a, b) => a.evento.fecha.unix() - b.evento.fecha.unix());
      return newState;

    case GUARDAR_ENLACES:
      newState.enlaces = action.payload.enlaces;
      return newState;

    case GUARDAR_MESAS:
      newState.mesas = action.payload.mesas;
      return newState;

    default:
      return state;
  }
}
