import {
  AdminMesaEnlacesActionTypes,
  AdminMesaEnlacesState,
  CAMBIAR_CARGANDO,
  GUARDAR_ENLACES,
} from "routes/admin-mesa-enlaces/types";

export const initialState: AdminMesaEnlacesState = {
  cargando: true,
  enlaces: [],
}

export function adminMesaEnlacesReducer(state = initialState, action: AdminMesaEnlacesActionTypes): AdminMesaEnlacesState {
  let newState: AdminMesaEnlacesState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando;
      return newState;

    case GUARDAR_ENLACES:
      newState.enlaces = action.payload.enlaces;
      return newState;

    default:
      return state;
  }
}
