import {Moment} from "moment";

export interface UsuarioBase {
  id: string;
}

export interface UsuarioSimple {
  nombre: string;
  apellidos: string;
  avatar: string;
}

export interface Usuario extends UsuarioSimple {
  dni: string;
  email: string;
  fechaNacimiento: string;
  telefono: string;
}

export const UsuarioDummy: Usuario = {
  nombre: 'Dummy',
  apellidos: 'Pasoinfo',
  avatar: '',
  dni: '12345678Z',
  email: 'dummy@pasoinfo.xyz',
  fechaNacimiento: '1970-01-01',
  telefono: '+34644490013',
}


export interface Invitado {
  dni: string;
  pin: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento?: Moment;
  email: string;
  telefono: string;
  instagram?: string;
}
