import {
  BORRAR_SELECCION,
  CAMBIAR_CARGANDO,
  CAMBIAR_SELECCION,
  DrawerSelectorEventosActionTypes,
  DrawerSelectorEventoState,
  GUARDAR_DATOS,
} from "./types";
import {parsearFechasEventoGrupo} from "components/form-evento-admin/utils";

export const initialState: DrawerSelectorEventoState = {
  cargando: false,
  grupos: [],
  seleccion: null,
}

export function drawerSelectorEventoReducer(state = initialState, action: DrawerSelectorEventosActionTypes): DrawerSelectorEventoState {
  let newState: DrawerSelectorEventoState = JSON.parse(JSON.stringify(state));
  newState.grupos = parsearFechasEventoGrupo(newState.grupos);
  newState.seleccion = newState.seleccion !== null ? parsearFechasEventoGrupo([newState.seleccion])[0] : null;

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case GUARDAR_DATOS:
      newState.grupos = action.payload.grupos;
      return newState;

    case CAMBIAR_SELECCION:
      newState.seleccion = action.payload.seleccion;
      return newState;

    case BORRAR_SELECCION:
      newState.seleccion = null;
      return newState;

    default:
      return state;
  }
}
