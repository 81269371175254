import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {AdminSuperadminPermisosActionTypes, CAMBIAR_CARGANDO, CAMBIAR_NUEVO_VISIBLE, GUARDAR_PERMISOS, PermisoDTO} from "./types";

export function cambiarCargando(cargando?: boolean): AdminSuperadminPermisosActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function cambiarNuevoVisible(): AdminSuperadminPermisosActionTypes {
  return {
    type: CAMBIAR_NUEVO_VISIBLE
  }
}

export function guardarPermisos(permisos: PermisoDTO[]): AdminSuperadminPermisosActionTypes {
  return {
    type: GUARDAR_PERMISOS,
    payload: {permisos}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.adminSuperadminPermisos.cargando,
  nuevo: state.adminSuperadminPermisos.nuevo,
  permisos: state.adminSuperadminPermisos.permisos
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  cambiarNuevoVisible: cambiarNuevoVisible,
  guardarPermisos: guardarPermisos
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
