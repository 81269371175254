import {EventoGrupo, Reserva} from "types/eventos";
import {PropsFromRedux} from "routes/admin-reservas/actions";
import {RouteComponentProps} from "react-router-dom";

export interface ModalReservarCustomProps {
  mostrar: boolean,
  evento?: string;
  permitirAnonimo?: boolean;

  onReservar(reserva: Reserva[]): void;

  onCancelar(): void;
}

export interface ModalReservarProps extends PropsFromRedux, RouteComponentProps, ModalReservarCustomProps {
}

export interface ModalReservarState {
  cargando: boolean;
  grupo: EventoGrupo | null;
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_GRUPO = 'GUARDAR_GRUPO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: {cargando: boolean};
}

interface GuardarGrupoAction {
  type: typeof GUARDAR_GRUPO;
  payload: {grupo?: EventoGrupo};
}

export type ModalReservarActionTypes = CambiarCargandoAction
  | GuardarGrupoAction;
