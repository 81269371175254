import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";
import {RouteComponentProps} from "react-router-dom";
import {Asistente, EventoGrupo, Reserva} from "types/eventos";
import {PropsFromRedux} from "routes/evento/actions";

export interface EventoRouteProps {
  uuid: string;
}

export interface EventoProps extends PropsFromRedux, IWithGoogleReCaptchaProps, RouteComponentProps<EventoRouteProps> {

}

export interface EventoState {
  cargando: boolean;
  grupo: EventoGrupo | null;
  abierto: boolean;

  reservas: Reserva[] | null;
  asistentes: Asistente[] | null;

  modal: {
    reservar: boolean;
    reserva: boolean;
    confirmar: Reserva | null;
    pin: boolean;
  }
}

export type EVENTO_MODALS = "reservar" | "reserva" | "confirmar" | "pin";

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const CARGAR_EVENTO_GRUPO = 'CARGAR_EVENTO_GRUPO';
export const COMPROBAR_INSCRIPCIONES_ABIERTAS = 'COMPROBAR_INSCRIPCIONES_ABIERTAS';
export const GUARDAR_RESERVA = 'GUARDAR_RESERVA';

export const MOSTRAR_MODAL = 'MOSTRAR_MODAL';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando: boolean };
}

interface CargarEventoGrupoAction {
  type: typeof CARGAR_EVENTO_GRUPO;
  payload: { grupo: EventoGrupo; };
}

interface ComprobarInscripcionesAbiertas {
  type: typeof COMPROBAR_INSCRIPCIONES_ABIERTAS;
  payload: { abierto: boolean; };
}

interface GuardarReservaAction {
  type: typeof GUARDAR_RESERVA;
  payload: { reserva: Reserva[]; asistentes: Asistente[]; };
}

interface MostrarModalAction {
  type: typeof MOSTRAR_MODAL;
  payload: { modal: EVENTO_MODALS; visible: boolean | Reserva | null; };
}

export type EventoActionTypes = CambiarCargandoAction
  | CargarEventoGrupoAction
  | ComprobarInscripcionesAbiertas
  | GuardarReservaAction
  | MostrarModalAction;
