import React from "react";
import {Icon as IconMDI} from "@mdi/react";
import {IconProps} from "@mdi/react/dist/IconProps";
import './icon.less';

class Icon extends React.Component<IconProps> {
  render() {
    return <IconMDI
      className="anticon mdi-icon"
      size={this.props.size || 1}
      path={this.props.path}
      style={this.props.style}
    />;
  }
}

export default Icon;
