import {
  CAMBIAR_CARGANDO,
  GUARDAR_USUARIO,
  ModalUsuarioBusquedaActionTypes,
  ModalUsuarioState,
  RESETEAR_SELECCION
} from "./types";

export const initialState: ModalUsuarioState = {
  cargando: false,
  seleccion: null
}

export function modalUsuarioBusquedaReducer(state = initialState, action: ModalUsuarioBusquedaActionTypes): ModalUsuarioState {
  let newState: ModalUsuarioState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case GUARDAR_USUARIO:
      newState.seleccion = {id: action.payload.id};
      return newState;

    case RESETEAR_SELECCION:
      newState.seleccion = null;
      return newState;

    default:
      return state;
  }
}
