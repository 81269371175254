import {Moment} from "moment";
import {Invitado, Usuario} from "types/usuarios";


export enum EstadoEntrada {
  EN_COLA = "en_cola",
  ASIGNADA = "asignada",
  CONFIRMADA = "confirmada",
  PAGADA = "pagada",
  ENTREGADA = "entregada",
}


export interface EventoGrupo {
  id: string;
  nombre: string;
  descripcion: string | null;
  imagen: string | null;

  fechaApertura: Moment;
  fechaCierre: Moment | null;
  numEntradasAdmin: number;
  visible: boolean;
  activo: boolean;

  eventos?: Evento[];
}

export interface Evento {
  id: number;
  grupo?: EventoGrupo;
  nombre: string;
  localizacion: string;
  fecha: Moment;

  precio: number;
  requiere18: boolean;
  requiereNoCovid: boolean;
  requiereCuenta: boolean;
  requiereNominativas: boolean;
  requiereEntrega: boolean;

  entradasTotales: number;
  entradasMax: number;

  entradasReservadas: number;
  entradasAsignadas: number;
  entradasConfirmadas: number;
  entradasPagadas: number;
  entradasEntregadas: number;

  hayEntradasDisponibles: boolean;
  spreadsheet?: string | null;
}


export interface Reserva {
  id: number;
  evento: Evento;
  usuario: Usuario | null;
  invitado: Invitado | null;
  fecha: Moment;
  notas: string;

  numEntradas: number;
  fechaAsignadas: Moment | null;

  estado: EstadoEntrada;
  numReservasDelante?: number;
  numEntradasDelante?: number;
}


export interface Asistente {
  id: number;
  evento: Evento;
  reserva: Reserva | null;
  usuario: Usuario | null;
  invitado: Invitado | null;
  fecha: Moment;

  estado: EstadoEntrada;
  fechaPago: Moment | null;
  fechaEntrega: Moment | null;

  entrada: string;
  notas: string;
}

export interface CambioEstado {
  evento: Evento;
  usuario: Usuario | null;
  invitado: Invitado | null;
  fecha: Moment;

  estadoAntes: EstadoEntrada | null;
  estadoAhora: EstadoEntrada | null;
  admin: Usuario | null;
  notas: string;
}
