import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {AccesoActionTypes, CAMBIAR_CARGANDO} from "./types";

export function cambiarCargando(cargando?: boolean): AccesoActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.acceso.cargando
});

const mapDispatch = {
  cambiarCargando: cambiarCargando
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
