import {
  AdminMesaMesasActionTypes,
  CAMBIAR_CARGANDO,
  GUARDAR_MESAS
} from "routes/admin-mesa-mesas/types";
import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {Mesa} from "types/misc";

export function cambiarCargando(cargando: boolean): AdminMesaMesasActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarMesas(mesas: Mesa[]): AdminMesaMesasActionTypes {
  return {
    type: GUARDAR_MESAS,
    payload: {mesas}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.adminMesaMesas.cargando,
  mesas: state.adminMesaMesas.mesas,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarMesas: guardarMesas,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;

