import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";

export interface FormPerfilCustomProps {
  uuid?: string;
  simple?: boolean;
}

export interface FormPerfilProps extends PropsFromRedux, IWithGoogleReCaptchaProps, RouteComponentProps, FormPerfilCustomProps {
}

export interface FormPerfilState {
  cargando: boolean;
  editable: boolean;
  avatar: string;

  desactivar: boolean;
  clave: boolean;
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const CAMBIAR_EDITABLE = 'CAMBIAR_EDITABLE';
export const CAMBIAR_AVATAR = 'CAMBIAR_AVATAR';

export const DESACTIVAR_CAMPOS = 'DESACTIVAR_CAMPOS';
export const EDITAR_CLAVE = 'EDITAR_CLAVE';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface CambiarEditableAction {
  type: typeof CAMBIAR_EDITABLE;
  payload: { editable?: boolean }
}

interface CambiarAvatarAction {
  type: typeof CAMBIAR_AVATAR;
  payload: { avatar: string }
}

interface DesactivarCampos {
  type: typeof DESACTIVAR_CAMPOS;
}

interface EditarClaveAction {
  type: typeof EDITAR_CLAVE;
}

export type FormPerfilActionTypes = CambiarCargandoAction
  | CambiarEditableAction
  | CambiarAvatarAction
  | DesactivarCampos
  | EditarClaveAction;
