import React from "react";
import {CerrarSesionProps, CerrarSesionState} from "./types";
import {Button, Card, Result} from "antd";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import {borrarToken} from "utils/token";
import AppRoutes from "routes";
import {withRouter} from "react-router-dom";

class CerrarSesion extends React.Component<CerrarSesionProps, CerrarSesionState> {
  constructor(props: CerrarSesionProps) {
    super(props);
    this.state = {};
  }

  private static redirigir() {
    window.location.href = AppRoutes.INICIO;
  }

  componentDidMount() {
    borrarToken();
    setTimeout(() => CerrarSesion.redirigir(), 5000);
  }

  render() {
    return <Card>
      <Result
        icon={<Icon path={MDI.mdiHandWave} size={5}/>}
        title="Se ha cerrado sesión"
        subTitle="En unos segundos serás redirigido automáticamente; sino haz click en este botón."
        extra={<Button type="primary" onClick={() => CerrarSesion.redirigir()}>Continuar</Button>}
      />
    </Card>;
  }
}

export default withRouter(CerrarSesion);
