import {RouteComponentProps} from "react-router-dom";

export interface LectorQrCustomProps {
  visible: boolean;

  onLeer(datos: any): void;

  onCancelar(): void;
}

export interface LectorQrProps extends LectorQrCustomProps, RouteComponentProps {

}

export interface LectorQrState {
  cargado: boolean;

  camaras: Camara[];
  camaraSeleccionada: string | undefined;
}

export interface Camara {
  id: string;
  nombre: string;
}


export const CAMBIAR_CARGADO = 'CAMBIAR_CARGADO';
export const ANADIR_CAMARA = 'ANADIR_CAMARA';
export const SELECCIONAR_CAMARA = 'SELECCIONAR_CAMARA';

interface CambiarCargadoAction {
  type: typeof CAMBIAR_CARGADO;
  payload: { cargado: boolean; };
}

interface AnadirCamaraAction {
  type: typeof ANADIR_CAMARA;
  payload: { camara: Camara; };
}

interface SeleccionarCamaraAction {
  type: typeof SELECCIONAR_CAMARA;
  payload: { camara: string; };
}

export type LectorQrActionTypes = CambiarCargadoAction
  | AnadirCamaraAction
  | SeleccionarCamaraAction;
