import {
  ANADIR_CAMARA,
  CAMBIAR_CARGADO,
  LectorQrActionTypes,
  LectorQrState,
  SELECCIONAR_CAMARA
} from "components/lector-qr/types";

export const initialState: LectorQrState = {
  cargado: false,
  camaras: [],
  camaraSeleccionada: undefined
}

export function lectorQrReducer(state = initialState, action: LectorQrActionTypes): LectorQrState {
  let newState: LectorQrState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGADO:
      newState.cargado = action.payload.cargado;
      return newState;

    case ANADIR_CAMARA:
      newState.camaras.push(action.payload.camara);
      return newState;

    case SELECCIONAR_CAMARA:
      newState.camaraSeleccionada = action.payload.camara;
      return newState;

    default:
      return state;
  }
}
