import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";

export interface AccesoProps extends PropsFromRedux, RouteComponentProps {

}

export interface AccesoState {
  cargando: boolean;
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

export type AccesoActionTypes = CambiarCargandoAction;
