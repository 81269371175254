import React from "react";
import {ModalUsuarioCustomProps, ModalUsuarioProps, ModalUsuarioState} from "./types";
import {initialState, modalUsuarioBusquedaReducer} from "./reducers";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {cambiarCargando, CONNECTOR, guardarUsuario, resetearSeleccion} from "./actions";
import {Input, Modal, notification} from "antd";
import assert from "assert";
import {Endpoints, get} from "utils/http";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import FormPerfil from "components/form-perfil";

class ModalUsuarioBusqueda extends React.Component<ModalUsuarioProps, ModalUsuarioState> {
  constructor(props: ModalUsuarioProps) {
    super(props);
    this.state = initialState;
  }

  render() {
    return <>
      <Modal
        title={
          this.props.tipo ?
            (this.props.tipo === "usuario" ?
              "Busca un usuario" :
              "Introduce los datos manualmente") :
            "Busca un usuario o introduce sus datos manualmente"}
        visible={this.props.visible}
        onOk={() => {
          assert(this.state.seleccion !== null);
          this.props.onSeleccion(this.state.seleccion);
        }}
        onCancel={() => this.props.onCancelar()}
        okButtonProps={{disabled: this.state.seleccion === null}}
      >
        <Input.Search
          placeholder="DNI / NIE / Pasaporte"
          allowClear
          enterButton={<Icon path={MDI.mdiMagnify}/>}
          size="large"
          required
          disabled={this.state.cargando}
          loading={this.state.cargando}
          onSearch={value => {
            let state = modalUsuarioBusquedaReducer(this.state, resetearSeleccion());
            this.setState(modalUsuarioBusquedaReducer(state, cambiarCargando()), () => {
              get(Endpoints.USUARIO_BUSQUEDA.replace(":dni", value))
                .then(res => {
                  let state = modalUsuarioBusquedaReducer(this.state, guardarUsuario(res.id));
                  this.setState(modalUsuarioBusquedaReducer(state, cambiarCargando()));
                })
                .catch(res => {
                  notification.error({message: "Error", description: res.mensaje});
                  this.setState(modalUsuarioBusquedaReducer(this.state, cambiarCargando()));
                })
            })
          }}
        />
        {this.state.seleccion === null ? <></> : <>
          {'id' in this.state.seleccion ? <FormPerfil uuid={this.state.seleccion.id} simple/> : <></>}
        </>}
      </Modal>
    </>;
  }
}

export default compose(withRouter, CONNECTOR)(ModalUsuarioBusqueda) as React.ComponentType<ModalUsuarioCustomProps>;
