import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {
  Badge,
  Button,
  Descriptions,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Space,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography
} from "antd";
import {AdminReservasProps, AdminReservasState, RespuestaDTO} from "routes/admin-reservas/types";
import {adminReservasReducer, DEFAULT_FILTROS_SIZE, initialState} from "routes/admin-reservas/reducers";
import {
  cambiarCargando,
  cambiarDrawerVisible,
  confirmarReserva,
  CONNECTOR,
  guardarDatos,
  guardarFiltros,
  nuevaReserva,
  seleccionarReservas,
} from "routes/admin-reservas/actions";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import {Endpoints, get, post} from "utils/http";
import AppRoutes from "routes/index";
import {ColumnsType} from "antd/lib/table/interface";
import {Asistente, EstadoEntrada, EventoGrupo, Reserva} from "types/eventos";
import DrawerSelectorEvento from "components/drawer-selector-evento";
import assert from "assert";
import ModalConfirmar from "components/modal-confirmar";
import {Invitado} from "types/usuarios";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import PopoverUsuario from "components/popover-usuario";
import ModalReservar from "components/modal-reservar";

export const getEstadoReserva = (reserva: Reserva | Asistente) => {
  return AdminReservas.getEstado(reserva);
}

class AdminReservas extends React.Component<AdminReservasProps, AdminReservasState> {
  constructor(props: AdminReservasProps) {
    super(props);
    this.state = initialState;

    this.getColumnas = this.getColumnas.bind(this);
    this.getTituloTabla = this.getTituloTabla.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);

    this.onAceptarDrawer = this.onAceptarDrawer.bind(this);
    this.onCancelarDrawer = this.onCancelarDrawer.bind(this);

    this.onNotas = this.onNotas.bind(this);
    this.onAsignar = this.onAsignar.bind(this);
    this.onConfirmar = this.onConfirmar.bind(this);
    this.onConfirmarAsistentes = this.onConfirmarAsistentes.bind(this);
    this.onCancelar = this.onCancelar.bind(this);

    this.onAsignarBulk = this.onAsignarBulk.bind(this);
    this.onConfirmarBulk = this.onConfirmarBulk.bind(this);
    this.onCancelarBulk = this.onCancelarBulk.bind(this);
    this.onNotificarBulk = this.onNotificarBulk.bind(this);
  }

  private isFiltraEvento(): boolean {
    return this.props.match.params.uuid !== undefined;
  }

  public static getEstado(reserva: Reserva | Asistente) {
    let estado = {nombre: "OCULTO", color: "default", descripcion: ""};
    switch (reserva.estado) {
      case EstadoEntrada.EN_COLA:
        estado = {nombre: "EN COLA", color: "red", descripcion: "Todavía no se han asignado las entradas"};
        break;
      case EstadoEntrada.ASIGNADA:
        assert("fechaAsignadas" in reserva && reserva.fechaAsignadas !== null);
        estado = {
          nombre: "ASIGNADA", color: "gold",
          descripcion: "Se han asignado las entradas " + reserva.fechaAsignadas.local().fromNow() + ", pero no se ha confirmado"
        };
        break;
      case EstadoEntrada.CONFIRMADA:
        estado = {nombre: "CONFIRMADA", color: "green", descripcion: "Se han confirmado los asistentes"};
        break;
      case EstadoEntrada.PAGADA:
        estado = {nombre: "PAGADA", color: "blue", descripcion: "Se han pagado las entradas"};
        break;
      case EstadoEntrada.ENTREGADA:
        estado = {nombre: "ENTREGADA", color: "purple", descripcion: "Se han entregado las entradas físicas"};
        break;
    }
    return <Tooltip title={estado.descripcion}><Tag color={estado.color}>{estado.nombre}</Tag></Tooltip>;
  }

  private getColumnas(): ColumnsType<Reserva> {
    return [
      {
        title: 'Usuario / Invitado',
        dataIndex: 'usuario',
        key: 'usuario',
        render: (texto: string, record: Reserva) => <PopoverUsuario usuario={record.usuario || record.invitado}/>,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
          <div style={{padding: 8}}>
            <Tooltip title="Puedes buscar por Nombre, Apellidos, DNI, Email o Teléfono">
              <Input
                placeholder="Buscar..."
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{marginBottom: 8, display: 'block'}}
              />
            </Tooltip>
            <Space align="center" size="large">
              <Button size="small" block onClick={() => {
                if (clearFilters) clearFilters();
                confirm();
              }}>
                Resetear
              </Button>
              <Button
                type="primary" icon={<Icon path={MDI.mdiMagnify} size={0.8}/>} size="small"
                block onClick={() => confirm()}
              >
                Buscar
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <Icon
          path={MDI.mdiMagnify} style={{color: filtered ? '#1890ff' : undefined}} size={0.8}
        />
      },
      {
        title: '#',
        dataIndex: 'numEntradas',
        key: 'numEntradas',
        align: "center",
        render: (texto: string) => texto + " Entrada" + (parseInt(texto) === 1 ? "" : "s")
      },
      {
        title: 'Evento',
        dataIndex: ['evento', 'nombre'],
        key: 'evento',
        render: (texto: string, record: Reserva) => <Tooltip title={record.evento.grupo?.nombre}>
          {record.evento.nombre}
        </Tooltip>
      },
      {
        title: 'Fecha Reserva',
        dataIndex: 'fecha',
        key: 'fecha',
        render: (texto: string, record: Reserva, index: number) => {
          return <Popover
            title={record.fecha.local().fromNow()}
            content={<Typography.Text code>{record.fecha.toISOString()}</Typography.Text>}
          >
            {record.fecha.local().format("DD/MM/YYYY HH:mm")}
          </Popover>
        },
        sorter: (a: Reserva, b: Reserva) => 0
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        align: 'center',
        render: (texto: string, record: Reserva, index: number) => AdminReservas.getEstado(record),
        filters: [
          {text: 'EN COLA', value: 'en_cola'},
          {text: 'ASIGNADA', value: 'asignada'},
          // TODO: Resto de filtros? Quizás es mejor dejar que no se filtre, ya que no influye a reservas
          // que esté pagado o no
          // {text: 'CONFIRMADA', value: 'confirmada'},
          // {text: 'PAGADA', value: 'pagada'},
          // {text: 'ENTREGADA', value: 'entregada'},
        ],
      },
      {
        title: '',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        render: (texto: string, record: Reserva) => <>
          <Popover title="Notas" content={<Typography.Text italic>{record.notas}</Typography.Text>}>
            <Badge dot count={record.notas === "" ? 0 : 1} offset={[-10, 5]}>
              <Button
                icon={<Icon path={MDI.mdiNotebookEdit} style={{marginBottom: 0, marginLeft: 3}}/>}
                style={{marginRight: 5}} type="link"
                onClick={() => this.onNotas(record)}
              />
            </Badge>
          </Popover>

          <Tooltip title="Asignar Entradas"><Button
            icon={<Icon
              path={record.estado === "en_cola" ? MDI.mdiBookmarkPlus : MDI.mdiBookmarkCheck}
              style={{marginBottom: 0, marginLeft: 3}}
            />}
            style={{marginRight: 5}} disabled={record.estado !== "en_cola"}
            onClick={() => this.onAsignar(record)}
          /></Tooltip>

          <Tooltip title="Confirmar Asistentes"><Button
            icon={<Icon path={MDI.mdiAccountCheck} style={{marginBottom: 0, marginLeft: 3}}/>}
            type="primary" style={{marginRight: 5}} disabled={record.estado !== "asignada"}
            onClick={() => this.onConfirmar(record)}
          /></Tooltip>

          <Tooltip title="Cancelar Reserva"><Button
            icon={<Icon path={MDI.mdiCancel} style={{marginBottom: 0, marginLeft: 3}}/>} type="primary" danger
            onClick={() => this.onCancelar(record)}
            disabled={record.estado !== "en_cola" && record.estado !== "asignada" && record.estado !== "confirmada"}
          /></Tooltip>
        </>,
      }
    ];
  }

  private getTituloTabla() {
    if (this.state.seleccionadas.length > 0) {
      return <Space>
        <Tooltip title="Se asignarán las entradas, independientemente del límite total">
          <Button
            disabled={this.state.seleccionadas.find(r => r.estado !== "en_cola") !== undefined}
            shape="round" icon={<Icon path={MDI.mdiBookmarkCheck}/>}
            onClick={() => this.onAsignarBulk()}
          >
            Asignar Entradas
          </Button>
        </Tooltip>
        <Tooltip
          title="Se confirmarán los asistentes asignándole todas las entradas a la persona que reserva (no se puede si el evento requiere entradas nominativas)">
          <Button
            disabled={this.state.seleccionadas.find(r => r.evento.requiereNominativas || r.estado !== "asignada") !== undefined}
            shape="round" type="primary" icon={<Icon path={MDI.mdiAccountCheck}/>}
            onClick={() => this.onConfirmarBulk()}
          >
            Confirmar Asistentes
          </Button>
        </Tooltip>
        <Tooltip title="Se cancelarán las reservas si no se han pagado o entregado">
          <Button
            disabled={this.state.seleccionadas.find(r => r.estado !== "en_cola" && r.estado !== "asignada" && r.estado !== "confirmada") !== undefined}
            shape="round" type="primary" danger icon={<Icon path={MDI.mdiCancel}/>}
            onClick={() => this.onCancelarBulk()}
          >
            Cancelar Reservas
          </Button>
        </Tooltip>
        <Button
          shape="round" type="primary" icon={<Icon path={MDI.mdiMessageDraw}/>}
          onClick={() => this.onNotificarBulk()}
        >
          Mandar Mensaje
        </Button>
      </Space>;
    }

    if (this.isFiltraEvento()) {
      return <Space>
        <Button
          type="primary" shape="round"
          icon={<Icon path={MDI.mdiArrowLeft}/>}
          onClick={() => {
            this.props.history.push(AppRoutes.ADMIN_RESERVAS);
            this.setState(adminReservasReducer(this.state, cambiarCargando(true)), () => {
              this.cargarDatos();
            });
          }}
        >
          Volver
        </Button>
        <Button
          type="default" shape="round"
          icon={<Icon path={MDI.mdiViewGridPlus}/>}
          onClick={() => {
            this.setState(adminReservasReducer(this.state, nuevaReserva(true)));
          }}
        >
          Nueva Reserva
        </Button>
      </Space>;
    }

    return <Button
      type="primary" shape="round"
      icon={<Icon path={MDI.mdiCalendarArrowRight}/>}
      onClick={() => {
        this.setState(adminReservasReducer(this.state, cambiarDrawerVisible(true)));
      }}
    >
      Filtrar Evento
    </Button>
  }

  private onNotas(reserva: Reserva) {
    let u = reserva.usuario || reserva.invitado;
    assert(u !== null);

    let form = React.createRef<FormInstance>();

    Modal.info({
      title: 'Editar notas de ' + u.nombre, closable: true, maskClosable: true,
      content: <Form size="middle" ref={form} initialValues={{notas: reserva.notas}}>
        <Form.Item name="notas" style={{marginTop: 15, marginBottom: 5}}>
          <Input.TextArea/>
        </Form.Item>
      </Form>,
      onOk: () => {
        form.current?.validateFields().then(() => {
          Modal.destroyAll();
          this.setState(adminReservasReducer(this.state, cambiarCargando(true)), () => {
            post(Endpoints.ADMIN_RESERVAS_NOTAS, {
              reserva: reserva.id,
              notas: form.current?.getFieldValue('notas') || ""
            })
              .then(res => {
                notification.success({message: 'Listo', description: 'Se ha actualizado la nota'});
                this.cargarDatos();
              })
              .catch(e => {
                notification.error({message: 'Error cambiando la nota', description: e.descripcion});
                this.setState(adminReservasReducer(this.state, cambiarCargando(false)));
              })
          });
        });
        return true;
      }
    });
  }

  private onAsignar(reserva: Reserva) {
    if (reserva.estado !== "en_cola") return;

    let modal = Modal.success;
    if (reserva.evento.entradasAsignadas + reserva.numEntradas > reserva.evento.entradasTotales) {
      modal = Modal.warning;
    }

    let u = reserva.usuario || reserva.invitado;
    assert(u !== null);

    let form = React.createRef<FormInstance>();

    modal({
      title: 'Asignar entradas', closable: true, maskClosable: true,
      content: <Form size="middle" ref={form} initialValues={{numEntradas: reserva.numEntradas}}>
        <Statistic
          title="Entradas Restantes" value={reserva.evento.entradasTotales - reserva.evento.entradasAsignadas}
          suffix={"/ " + reserva.evento.entradasTotales}
        />

        <Form.Item label="Nombre" style={{marginTop: 15, marginBottom: 5}}>
          <Input value={u.nombre + " " + u.apellidos} disabled/>
        </Form.Item>
        <Form.Item label="DNI"><Input value={u.dni} disabled/></Form.Item>

        <Form.Item
          label="Número de Entradas" name="numEntradas" required
          rules={[{required: true, message: "Introduce el número de entradas"}]}
        >
          <InputNumber min={1} max={reserva.numEntradas}/>
        </Form.Item>
      </Form>,
      onOk: () => {
        form.current?.validateFields().then(() => {
          Modal.destroyAll();
          this.setState(adminReservasReducer(this.state, cambiarCargando(true)), () => {
            post(Endpoints.ADMIN_RESERVAS_ASIGNAR, {
              reserva: reserva.id,
              numEntradas: parseInt(form.current?.getFieldValue('numEntradas'))
            })
              .then(res => {
                notification.success({message: 'Listo', description: 'Se han asignado las entradas'});
                this.cargarDatos();
              })
              .catch(e => {
                notification.error({message: 'Error asignando las entradas', description: e.descripcion});
                this.setState(adminReservasReducer(this.state, cambiarCargando(false)));
              })
          });
        });
        return true;
      }
    });
  }

  private onConfirmar(reserva: Reserva) {
    this.setState(adminReservasReducer(this.state, confirmarReserva(reserva)));
  }

  private onConfirmarAsistentes(invitados: Invitado[], numero?: number) {
    let reserva = this.state.confirmarReserva;
    let state = adminReservasReducer(this.state, confirmarReserva());
    state = adminReservasReducer(state, cambiarCargando(true));
    this.setState(state, () => {
      post(Endpoints.ADMIN_RESERVAS_CONFIRMAR, {
        reserva: reserva?.id,
        invitados: invitados,
        numero: numero,
      })
        .then(res => {
          notification.success({message: 'Entradas confirmadas', description: 'Ya puede pagarlas y/o recogerlas'});
          this.cargarDatos();
        })
        .catch(e => {
          notification.error({message: 'Error confirmando las entradas', description: e.descripcion});
          this.setState(adminReservasReducer(this.state, cambiarCargando(false)));
        });
    });
  }

  private onCancelar(reserva: Reserva) {
    let u = reserva.usuario || reserva.invitado;
    assert(u !== null);

    Modal.error({
      title: 'Cancelar reserva de ' + u.nombre, closable: true, maskClosable: true,
      content: <>
        <Typography.Text>
          <Typography.Text strong>¿Estás seguro de querer cancelar la reserva?</Typography.Text> Quedará un registro de
          la acción, con el momento y el administrador.
        </Typography.Text>
      </>,
      onOk: () => {
        this.setState(adminReservasReducer(this.state, cambiarCargando(true)), () => {
          post(Endpoints.ADMIN_RESERVAS_CANCELAR, {reserva: reserva.id})
            .then(res => {
              notification.success({message: 'Listo', description: 'Se ha cancelado la reserva'});
              this.cargarDatos();
            })
            .catch(e => {
              notification.error({message: 'Error cancelando la reserva', description: e.descripcion});
              this.setState(adminReservasReducer(this.state, cambiarCargando(false)));
            });
        });
      }
    });
  }

  private onAsignarBulk() {
    let eventos: any = {};

    let warning = false;
    this.state.seleccionadas.forEach(r => {
      if (!(("" + r.evento.id) in eventos)) {
        eventos["" + r.evento.id] = {
          totales: r.evento.entradasTotales,
          restantes: r.evento.entradasTotales - r.evento.entradasAsignadas,
          pedidas: 0
        };
      }

      eventos["" + r.evento.id] = {
        ...eventos["" + r.evento.id],
        pedidas: eventos["" + r.evento.id].pedidas + r.numEntradas
      };

      if (!warning && eventos["" + r.evento.id].pedidas > eventos["" + r.evento.id].restantes) {
        warning = true;
      }
    });

    let modal = warning ? Modal.warning : Modal.success;
    const nEntradas = this.state.seleccionadas.map(r => r.numEntradas).reduce((acc, a) => acc + a, 0);

    modal({
      title: 'Asignar ' + nEntradas + ' entrada' + (nEntradas !== 1 ? "s" : ""),
      closable: true, maskClosable: true,
      content: <Descriptions bordered>
        {Object.entries(eventos).map(k => {
          let eId = k[0], d: any = k[1];
          return <>
            <Descriptions.Item
              label={this.state.seleccionadas.find(r => r.evento.id === parseInt(eId))?.evento.nombre}
              key={eId}
            >
              <Typography.Text type={d.pedidas > d.restantes ? "danger" : undefined}>
                Asignando {d.pedidas} entradas de {d.restantes} restantes.
              </Typography.Text>
            </Descriptions.Item>
          </>;
        })}
      </Descriptions>,
      onOk: () => {
        this.setState(adminReservasReducer(this.state, cambiarCargando(true)), async () => {
          for (let reserva of this.state.seleccionadas) {
            try {
              await post(Endpoints.ADMIN_RESERVAS_ASIGNAR, {
                reserva: reserva.id,
                numEntradas: reserva.numEntradas
              });
            } catch (e: any) {
              notification.error({
                message: 'Error asignando las entradas de ' + (reserva.usuario?.dni || reserva.invitado?.dni) + ' para ' + reserva.evento.nombre,
                description: e.descripcion
              });
              this.cargarDatos();
              break;
            }
          }

          notification.success({message: 'Listo', description: 'Se han asignado las entradas'});
          this.cargarDatos();
        });
      }
    });
  }

  private onConfirmarBulk() {
    Modal.success({
      title: 'Confirmar ' + this.state.seleccionadas.map(r => r.numEntradas).reduce((acc, a) => acc + a) + ' entradas',
      closable: true, maskClosable: true,
      content: <Typography.Text>
        Se confirmaran las entradas asignándolas a la persona que hace la reserva.
      </Typography.Text>,
      onOk: () => {
        this.setState(adminReservasReducer(this.state, cambiarCargando(true)), async () => {
          for (let reserva of this.state.seleccionadas) {
            try {
              await post(Endpoints.ADMIN_RESERVAS_CONFIRMAR, {reserva: reserva.id, invitados: []});
            } catch (e: any) {
              notification.error({
                message: 'Error confirmando la reserva de ' + (reserva.usuario?.dni || reserva.invitado?.dni) + ' para ' + reserva.evento.nombre,
                description: e.descripcion
              });
              this.cargarDatos();
              break;
            }
          }

          notification.success({message: 'Listo', description: 'Se han confirmado las entradas'});
          this.cargarDatos();
        });
      }
    });
  }

  private onCancelarBulk() {
    Modal.error({
      title: 'Cancelar ' + this.state.seleccionadas.length + ' reserva' + (this.state.seleccionadas.length === 1 ? "" : "s"),
      closable: true, maskClosable: true,
      content: <>
        <Typography.Text>
          <Typography.Text strong>¿Estás seguro de querer cancelar todas estas reserva (
            {this.state.seleccionadas.map(r => r.numEntradas).reduce((acc, a) => acc + a, 0)} entradas)?
          </Typography.Text> Quedará un registro de la acción, con el momento y el administrador.
        </Typography.Text>
      </>,
      onOk: () => {
        this.setState(adminReservasReducer(this.state, cambiarCargando(true)), async () => {
          for (let reserva of this.state.seleccionadas) {
            try {
              await post(Endpoints.ADMIN_RESERVAS_CANCELAR, {reserva: reserva.id});
            } catch (e: any) {
              notification.error({
                message: 'Error cancelando la reserva de ' + (reserva.usuario?.dni || reserva.invitado?.dni) + ' para ' + reserva.evento.nombre,
                description: e.descripcion
              });
              this.cargarDatos();
              break;
            }
          }

          notification.success({message: 'Listo', description: 'Se han cancelado las reservas'});
          this.cargarDatos();
        });
      }
    });
  }

  private onNotificarBulk() {
    let form = React.createRef<FormInstance>();

    Modal.info({
      title: 'Mandar notificaciones a los usuarios notificados',
      closable: true, maskClosable: true,
      content: <Form size="middle" ref={form}>
        <Form.Item name="mensaje" style={{marginTop: 15, marginBottom: 5}} required>
          <Input.TextArea/>
        </Form.Item>
      </Form>,
      onOk: () => {
        form.current?.validateFields().then(() => {
          Modal.destroyAll();
          this.setState(adminReservasReducer(this.state, cambiarCargando(true)), () => {
            post(Endpoints.ADMIN_RESERVAS_NOTIFICAR, {
              reservas: this.state.seleccionadas.map(r => r.id),
              mensaje: form.current?.getFieldValue('mensaje'),
            })
              .then(res => {
                notification.success({message: 'Listo', description: 'Se han enviado los mensajes'});
                this.cargarDatos();
              })
              .catch(e => {
                notification.error({message: 'Error enviando las notas', description: e.descripcion});
                this.setState(adminReservasReducer(this.state, cambiarCargando(false)));
              })
          });
        });
        return true;
      }
    })
  }

  private cargarDatos(
    page: number = this.state.filtros.page, size: number = this.state.filtros.size,
    sort = this.state.filtros.sort, order = this.state.filtros.order,
    filtroEstado = this.state.filtros.filtroEstado, filtroUsuario = this.state.filtros.filtroUsuario
  ) {
    let state = adminReservasReducer(this.state, guardarFiltros(page, size, sort, order, filtroEstado, filtroUsuario));
    state = adminReservasReducer(state, seleccionarReservas([]));
    this.setState(state, () => {
      let url = Endpoints.ADMIN_RESERVAS + `?page=${page}&size=${size}`;
      if (sort) url += `&sort=${sort}&order=${order}`;
      if (this.isFiltraEvento()) url += `&evento=${this.props.match.params.uuid}`;
      if (filtroEstado && filtroEstado.length > 0) url += `&estado=${filtroEstado.join(",")}`;
      if (filtroUsuario) url += `&usuario=${encodeURIComponent(filtroUsuario)}`;
      get(url)
        .then((res: RespuestaDTO) => {
          let state = adminReservasReducer(this.state, guardarDatos(res));
          state = adminReservasReducer(state, cambiarCargando(false));
          this.setState(state);
        })
        .catch(res => {
          notification.error({
            message: 'Error',
            description: res.descripcion
          });
          this.setState(adminReservasReducer(this.state, cambiarCargando(false)));
        });
    });
  }

  private onCancelarDrawer() {
    this.setState(adminReservasReducer(this.state, cambiarDrawerVisible(false)));
  }

  private onAceptarDrawer(e: EventoGrupo) {
    this.setState(adminReservasReducer(this.state, cambiarDrawerVisible(false)), () => {
      this.props.history.push(AppRoutes.ADMIN_RESERVAS_EVENTO.replace(":uuid", e.id));
      this.setState(adminReservasReducer(this.state, cambiarCargando(true)), () => {
        this.cargarDatos();
      });
    });
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return <>
      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys: this.state.seleccionadas.map(r => r.id),
          onChange: (key, record) => {
            this.setState(adminReservasReducer(this.state, seleccionarReservas(record)));
          }
        }}
        loading={this.state.cargando}
        columns={this.getColumnas()}
        dataSource={this.state.datos}
        rowClassName="ant-table-row-clickable"
        size="middle"
        title={() => this.getTituloTabla()}
        pagination={{
          total: this.state.total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} reservas`,
          defaultPageSize: DEFAULT_FILTROS_SIZE,
        }}
        onChange={(pagination, filters, sorter) => {
          let usuario: string | undefined;
          if ('usuario' in filters && filters.usuario !== null) {
            usuario = filters.usuario.toString();
          }

          let estado: string[] = [];
          if ('estado' in filters && filters.estado !== null) {
            estado = filters.estado.map(e => e.toString());
          }

          this.setState(adminReservasReducer(this.state, cambiarCargando()), () => {
            if (Array.isArray(sorter)) sorter = sorter[0];
            this.cargarDatos(
              pagination.current, pagination.pageSize,
              sorter.column?.key as (string | undefined), sorter.order || undefined,
              estado, usuario
            );
          });
        }}
      />

      <DrawerSelectorEvento
        visible={this.state.drawerVisible}
        onEvento={this.onAceptarDrawer} onCerrar={this.onCancelarDrawer}
      />

      <ModalConfirmar
        reserva={this.state.confirmarReserva}
        title={(() => {
          if (this.state.confirmarReserva === null) return "";
          let u = this.state.confirmarReserva.usuario || this.state.confirmarReserva.invitado;
          assert(u !== null);

          return "Confirmar reserva de " + u.nombre;
        })()}
        onConfirmar={(invitados, numero) => this.onConfirmarAsistentes(invitados, numero)}
        onCancelar={() => this.setState(adminReservasReducer(this.state, confirmarReserva()))}
      />

      <ModalReservar
        mostrar={this.state.nueva}
        evento={this.props.match.params.uuid}
        onReservar={reservas => {
          this.setState(adminReservasReducer(adminReservasReducer(
            this.state,
            cambiarCargando(true)),
            nuevaReserva(false)), async () => {
            for (let reserva of reservas) {
              try {
                await post(Endpoints.ADMIN_RESERVAS_CREAR, reserva);
                notification.success({
                  message: "Reserva creada",
                  description: "Se ha creado la reserva del evento " + reserva.evento.nombre
                });
              } catch (e: any) {
                notification.error({
                  message: "Error creando la reserva para " + reserva.evento.nombre,
                  description: e.mensaje
                });
              }
            }

            this.cargarDatos();
          });
        }}
        onCancelar={() => this.setState(adminReservasReducer(this.state, nuevaReserva(false)))}
      />
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha, CONNECTOR)(AdminReservas) as React.ComponentType;
