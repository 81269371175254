import React from "react";
import {FormPerfilCustomProps, FormPerfilProps, FormPerfilState} from "./types";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin
} from "antd";
import {withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {compose} from "redux";
import {Endpoints, get, post} from "utils/http";
import AppRoutes from "routes";
import {getToken, guardarToken} from "utils/token";
import {initialState, formPerfilReducer} from "./reducers";
import {cambiarAvatar, cambiarCargando, cambiarEditable, CONNECTOR, desactivarCampos, editarClave} from "./actions";
import moment from "moment";
import {parseIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js/max";
import {isTienePermiso, PermisoUsuarios} from "utils/permisos";
import App from "components/app";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import {Usuario, UsuarioSimple} from "types/usuarios";

class FormPerfil extends React.Component<FormPerfilProps, FormPerfilState> {
  constructor(props: FormPerfilProps) {
    super(props);
    this.state = initialState;

    this.onPerfil = this.onPerfil.bind(this);
    this.onClave = this.onClave.bind(this);
  }

  private FORM_PERFIL = React.createRef<FormInstance>();
  private FORM_CLAVE = React.createRef<FormInstance>();

  private esEditable() {
    return !this.props.simple && (this.esEditableClave() || isTienePermiso(PermisoUsuarios));
  }

  private esEditableClave(): boolean {
    return !this.props.uuid;
  }

  private onClave() {
    this.FORM_CLAVE.current?.validateFields().then(values => {
      this.setState(formPerfilReducer(this.state, cambiarCargando(true)), () => {
        let token = getToken();
        if (token === null || typeof token === "string" || !('usuario' in token)) {
          this.props.history.push(AppRoutes.ACCESO);
          return;
        }

        let uuid = this.props.uuid;
        if (uuid === undefined || uuid === null) {
          uuid = token.usuario.uuid;
        }

        post(Endpoints.USUARIO_PERFIL_CLAVE.replace(":uuid", uuid), {
          'clave_previa': this.FORM_CLAVE.current?.getFieldValue('clave_previa'),
          'clave': this.FORM_CLAVE.current?.getFieldValue('clave'),
        })
          .then(res => {
            let state = formPerfilReducer(this.state, cambiarCargando(false));
            state = formPerfilReducer(state, editarClave());
            guardarToken(true, res.token);
            this.setState(state);
            notification.success({
              message: 'Se ha guardado la nueva contraseña'
            })
          })
          .catch(res => this.setState(formPerfilReducer(this.state, cambiarCargando(false)), () => {
            notification.error({
              message: 'Error en el guardado de la contraseña',
              description: res.mensaje,
              placement: 'topRight'
            })
          }));
      });
    });
  }

  private onPerfil() {
    let state = formPerfilReducer(this.state, cambiarEditable(false));
    state = formPerfilReducer(state, cambiarCargando(true));
    this.setState(state, () => {
      let form = this.FORM_PERFIL.current;
      let fecha = form?.getFieldValue('fechaNacimiento').format("YYYY-MM-DD");
      let telefono = parsePhoneNumber("+" + form?.getFieldValue("prefijo") + form?.getFieldValue("telefono"));

      let token = getToken();
      if (token === null || typeof token === "string" || !('usuario' in token)) {
        this.props.history.push(AppRoutes.ACCESO);
        return;
      }

      let uuid = this.props.uuid;
      if (uuid === undefined || uuid === null) {
        uuid = token.usuario.uuid;
      }

      post(Endpoints.USUARIO_PERFIL.replace(":uuid", uuid), {
        'nombre': form?.getFieldValue('nombre'),
        'apellidos': form?.getFieldValue('apellidos'),
        'dni': form?.getFieldValue('dni'),
        'email': form?.getFieldValue('email'),
        'fechaNacimiento': fecha,
        'telefono': telefono.formatInternational(),
      })
        .then(res => {
          notification.success({
            message: 'Datos actualizados',
            placement: 'topRight'
          });
          App.renovarToken();
          this.cargarPerfil();
        })
        .catch(res => this.setState(formPerfilReducer(state, cambiarCargando(false)), () => {
          notification.error({
            message: 'Error en el registro',
            description: res.mensaje,
            placement: 'topRight'
          });
        }));
    });
  }

  private onBtnEditar() {
    if (!this.state.editable) {
      this.setState(formPerfilReducer(this.state, cambiarEditable(true)));
    } else {
      let state = formPerfilReducer(this.state, cambiarEditable(false));
      state = formPerfilReducer(state, cambiarCargando(true));
      this.setState(state, () => this.cargarPerfil());
    }
  }

  private cargarPerfil() {
    let token = getToken();
    if (token === null || typeof token === "string" || !('usuario' in token)) {
      this.props.history.push(AppRoutes.ACCESO);
      return;
    }

    let uuid = this.props.uuid;
    if (uuid === undefined || uuid === null) {
      uuid = token.usuario.uuid;
    }

    get(Endpoints.USUARIO_PERFIL.replace(":uuid", uuid))
      .then((res: UsuarioSimple | Usuario) => {
        let newState = formPerfilReducer(this.state, cambiarCargando(false));
        this.FORM_PERFIL.current?.setFieldsValue({
          nombre: res.nombre,
          apellidos: res.apellidos,
        });
        newState = formPerfilReducer(newState, cambiarAvatar(res.avatar));

        if ("dni" in res) {
          this.FORM_PERFIL.current?.setFieldsValue({
            dni: res.dni,
            email: res.email,
            fechaNacimiento: moment.utc(res.fechaNacimiento),
            telefono: parsePhoneNumber(res.telefono).formatNational(),
          });
        } else {
          newState = formPerfilReducer(newState, desactivarCampos());
        }
        this.setState(newState);
      })
      .catch(res => {
        this.setState(formPerfilReducer(this.state, cambiarCargando(false)), () => {
          notification.error({message: 'Error', description: res.mensaje});
          this.props.history.push(AppRoutes.INICIO);
        });
      });
  }

  componentDidMount() {
    this.cargarPerfil();
  }

  render() {
    return <>
      <Spin spinning={this.state.cargando}>
        <Card>
          <Row justify="space-between">
            {this.props.simple ? <></> : <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Row>
                <Col flex="auto" style={{textAlign: "center"}}>
                  <Avatar size={120} src={this.state.avatar}/>
                </Col>
              </Row>
              <Row>
                <Col flex="auto" style={{textAlign: "center"}}>
                  <Button
                    icon={<Icon path={MDI.mdiKey} size={1}/>} disabled={this.state.editable}
                    style={{marginTop: 20}} onClick={e => this.setState(formPerfilReducer(this.state, editarClave()))}
                  >
                    Cambiar clave
                  </Button>
                </Col>
              </Row>
            </Col>}
            <Col
              xs={24} sm={24}
              md={this.props.simple ? 24 : 18} lg={this.props.simple ? 24 : 18} xl={this.props.simple ? 24 : 18}
            >
              <Form
                name="perfil" labelCol={{span: 8}} wrapperCol={{span: 16}}
                size="large" onFinish={this.onPerfil} initialValues={{prefijo: "34"}} ref={this.FORM_PERFIL}
              >
                <Form.Item
                  label="Nombre Completo" name="nombre_completo" style={{marginBottom: 0}} required={true}
                >
                  <Form.Item
                    style={{display: 'inline-block', width: 'calc(35% - 12px)'}} name="nombre"
                    rules={[{required: true, message: 'Introduce tu nombre'}]} hasFeedback={this.state.editable}
                  >
                    <Input placeholder="Nombre" disabled={!this.state.editable}/>
                  </Form.Item>
                  <span style={{display: 'inline-block', width: '24px'}}/>
                  <Form.Item
                    style={{display: 'inline-block', width: 'calc(65% - 12px)'}} name="apellidos"
                    rules={[{required: true, message: 'Introduce los apellidos'}]} hasFeedback={this.state.editable}
                  >
                    <Input placeholder="Apellidos" disabled={!this.state.editable}/>
                  </Form.Item>
                </Form.Item>

                {!this.state.desactivar ? <>
                  {/* TODO: Validar DNI */}
                  <Form.Item
                    label="DNI / NIE / Pasaporte" name="dni"
                    rules={[{required: true, message: 'Introduce tu DNI, NIE o Pasaporte'}]}
                  >
                    <Input placeholder="12346578Z" disabled/>
                  </Form.Item>

                  <Form.Item
                    label="Correo Electrónico" name="email" hasFeedback={this.state.editable}
                    rules={[
                      {required: true, message: 'Introduce tu correo electrónico'},
                      {type: "email", message: 'Introduce un email válido'}
                    ]}
                  >
                    <Input placeholder="Email" disabled={!this.state.editable}/>
                  </Form.Item>

                  <Form.Item
                    label="Fecha de Nacimiento" rules={[{required: true, message: 'Introduce tu fecha de nacimiento'}]}
                    name="fechaNacimiento" hasFeedback={this.state.editable}
                  >
                    <DatePicker
                      style={{width: "100%"}} allowClear={false}
                      disabledDate={current => current && current > moment().endOf('day')}
                      format="DD/MM/YYYY" showToday={false} showTime={false}
                      disabled={!this.state.editable}
                    />
                  </Form.Item>

                  <Form.Item
                    name="telefono" label="Número de Móvil" hasFeedback={this.state.editable}
                    rules={[
                      {
                        validator: (rule: any, value: string) => {
                          let prefijo = this.FORM_PERFIL.current?.getFieldValue("prefijo");
                          try {
                            let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + value));
                            if (!numero || !numero.isValid()) throw new Error('Número no válido');
                            return Promise.resolve();
                          } catch (error) {
                          }
                          return Promise.reject();
                        }, message: 'Introduce un número de móvil válido', required: true
                      }
                    ]}
                  >
                    <Input disabled={!this.state.editable} addonBefore={<Form.Item noStyle name="prefijo">
                      <Select style={{width: 100}} disabled={!this.state.editable}>
                        <Select.Option value="34">+34</Select.Option>
                      </Select>
                    </Form.Item>} style={{width: '100%'}} onChange={e => {
                      let prefijo = this.FORM_PERFIL.current?.getFieldValue("prefijo");
                      try {
                        let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + e.target.value));
                        if (numero) {
                          this.FORM_PERFIL.current?.setFieldsValue({telefono: numero.formatNational()});
                        }
                      } catch (error) {

                      }
                    }}/>
                  </Form.Item>
                </> : <></>}

                {this.esEditable() ? <Row style={{marginTop: 40}}>
                  <Col span={16} offset={8}>
                    <Button onClick={e => this.onBtnEditar()}>
                      {this.state.editable ? "Cancelar" : "Editar"}
                    </Button>
                    <Button style={{margin: '0 16px'}} type="primary" htmlType="submit" disabled={!this.state.editable}>
                      Guardar
                    </Button>
                  </Col>
                </Row> : <></>}
              </Form>
            </Col>
          </Row>
        </Card>
      </Spin>

      <Modal
        title="Cambiar la clave de acceso" visible={this.state.clave}
        onCancel={() => this.setState(formPerfilReducer(this.state, editarClave()))}
        onOk={() => this.onClave()}
      >
        <Spin tip="Cargando..." spinning={this.state.cargando} size="large">
          <Form
            name="login" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={{recordar: true}}
            size="large" ref={this.FORM_CLAVE}
          >
            <Form.Item
              label="Clave Actual" name="clave_previa" hasFeedback
              rules={[{required: true, message: 'Introduce tu contraseña actual'}]}
            >
              <Input.Password/>
            </Form.Item>

            {/* TODO: Validar seguridad de la contraseña */}
            <Form.Item
              label="Nueva Clave" name="clave" hasFeedback
              rules={[{required: true, message: 'Introduce la contraseña que quieras'}]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item
              label="Repetir Clave" name="clave2" hasFeedback
              rules={[
                {required: true, message: 'Confirma tu nueva contraseña'},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('clave') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                  },
                }),
              ]}
            >
              <Input.Password/>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha, CONNECTOR)(FormPerfil) as React.ComponentType<FormPerfilCustomProps>;
