import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {Asistente} from "types/eventos";
import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";

export interface AdminVentasRouteProps {
  uuid?: string;
}

export interface AdminVentasProps extends PropsFromRedux, IWithGoogleReCaptchaProps, RouteComponentProps<AdminVentasRouteProps> {
}

export interface AdminVentasState {
  cargando: boolean;
  datos: Asistente[];
  total: number;
  drawerVisible: boolean;
  lectorVisible: boolean;

  filtros: {
    page: number;
    size: number;
    sort?: string;
    order?: 'ascend' | 'descend';
    filtroEstado?: string[];
    filtroUsuario?: string;
  }

  seleccionados: Asistente[];
  nuevo: boolean;
}

export interface RespuestaDTO {
  total: number;
  asistentes: Asistente[];
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_DATOS = 'GUARDAR_DATOS';
export const CAMBIAR_DRAWER_VISIBLE = 'CAMBIAR_DRAWER_VISIBLE';
export const CAMBIAR_LECTOR_VISIBLE = 'CAMBIAR_LECTOR_VISIBLE';

export const GUARDAR_FILTROS = 'GUARDAR_FILTROS';
export const SELECCIONAR_ASISTENTES = 'SELECCIONAR_ASISTENTES';
export const MOSTRAR_NUEVO = 'MOSTRAR_NUEVO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface GuardarDatosAction {
  type: typeof GUARDAR_DATOS;
  payload: { total: number, asistentes: Asistente[] };
}

interface CambiarLectorVisibleAction {
  type: typeof CAMBIAR_LECTOR_VISIBLE;
  payload: { visible: boolean; };
}

interface CambiarDrawerVisibleAction {
  type: typeof CAMBIAR_DRAWER_VISIBLE;
  payload: { visible: boolean };
}

interface GuardarFiltrosAction {
  type: typeof GUARDAR_FILTROS,
  payload: {
    page: number; size: number;
    sort?: string; order?: 'ascend' | 'descend';
    filtroEstado?: string[]; filtroUsuario?: string;
  };
}

interface SeleccionarAsistentesAction {
  type: typeof SELECCIONAR_ASISTENTES;
  payload: { asistentes: Asistente[]; };
}

interface MostrarNuevoAction {
  type: typeof MOSTRAR_NUEVO;
  payload: { mostrar: boolean };
}


export type AdminVentasActionTypes = CambiarCargandoAction
  | GuardarDatosAction
  | CambiarLectorVisibleAction
  | CambiarDrawerVisibleAction
  | GuardarFiltrosAction
  | SeleccionarAsistentesAction
  | MostrarNuevoAction;
