import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {EventoGrupo} from "types/eventos";

export interface AdminEventosProps extends PropsFromRedux, RouteComponentProps {
}

export interface AdminEventosState {
  cargando: boolean;
  datos: EventoGrupo[];
  total: number;

  nuevo: {
    visible: boolean;
    getEventoGrupo?(): Promise<EventoGrupo>;
  };
}

export interface RespuestaDTO {
  total: number;
  grupos: EventoGrupo[];
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_DATOS = 'GUARDAR_DATOS';

export const MOSTRAR_NUEVO_EVENTO = 'MOSTRAR_NUEVO_EVENTO';
export const GUARDAR_FUNCION_NUEVO_EVENTO = 'GUARDAR_NUEVO_EVENTO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface GuardarDatosAction {
  type: typeof GUARDAR_DATOS;
  payload: { total: number, grupos: EventoGrupo[] };
}

interface MostrarNuevoEventoAction {
  type: typeof MOSTRAR_NUEVO_EVENTO;
  payload: { mostrar?: boolean };
}

interface GuardarFuncionNuevoEventoAction {
  type: typeof GUARDAR_FUNCION_NUEVO_EVENTO;
  payload: { fn: () => Promise<EventoGrupo> };
}


export type AdminEventosActionTypes = CambiarCargandoAction
  | GuardarDatosAction

  | MostrarNuevoEventoAction
  | GuardarFuncionNuevoEventoAction;
