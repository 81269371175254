import React from "react";
import {AdminProps, AdminState} from "./types";
import {withRouter} from "react-router-dom";
import {Button, Card, Col, Row, Space} from "antd";
import {isTienePermiso, Permisos, PermisoSuper} from "utils/permisos";

class Admin extends React.Component<AdminProps, AdminState> {
  render() {
    return <>
      <Row style={{marginBottom: 15}}>
        <Col span={24}>
          <Card title="Tus Permisos" size="small">
            <Space>
              {Permisos.filter(p => isTienePermiso(p)).map(p => <Button
                shape="round" type="primary" danger={p === PermisoSuper} onClick={() => this.props.history.push(p.ruta)}
              >
                {p.nombre}
              </Button>)}
            </Space>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <iframe
            src="https://ayuda.pasoinfo.xyz/admin/permisos/" frameBorder="0" title="Sistema de Permisos"
            style={{overflow: "hidden", width: "100%", height: "100%", minHeight: 600}} height="100%" width="100%"
          />
        </Col>
      </Row>
    </>;
  }
}

export default withRouter(Admin);
