import {Asistente, EventoGrupo} from "types/eventos";
import {PropsFromRedux} from "routes/admin-reservas/actions";
import {RouteComponentProps} from "react-router-dom";

export interface ModalVenderCustomProps {
  mostrar: boolean,
  evento?: string;

  onVender(asistentes: Asistente[]): void;

  onCancelar(): void;
}

export interface ModalVenderProps extends PropsFromRedux, RouteComponentProps, ModalVenderCustomProps {
}

export interface ModalVenderState {
  cargando: boolean;
  grupo: EventoGrupo | null;
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_GRUPO = 'GUARDAR_GRUPO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: {cargando: boolean};
}

interface GuardarGrupoAction {
  type: typeof GUARDAR_GRUPO;
  payload: {grupo?: EventoGrupo};
}

export type ModalVenderActionTypes = CambiarCargandoAction
  | GuardarGrupoAction;
