import {AccesoActionTypes, AccesoState, CAMBIAR_CARGANDO} from "./types";

export const initialState: AccesoState = {
  cargando: false
}

export function accesoReducer(state = initialState, action: AccesoActionTypes): AccesoState {
  let newState: AccesoState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    default:
      return state;
  }
}
