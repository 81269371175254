import React from 'react';
import {Provider} from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import store from 'store';
import {AppProps, AppState} from "./types";
import {Alert, Col, Layout, Row} from "antd";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Inicio from "routes/inicio";
import AppRoutes from "routes";
import Acceso from "routes/acceso";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {borrarToken, getToken, guardarToken, isSesionTemporal, isSesionUsuario} from "utils/token";
import {Endpoints, post} from "utils/http";
import CerrarSesion from "routes/cerrar-sesion";
import AccesoVerificar from "routes/acceso-verificar";
import Perfil from "routes/yo";
import Admin from "routes/admin";
import AdminSuperadminPermisos from "routes/admin-superadmin-permisos";
import {
  isTienePermiso,
  PermisoEventos,
  PermisoMesa,
  PermisoReservas,
  PermisoSuper,
  PermisoVentas
} from "utils/permisos";
import AdminEventos from "routes/admin-eventos";
import Fab from "components/fab";
import AdminEvento from "routes/admin-evento";
import AdminReservas from "routes/admin-reservas";
import AdminVentas from "routes/admin-ventas";
import AdminMesaEnlaces from "routes/admin-mesa-enlaces";
import AdminMesaMesas from "routes/admin-mesa-mesas";
import Eventos from "routes/eventos";
import Evento from "routes/evento";
import Reservas from "routes/reservas";


function AdminRoute({children, permiso, ...rest}: any) {
  return (
    <Route {...rest} render={({location}) => {
      let token = getToken();
      return token !== null && typeof token !== "string" && 'usuario' in token && token.usuario.permisos !== null &&
      (!permiso || isTienePermiso(permiso, token))
        ? children
        : <Redirect to={AppRoutes.INICIO}/>
    }}/>
  )
}


function PrivateRoute({children, ...rest}: any) {
  return (
    <Route {...rest} render={({location}) => {
      return isSesionUsuario()
        ? children
        : <Redirect to={AppRoutes.ACCESO}/>
    }}/>
  )
}


function PublicRoute({children, ...rest}: any) {
  return (
    <Route {...rest} render={({location}) => {
      return getToken() === null || isSesionTemporal()
        ? children
        : <Redirect to={AppRoutes.INICIO}/>
    }}/>
  )
}


export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {};
  }

  public static renovarToken() {
    let token = getToken();
    if (token === null || isSesionTemporal()) return;

    post(Endpoints.ACCESO_RENOVAR)
      .then(res => {
        guardarToken(true, res.token);
      })
      .catch(res => {
        console.log(res.codigo, res.mensaje);
        borrarToken();
        window.location.href = AppRoutes.INICIO;
      })
  }

  componentDidMount() {
    App.renovarToken();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LeEmhUdAAAAAN_X1Qtx6MDoHLM6Ov9VKoTt1eqM" language="es"
            useRecaptchaNet={false} useEnterprise={false}
            scriptProps={{async: false, defer: false, appendTo: 'head', nonce: undefined}}
          >
            <Layout>
              <Navbar/>
              <Layout style={{minHeight: '100vh'}}>
                <Sidebar/>
                <Layout>
                  <Layout.Content className={'container'}>
                    <Row justify="space-between">
                      <Col xs={1} sm={1} md={1} lg={2} xl={2} xxl={2}/> {/* Left gutter for large devices */}
                      <Col xs={22} sm={22} md={22} lg={20} xl={20} xxl={20}>
                        {isSesionTemporal() ? <Alert
                          message="Esta sesión expirará al cambiar o cerrar la pestaña." showIcon
                          style={{marginBottom: 20}}
                        /> : <></>}
                        <Switch>
                          {/* ADMIN */}
                          <AdminRoute exact path={AppRoutes.ADMIN_SUPERADMIN_PERMISOS} permiso={PermisoSuper}>
                            <AdminSuperadminPermisos/>
                          </AdminRoute>
                          <AdminRoute exact path={AppRoutes.ADMIN_SUPERADMIN_REGISTRO} permiso={PermisoSuper}>
                            WIP...
                          </AdminRoute>
                          <AdminRoute path={AppRoutes.ADMIN_SUPERADMIN} permiso={PermisoSuper}>
                            <Redirect to={AppRoutes.ADMIN_SUPERADMIN_PERMISOS}/>
                          </AdminRoute>

                          <AdminRoute exact path={AppRoutes.ADMIN_EVENTO} permiso={PermisoEventos}>
                            <AdminEvento/>
                          </AdminRoute>
                          <AdminRoute path={AppRoutes.ADMIN_EVENTOS} permiso={PermisoEventos}>
                            <AdminEventos/>
                          </AdminRoute>

                          <AdminRoute exact path={AppRoutes.ADMIN_RESERVAS_EVENTO} permiso={PermisoReservas}>
                            <AdminReservas/>
                          </AdminRoute>
                          <AdminRoute exact path={AppRoutes.ADMIN_RESERVAS} permiso={PermisoReservas}>
                            <AdminReservas/>
                          </AdminRoute>

                          <AdminRoute exact path={AppRoutes.ADMIN_VENTAS_EVENTO} permiso={PermisoVentas}>
                            <AdminVentas/>
                          </AdminRoute>
                          <AdminRoute exact path={AppRoutes.ADMIN_VENTAS} permiso={PermisoVentas}>
                            <AdminVentas/>
                          </AdminRoute>

                          <AdminRoute exact path={AppRoutes.ADMIN_MESA_ENLACES} permiso={PermisoMesa}>
                            <AdminMesaEnlaces/>
                          </AdminRoute>
                          <AdminRoute exact path={AppRoutes.ADMIN_MESA_MESAS} permiso={PermisoMesa}>
                            <AdminMesaMesas/>
                          </AdminRoute>
                          <AdminRoute path={AppRoutes.ADMIN_MESA} permiso={PermisoMesa}>
                            <Redirect to={AppRoutes.ADMIN_MESA_ENLACES}/>
                          </AdminRoute>

                          <AdminRoute path={AppRoutes.ADMIN}><Admin/></AdminRoute>

                          {/* USUARIOS */}
                          <PublicRoute exact path={AppRoutes.ACCESO_VERIFICAR}><AccesoVerificar/></PublicRoute>
                          <PublicRoute exact path={AppRoutes.ACCESO}><Acceso/></PublicRoute>
                          <PrivateRoute exact path={AppRoutes.YO}><Perfil/></PrivateRoute>
                          <PrivateRoute exact path={AppRoutes.CERRAR_SESION}><CerrarSesion/></PrivateRoute>

                          {/* PUBLIC */}
                          <Route exact path={AppRoutes.EVENTO}><Evento/></Route>
                          {/* NOTA: Se pone la lista entera de eventos como privada para requerir una cuenta */}
                          <PrivateRoute exact path={AppRoutes.EVENTOS}><Eventos/></PrivateRoute>
                          <Route path={AppRoutes.EVENTOS}><Redirect to={AppRoutes.EVENTOS}/></Route>

                          <Route exact path={AppRoutes.EVENTO_MAL} render={props => <Redirect
                            to={AppRoutes.EVENTO.replace(":uuid", props.match.params.uuid)}
                          />}/>
                          <Route path={AppRoutes.EVENTOS_MAL}><Redirect to={AppRoutes.EVENTOS}/></Route>

                          <PrivateRoute exact path={AppRoutes.RESERVAS}><Reservas/></PrivateRoute>
                          <PrivateRoute path={AppRoutes.EVENTOS}><Redirect to={AppRoutes.RESERVAS}/></PrivateRoute>

                          {/* INICIO */}
                          <Route exact path={AppRoutes.INICIO}><Inicio/></Route>
                          <Redirect to={AppRoutes.INICIO}/>
                        </Switch>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={2} xl={2} xxl={2}/> {/* Right gutter for large devices */}
                    </Row>
                  </Layout.Content>
                  <Layout.Footer style={{textAlign: 'center'}}>
                    Paso Informática © 2021-2022 | Creado
                    por <a href="https://diego.barreiro.xyz" target="_blank" rel="noreferrer">Diego Barreiro</a>
                  </Layout.Footer>
                </Layout>
              </Layout>
              <Fab/>
            </Layout>
          </GoogleReCaptchaProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;
