import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";

interface AccesoVerificarPropsMatchParams {
  token: string;
}

export interface AccesoVerificarProps extends PropsFromRedux, IWithGoogleReCaptchaProps,
  RouteComponentProps<AccesoVerificarPropsMatchParams> {

}

export interface AccesoVerificarState {
  cargando: boolean;
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

export type AccesoVerificarActionTypes = CambiarCargandoAction;
