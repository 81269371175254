import React from "react";
import {AdminMesaEnlacesProps, AdminMesaEnlacesState} from "routes/admin-mesa-enlaces/types";
import {compose} from "redux";
import {cambiarCargando, CONNECTOR, guardarEnlaces,} from "routes/admin-mesa-enlaces/actions";
import {withRouter} from "react-router-dom";
import {adminMesaEnlacesReducer, initialState} from "routes/admin-mesa-enlaces/reducers";
import {Button, Col, notification, Row, Space, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import {Enlace} from "types/misc";
import {EditableCell, EditableRow} from "components/editable-cell";
import {Endpoints, get, post} from "utils/http";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
// import {MenuOutlined} from '@ant-design/icons';
import {SortableContainer as SC, SortableElement,} from 'react-sortable-hoc';

// TODO: Dragging
// const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);
const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainer = SC((props: any) => <tbody {...props} />);

class AdminMesaEnlaces extends React.Component<AdminMesaEnlacesProps, AdminMesaEnlacesState> {
  constructor(props: AdminMesaEnlacesProps) {
    super(props);
    this.state = initialState;

    this.getColumnasEnlace = this.getColumnasEnlace.bind(this);

    this.handleAddEnlaces = this.handleAddEnlaces.bind(this);
    this.handleSaveEnlaces = this.handleSaveEnlaces.bind(this);
    this.handleOrdenEnlaces = this.handleOrdenEnlaces.bind(this);
    this.handleDeleteEnlaces = this.handleDeleteEnlaces.bind(this);

    this.actualizarEnlaces = this.actualizarEnlaces.bind(this);
  }

  private DraggableContainer = (props: any) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={props.onSortEnd}
      {...props}
    />
  );

  private DraggableBodyRow = ({className, style, ...restProps}: any) => {
    const {enlaces} = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = enlaces.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  private getColumnasEnlace(): (ColumnsType<Enlace> & { editable?: boolean }) {
    return [
      /* {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      }, */
      {
        title: '',
        dataIndex: '',
        key: '',
        editable: false,
        fixed: 'right',
        width: 75,
        align: "center",
        render: (_, value, index) => {
          return <Space>
            <Button
              type="default" disabled={index === 0}
              icon={<Icon path={MDI.mdiChevronUp} size={0.6} style={{marginLeft: 4, marginTop: -5}}/>}
              onClick={() => this.handleOrdenEnlaces(value, "ARRIBA")} size="small"
            />
            <Button
              type="default" disabled={index === this.state.enlaces.length - 1}
              icon={<Icon path={MDI.mdiChevronDown} size={0.6} style={{marginLeft: 4, marginTop: -5}}/>}
              onClick={() => this.handleOrdenEnlaces(value, "ABAJO")} size="small"
            />
          </Space>
        }
      },
      {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'nombre',
          title: 'Nombre',
          handleSave: this.handleSaveEnlaces,
        })
      },
      {
        title: 'Enlace',
        dataIndex: 'enlace',
        key: 'enlace',
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'enlace',
          title: 'Enlace',
          handleSave: this.handleSaveEnlaces,
        })
      },
      {
        title: 'Imagen',
        dataIndex: 'imagen',
        key: 'imagen',
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'imagen',
          title: 'Imagen',
          handleSave: this.handleSaveEnlaces,
        }),
        render: (v, value) => !value.imagen ? "" : value.imagen
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        editable: false,
        fixed: 'right',
        width: 75,
        align: "center",
        render: (_, value) => {
          return <Space>
            <Tooltip title={value.visible ? "Ocultar" : "Mostrar"}>
              <Button
                type={value.visible ? "primary" : "default"}
                icon={<Icon
                  path={value.visible ? MDI.mdiEye : MDI.mdiEyeOff} size={0.6} style={{marginLeft: 4, marginTop: -5}}
                />}
                onClick={() => this.handleSaveEnlaces({...value, visible: !value.visible})} size="small"
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                danger icon={<Icon path={MDI.mdiTrashCan} size={0.6} style={{marginLeft: 4, marginTop: -5}}/>}
                onClick={() => this.handleDeleteEnlaces(value)} size="small"
              />
            </Tooltip>
          </Space>
        }
      }
    ]
  }

  private handleAddEnlaces() {
    this.setState(adminMesaEnlacesReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_ENLACE.replace(":id", "0"))
        .then(() => this.actualizarEnlaces());
    });
  }

  private handleSaveEnlaces(enlace: Enlace) {
    this.setState(adminMesaEnlacesReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_ENLACE.replace(":id", enlace.id.toString()), enlace)
        .then(() => this.actualizarEnlaces());
    });
  }

  private handleOrdenEnlaces(enlace: Enlace, movimiento: "ARRIBA" | "ABAJO") {
    this.setState(adminMesaEnlacesReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_ENLACE_ORDEN.replace(":id", enlace.id.toString()), {
        "enlace": enlace,
        "movimiento": movimiento
      })
        .then(() => this.actualizarEnlaces());
    });
  }

  private handleDeleteEnlaces(enlace: Enlace) {
    this.setState(adminMesaEnlacesReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_ENLACE_ELIMINAR.replace(":id", enlace.id.toString()))
        .then(() => this.actualizarEnlaces());
    });
  }

  private actualizarEnlaces() {
    get(Endpoints.ADMIN_MESA_ENLACES)
      .then((res: Enlace[]) => {
        let state = adminMesaEnlacesReducer(this.state, guardarEnlaces(res));
        this.setState(adminMesaEnlacesReducer(state, cambiarCargando(false)));
      })
  }

  componentDidMount() {
    get(Endpoints.ADMIN_MESA_ENLACES)
      .then(enlaces => {
        let state = adminMesaEnlacesReducer(this.state, guardarEnlaces(enlaces));
        this.setState(adminMesaEnlacesReducer(state, cambiarCargando(false)));
      })
      .catch(e => {
        notification.error({message: 'Error', description: 'Error descargando los datos'});
      });
  }

  render() {
    return <Row justify="space-between">
      <Col span={24} style={{marginBottom: 10}}>
        <Table
          key='id'
          loading={this.state.cargando}
          columns={this.getColumnasEnlace()}
          size="middle"
          rowClassName={() => 'editable-row'}

          components={{
            body: {
              // wrapper: this.DraggableContainer,
              row: EditableRow,
              cell: EditableCell
            }
          }}
          dataSource={this.state.enlaces}
          scroll={{x: 1000}}
          pagination={false}
          footer={() => <Button
            icon={<Icon path={MDI.mdiPlus}/>}
            onClick={() => this.handleAddEnlaces()}
          >
            Nuevo Enlace
          </Button>}
        />
      </Col>
    </Row>;
  }
}

export default compose(CONNECTOR, withRouter)(AdminMesaEnlaces) as React.ComponentType;
