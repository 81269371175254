import AppRoutes from "routes";
import {getToken} from "./token";

interface Permiso {
  permiso: string;
  nombre: string;
  ruta: string;

  submenu?: {
    nombre: string;
    ruta: string;
  }[];
}

export const PermisoSuper: Permiso = {
  permiso: 'super', nombre: 'Superadmin', ruta: AppRoutes.ADMIN_SUPERADMIN, submenu: [
    {nombre: "Permisos", ruta: AppRoutes.ADMIN_SUPERADMIN_PERMISOS},
    {nombre: "Registro", ruta: AppRoutes.ADMIN_SUPERADMIN_REGISTRO},
  ]
};
export const PermisoEventos: Permiso = {permiso: 'eventos', nombre: 'Eventos', ruta: AppRoutes.ADMIN_EVENTOS};
export const PermisoReservas: Permiso = {permiso: 'reservas', nombre: 'Reservas', ruta: AppRoutes.ADMIN_RESERVAS};
export const PermisoVentas: Permiso = {permiso: 'ventas', nombre: 'Ventas', ruta: AppRoutes.ADMIN_VENTAS};
export const PermisoMesa: Permiso = {
  permiso: 'mesa', nombre: 'Mesa', ruta: AppRoutes.ADMIN_MESA, submenu: [
    {nombre: "Enlaces", ruta: AppRoutes.ADMIN_MESA_ENLACES},
    {nombre: "Mesas", ruta: AppRoutes.ADMIN_MESA_MESAS},
  ]
};
export const PermisoUsuarios: Permiso = {permiso: 'usuarios', nombre: 'Usuarios', ruta: AppRoutes.ADMIN_USUARIOS};

export const Permisos: Permiso[] = [
  PermisoSuper, PermisoEventos, PermisoReservas, PermisoVentas, PermisoMesa, PermisoUsuarios
]

export const isTienePermiso = (permiso: Permiso, token = getToken()) => {
  if (token === null || typeof token === "string" || !('usuario' in token) || !token.usuario.permisos) return false;
  return token.usuario.permisos.find((p: string) => p === permiso.permiso) !== undefined;
};
