import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  AdminVentasActionTypes,
  CAMBIAR_CARGANDO,
  CAMBIAR_DRAWER_VISIBLE,
  CAMBIAR_LECTOR_VISIBLE,
  GUARDAR_DATOS,
  GUARDAR_FILTROS,
  MOSTRAR_NUEVO,
  RespuestaDTO,
  SELECCIONAR_ASISTENTES
} from "routes/admin-ventas/types";
import {Asistente} from "types/eventos";


export function cambiarCargando(cargando?: boolean): AdminVentasActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarDatos(respuesta: RespuestaDTO): AdminVentasActionTypes {
  return {
    type: GUARDAR_DATOS,
    payload: {...respuesta}
  }
}

export function cambiarDrawerVisible(visible: boolean): AdminVentasActionTypes {
  return {
    type: CAMBIAR_DRAWER_VISIBLE,
    payload: {visible}
  }
}

export function cambiarLectorVisible(visible: boolean): AdminVentasActionTypes {
  return {
    type: CAMBIAR_LECTOR_VISIBLE,
    payload: {visible}
  }
}

export function guardarFiltros(
  page: number, size: number,
  sort?: string, order?: 'ascend' | 'descend',
  filtroEstado?: string[], filtroUsuario?: string
): AdminVentasActionTypes {
  return {
    type: GUARDAR_FILTROS,
    payload: {page, size, sort, order, filtroEstado, filtroUsuario}
  }
}

export function seleccionarAsistentes(asistentes: Asistente[]): AdminVentasActionTypes {
  return {
    type: SELECCIONAR_ASISTENTES,
    payload: {asistentes}
  }
}

export function mostrarNuevo(mostrar: boolean): AdminVentasActionTypes {
  return {
    type: MOSTRAR_NUEVO,
    payload: {mostrar}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.adminVentas.cargando,
  datos: state.adminVentas.datos,
  total: state.adminVentas.total,
  drawerVisible: state.adminVentas.drawerVisible,
  lectorVisible: state.adminVentas.lectorVisible,
  
  filtros: {
    page: state.adminVentas.filtros.page,
    size: state.adminVentas.filtros.size,
    sort: state.adminVentas.filtros.sort,
    order: state.adminVentas.filtros.order,
    filtroEstado: state.adminVentas.filtros.filtroEstado,
    filtroUsuario: state.adminVentas.filtros.filtroUsuario,
  },
  seleccionados: state.adminVentas.seleccionados,
  nuevo: state.adminVentas.nuevo,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarDatos: guardarDatos,
  cambiarDrawerVisible: cambiarDrawerVisible,
  cambiarLectorVisible: cambiarLectorVisible,
  guardarFiltros: guardarFiltros,
  seleccionarAsistentes: seleccionarAsistentes,
  mostrarNuevo: mostrarNuevo,
};

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
