enum AppRoutes {
    INICIO = "/",

    EVENTOS = "/eventos",
    EVENTO = "/eventos/:uuid",
    EVENTOS_MAL = "/evento",
    EVENTO_MAL = "/evento/:uuid",

    RESERVAS = "/reservas",

    ACCESO = "/acceso",
    ACCESO_VERIFICAR = "/acceso/:token",
    YO = "/yo",
    CERRAR_SESION = "/adios",

    ADMIN = "/admin",

    ADMIN_SUPERADMIN = "/admin/superadmin",
    ADMIN_SUPERADMIN_PERMISOS = "/admin/superadmin/permisos",
    ADMIN_SUPERADMIN_REGISTRO = "/admin/superadmin/registro",

    ADMIN_EVENTOS = "/admin/eventos",
    ADMIN_EVENTO = "/admin/eventos/:uuid",

    ADMIN_RESERVAS = "/admin/reservas",
    ADMIN_RESERVAS_EVENTO = "/admin/reservas/:uuid",

    ADMIN_VENTAS = "/admin/ventas",
    ADMIN_VENTAS_EVENTO = "/admin/ventas/:uuid",

    ADMIN_MESA = "/admin/mesa",
    ADMIN_MESA_ENLACES = "/admin/mesa/enlaces",
    ADMIN_MESA_MESAS = "/admin/mesa/mesas",

    ADMIN_USUARIOS = "/admin/usuarios",
}

export enum AppRoutesMenu {
    INICIO = "000",
    EVENTOS = "010",
    RESERVAS = "020",

    ACCESO = "100",
    YO = "110",
    CERRAR_SESION = "120",

    ADMIN = "200",
    ADMIN_SUPERADMIN = "210",
    ADMIN_SUPERADMIN_PERMISOS = "211",
    ADMIN_SUPERADMIN_REGISTRO = "212",
    ADMIN_EVENTOS = "220",
    ADMIN_RESERVAS = "230",
    ADMIN_VENTAS = "240",
    ADMIN_MESA = "250",
    ADMIN_MESA_ENLACES = "251",
    ADMIN_MESA_MESAS = "252",
    ADMIN_USUARIOS = "260",
}

export const mapKeysToRoutes = (key: string): string => {
    switch (key) {
        case AppRoutesMenu.INICIO:
            return AppRoutes.INICIO;
        case AppRoutesMenu.EVENTOS:
            return AppRoutes.EVENTOS;
        case AppRoutesMenu.RESERVAS:
            return AppRoutes.RESERVAS;
        case AppRoutesMenu.ACCESO:
            return AppRoutes.ACCESO;
        case AppRoutesMenu.YO:
            return AppRoutes.YO;
        case AppRoutesMenu.CERRAR_SESION:
            return AppRoutes.CERRAR_SESION;
        case AppRoutesMenu.ADMIN:
            return AppRoutes.ADMIN;
        case AppRoutesMenu.ADMIN_SUPERADMIN:
            return AppRoutes.ADMIN_SUPERADMIN;
        case AppRoutesMenu.ADMIN_SUPERADMIN_PERMISOS:
            return AppRoutes.ADMIN_SUPERADMIN_PERMISOS;
        case AppRoutesMenu.ADMIN_SUPERADMIN_REGISTRO:
            return AppRoutes.ADMIN_SUPERADMIN_REGISTRO;
        case AppRoutesMenu.ADMIN_EVENTOS:
            return AppRoutes.ADMIN_EVENTOS;
        case AppRoutesMenu.ADMIN_RESERVAS:
            return AppRoutes.ADMIN_RESERVAS;
        case AppRoutesMenu.ADMIN_VENTAS:
            return AppRoutes.ADMIN_VENTAS;
        case AppRoutesMenu.ADMIN_MESA:
            return AppRoutes.ADMIN_MESA;
        case AppRoutesMenu.ADMIN_MESA_ENLACES:
            return AppRoutes.ADMIN_MESA_ENLACES;
        case AppRoutesMenu.ADMIN_MESA_MESAS:
            return AppRoutes.ADMIN_MESA_MESAS;
        case AppRoutesMenu.ADMIN_USUARIOS:
            return AppRoutes.ADMIN_USUARIOS;
        default:
            return AppRoutes.INICIO;
    }
}

export const mapRoutesToKeys = (route: string): string => {
    switch (true) {
        case route.startsWith(AppRoutes.EVENTOS):
            return AppRoutesMenu.EVENTOS;
        case route.startsWith(AppRoutes.RESERVAS):
            return AppRoutesMenu.RESERVAS;
        case route.startsWith(AppRoutes.ACCESO):
            return AppRoutesMenu.ACCESO;
        case route.startsWith(AppRoutes.YO):
            return AppRoutesMenu.YO
        case route.startsWith(AppRoutes.CERRAR_SESION):
            return AppRoutesMenu.CERRAR_SESION;
        case route.startsWith(AppRoutes.ADMIN_SUPERADMIN_PERMISOS):
            return AppRoutesMenu.ADMIN_SUPERADMIN_PERMISOS;
        case route.startsWith(AppRoutes.ADMIN_SUPERADMIN_REGISTRO):
            return AppRoutesMenu.ADMIN_SUPERADMIN_REGISTRO;
        case route.startsWith(AppRoutes.ADMIN_SUPERADMIN):
            return AppRoutesMenu.ADMIN_SUPERADMIN;
        case route.startsWith(AppRoutes.ADMIN_EVENTOS):
            return AppRoutesMenu.ADMIN_EVENTOS;
        case route.startsWith(AppRoutes.ADMIN_RESERVAS):
            return AppRoutesMenu.ADMIN_RESERVAS;
        case route.startsWith(AppRoutes.ADMIN_VENTAS):
            return AppRoutesMenu.ADMIN_VENTAS;
        case route.startsWith(AppRoutes.ADMIN_MESA_ENLACES):
            return AppRoutesMenu.ADMIN_MESA_ENLACES;
        case route.startsWith(AppRoutes.ADMIN_MESA_MESAS):
            return AppRoutesMenu.ADMIN_MESA_MESAS;
        case route.startsWith(AppRoutes.ADMIN_MESA):
            return AppRoutesMenu.ADMIN_MESA;
        case route.startsWith(AppRoutes.ADMIN_USUARIOS):
            return AppRoutesMenu.ADMIN_USUARIOS;
        case route.startsWith(AppRoutes.ADMIN):
            return AppRoutesMenu.ADMIN;
        case route.startsWith(AppRoutes.INICIO):
        default:
            return AppRoutesMenu.INICIO;
    }
}

export default AppRoutes;
