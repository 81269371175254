import {getToken} from "./token";

const BASE_URL = process.env.REACT_APP_PASO_BACKEND || "http://localhost:8000";

export function get(url: Endpoints | string) {
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  }
  return solicitar(url, options);
}

export function post(url: Endpoints | string, body: any = {}) {
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  }
  return solicitar(url, options);
}


function solicitar(url: Endpoints | string, options: any): Promise<RespuestaError | any> {
  return new Promise<RespuestaError | any>((resolve, reject) => {
    let token = getToken(true);
    if (token !== null) {
      options = {
        ...options,
        'headers': {...options.headers, 'Authorization': 'Bearer ' + token}
      }
    }

    fetch(BASE_URL + url, options)
      .then(res => res.json())
      .then((res: Respuesta) => {
        if (res.correcto) {
          resolve(res.datos as any);
        } else {
          reject(res.datos as RespuestaError)
        }
      })
      .catch(e => {
        reject({codigo: 0, mensaje: e.message} as RespuestaError)
      });
  });
}

export enum Endpoints {
  ACCESO_LOGIN = "/usuarios/acceso",
  ACCESO_RENOVAR = "/usuarios/renovar",
  ACCESO_REGISTRO = "/usuarios/registro",
  ACCESO_VERIFICAR = "/usuarios/verificar",
  ACCESO_CLAVE = "/usuarios/clave",

  USUARIO_PERFIL = "/usuarios/perfil/:uuid",
  USUARIO_PERFIL_CLAVE = "/usuarios/perfil/:uuid/clave",
  USUARIO_BUSQUEDA = "/usuarios/busqueda/:dni",
  USUARIO_INVITADO = "/usuarios/invitado",


  EVENTOS = "/eventos",
  EVENTOS_INICIO = "/eventos/inicio",
  EVENTO = "/eventos/:uuid",
  EVENTO_ABIERTO = "/eventos/:uuid/abierto",
  EVENTO_RESERVA = "/eventos/:uuid/reserva",
  EVENTO_RESERVAR = "/eventos/:uuid/reservar",
  EVENTO_CONFIRMAR = "/eventos/:uuid/confirmar",
  EVENTO_PIN = "/eventos/:uuid/pin",
  EVENTO_CANCELAR = "/eventos/:uuid/cancelar",

  RESERVAS = "/reservas",
  RESERVAS_INICIO = "/reservas/inicio",


  MISC_ENLACES = "/enlaces",
  MISC_MESAS = "/mesas",


  ADMIN_PERMISOS = "/admin/permisos",

  ADMIN_EVENTOS = "/admin/eventos",
  ADMIN_EVENTOS_BUSCAR = "/admin/eventos/buscar",
  ADMIN_EVENTO = "/admin/eventos/:uuid",
  ADMIN_EVENTO_VOLCAR = "/admin/eventos/:uuid/volcar",
  ADMIN_EVENTO_ASIGNAR = "/admin/eventos/:uuid/asignar",
  ADMIN_EVENTO_SPREADSHEET = "/admin/eventos/:uuid/:id",

  ADMIN_RESERVAS = "/admin/reservas",
  ADMIN_RESERVAS_CREAR = "/admin/reservas/crear",
  ADMIN_RESERVAS_NOTAS = "/admin/reservas/notas",
  ADMIN_RESERVAS_ASIGNAR = "/admin/reservas/asignar",
  ADMIN_RESERVAS_CONFIRMAR = "/admin/reservas/confirmar",
  ADMIN_RESERVAS_CANCELAR = "/admin/reservas/cancelar",
  ADMIN_RESERVAS_NOTIFICAR = "/admin/reservas/notificar",

  ADMIN_VENTAS = "/admin/ventas",
  ADMIN_VENTAS_CREAR = "/admin/ventas/crear",
  ADMIN_VENTAS_NOTAS = "/admin/ventas/notas",
  ADMIN_VENTAS_PAGAR = "/admin/ventas/pagar",
  ADMIN_VENTAS_ENTREGAR = "/admin/ventas/entregar",
  ADMIN_VENTAS_CANCELAR = "/admin/ventas/cancelar",
  ADMIN_VENTAS_NOTIFICAR = "/admin/ventas/notificar",

  ADMIN_MESA_ENLACES = "/admin/mesa/enlaces",
  ADMIN_MESA_ENLACE = "/admin/mesa/enlaces/:id",
  ADMIN_MESA_ENLACE_ORDEN = "/admin/mesa/enlaces/:id/orden",
  ADMIN_MESA_ENLACE_ELIMINAR = "/admin/mesa/enlaces/:id/eliminar",
  ADMIN_MESA_MESAS = "/admin/mesa/mesas",
  ADMIN_MESA_MESA = "/admin/mesa/mesas/:id",
  ADMIN_MESA_MESA_ELIMINAR = "/admin/mesa/mesas/:id/eliminar",
}

interface Respuesta {
  correcto: boolean;
  datos: RespuestaError | any;
}

interface RespuestaError {
  codigo: number;
  mensaje: string;
}
