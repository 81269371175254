import React from "react";
import {ModalConfirmarCustomProps, ModalConfirmarProps, ModalConfirmarState} from "components/modal-confirmar/types";
import {initialState, modalConfirmarReducer} from "components/modal-confirmar/reducers";
import {cambiarEntradasNominativas, cambiarNumeroAsistentes, CONNECTOR} from "components/modal-confirmar/actions";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {Col, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Switch, Tabs} from "antd";
import {parseIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js/max";
import assert from "assert";
import {Invitado} from "types/usuarios";

class ModalConfirmar extends React.Component<ModalConfirmarProps, ModalConfirmarState> {
  constructor(props: ModalConfirmarProps) {
    super(props);
    this.state = initialState;

    this.cubrirCampos = this.cubrirCampos.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  private FORM_REF = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: Readonly<ModalConfirmarProps>, prevState: Readonly<ModalConfirmarState>, snapshot?: any) {
    if (this.props.reserva && prevProps.reserva?.id !== this.props.reserva?.id) {
      let state = modalConfirmarReducer(this.state, cambiarNumeroAsistentes(this.props.reserva.numEntradas));
      state = modalConfirmarReducer(state, cambiarEntradasNominativas(this.props.reserva.evento.requiereNominativas));
      this.setState(state, () => this.cubrirCampos());
    }
  }

  private cubrirCampos() {
    let form = this.FORM_REF.current;
    assert(form !== null);

    let u = this.props.reserva?.usuario || this.props.reserva?.invitado;
    assert(u !== null);

    let fields: any = {};
    Array.from({length: this.state.numAsistentes}, (v, k) => k + 1).forEach(i => {
      fields['iPrefijo' + i] = "34";
      if (i === 1) {
        fields['iNombre' + i] = u?.nombre;
        fields['iApellidos' + i] = u?.apellidos;
        fields['iDni' + i] = u?.dni;
        fields['iEmail' + i] = u?.email;
        fields['iTelefono' + i] = parsePhoneNumber(parseIncompletePhoneNumber(u?.telefono || "")).formatNational();
      }
    });

    form.setFieldsValue(fields);
  }

  private onFinish() {
    this.FORM_REF.current?.validateFields()
      .then(res => {
        let invitados: Invitado[] = [];
        let numero = 0;

        if (this.state.nominativas) {
          invitados = Array.from({length: this.state.numAsistentes}, (v, k) => k + 1).map(i => {
            let form = this.FORM_REF.current;
            assert(form !== null);

            let invitado: Invitado = {
              pin: "",
              nombre: form.getFieldValue('iNombre' + i),
              apellidos: form.getFieldValue('iApellidos' + i),
              dni: form.getFieldValue('iDni' + i),
              email: form.getFieldValue('iEmail' + i),
              telefono: parsePhoneNumber(
                "+" + form.getFieldValue("iPrefijo" + i) + form.getFieldValue("iTelefono" + i)
              ).formatInternational(),
            };
            return invitado;
          });
        } else {
          numero = this.FORM_REF.current?.getFieldValue('numAsistentes');
        }

        this.props.onConfirmar(invitados, invitados.length === 0 ? numero : undefined);
      })
      .catch(res => {
        message.error("Faltan campos en el Asistente " + res.errorFields[0].name[0].replace(/\D/g,''));
      })
  }

  render() {
    return <Modal
      title={this.props.title || "Confirmar entradas"}
      visible={this.props.reserva !== null}
      onOk={() => this.onFinish()}
      onCancel={() => this.props.onCancelar()}
      width={1000}
    >
      <Form
        name="asistentes" ref={this.FORM_REF} labelCol={{span: 6}} wrapperCol={{span: 18}}
        onFinish={() => this.onFinish()}
      >
        <Form.Item
          label="Especificar asistentes" valuePropName="checked" labelCol={{span: 'auto'}} wrapperCol={{span: 'auto'}}
          tooltip="¿Quieres indicar los asistentes de cada una de las entradas?"
        >
          <Switch
            disabled={this.props.reserva?.evento.requiereNominativas}
            checked={this.state.nominativas}
            onChange={value => {
              if (this.props.reserva?.evento.requiereNominativas === false)
                this.setState(modalConfirmarReducer(this.state, cambiarEntradasNominativas(value)), () => {
                  if (this.state.nominativas) this.cubrirCampos();
                  else {
                    let txt: string;
                    if (this.props.reserva?.numEntradas === 1) {
                      txt = "La entrada se te asignará a tí, pero la podrás dar a quien quieras";
                    } else {
                      txt = "Las " + this.props.reserva?.numEntradas + " entradas se te asignarán a ti, pero las podrás repartir a quien quieras";
                    }
                    message.warning(txt);
                  }
                });
            }}
          />
        </Form.Item>

        {this.state.nominativas ? <Tabs
          type="editable-card"
          hideAdd={this.state.numAsistentes >= (this.props.reserva?.numEntradas || 0)}
          onEdit={(target, action) => {
            if (action === 'add') {
              if (this.state.numAsistentes < (this.props.reserva?.numEntradas || 0))
                this.setState(modalConfirmarReducer(this.state, cambiarNumeroAsistentes(this.state.numAsistentes + 1)));
            }
            if (action === 'remove') {
              if (this.state.numAsistentes > 1)
                this.setState(modalConfirmarReducer(this.state, cambiarNumeroAsistentes(this.state.numAsistentes - 1)));
            }
          }}
        >
          {Array.from({length: this.state.numAsistentes}, (v, k) => k + 1).map(i => <Tabs.TabPane
            tab={"Asistente " + i} key={i} closable={i > 1 && i === this.state.numAsistentes} forceRender
          >
            <Row>
              <Col xs={0} sm={0} md={0} lg={2} xl={2}/>
              <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                <Form.Item
                  label="Nombre Completo" name={"iNombreCompleto" + i} style={{marginBottom: 0}} required={true}
                >
                  <Form.Item
                    style={{display: 'inline-block', width: 'calc(35% - 12px)'}} name={"iNombre" + i}
                    rules={[{required: true, message: 'Introduce el nombre'}]} hasFeedback
                  >
                    <Input placeholder="Nombre" disabled={i === 1}/>
                  </Form.Item>
                  <span style={{display: 'inline-block', width: '24px'}}/>
                  <Form.Item
                    style={{display: 'inline-block', width: 'calc(65% - 12px)'}} name={"iApellidos" + i}
                    rules={[{required: true, message: 'Introduce los apellidos'}]} hasFeedback
                  >
                    <Input placeholder="Apellidos" disabled={i === 1}/>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="DNI / NIE / Pasaporte" name={"iDni" + i}
                  rules={[{required: true, message: 'Introduce el DNI, NIE o Pasaporte'}]}
                >
                  <Input placeholder="12346578Z" disabled={i === 1}/>
                </Form.Item>

                <Form.Item
                  label="Correo Electrónico" name={"iEmail" + i} hasFeedback
                  rules={[
                    {required: true, message: 'Introduce tu correo electrónico'},
                    {type: "email", message: 'Introduce un email válido'}
                  ]}
                >
                  <Input placeholder="Email" disabled={i === 1}/>
                </Form.Item>

                <Form.Item
                  name={"iTelefono" + i} label="Número de Móvil" hasFeedback
                  rules={[
                    {
                      validator: (rule: any, value: string) => {
                        let prefijo = this.FORM_REF.current?.getFieldValue("iPrefijo" + i);
                        try {
                          let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + value));
                          if (!numero || !numero.isValid()) throw new Error('Número no válido');
                          return Promise.resolve();
                        } catch (error) {
                        }
                        return Promise.reject();
                      }, message: 'Introduce un número de móvil válido', required: true
                    }
                  ]}
                >
                  <Input disabled={i === 1} addonBefore={<Form.Item noStyle name={"iPrefijo" + i}>
                    <Select style={{width: 100}} disabled={i === 1}>
                      <Select.Option value="34">+34</Select.Option>
                    </Select>
                  </Form.Item>} style={{width: '100%'}} onChange={e => {
                    let prefijo = this.FORM_REF.current?.getFieldValue("iPrefijo" + i);
                    try {
                      let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + e.target.value));
                      if (numero) {
                        let valores: any = {};
                        valores['iTelefono' + i] = numero.formatNational();
                        this.FORM_REF.current?.setFieldsValue(valores);
                      }
                    } catch (error) {
                    }
                  }}/>
                </Form.Item>
              </Col>
              <Col xs={0} sm={0} md={0} lg={2} xl={2}/>
            </Row>
          </Tabs.TabPane>)}
        </Tabs> : <Form.Item name="numAsistentes" label="Número de Asistentes">
          <InputNumber min={1} max={this.props.reserva?.numEntradas} defaultValue={this.props.reserva?.numEntradas}/>
        </Form.Item>}
      </Form>
    </Modal>;
  }
}

export default compose(withRouter, CONNECTOR)(ModalConfirmar) as React.ComponentType<ModalConfirmarCustomProps>;
