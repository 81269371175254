import {
  AdminEventosActionTypes,
  AdminEventosState,
  CAMBIAR_CARGANDO,
  GUARDAR_DATOS,
  GUARDAR_FUNCION_NUEVO_EVENTO,
  MOSTRAR_NUEVO_EVENTO
} from "./types";
import {parsearFechasEventoGrupo} from "components/form-evento-admin/utils";

export const initialState: AdminEventosState = {
  cargando: true,
  datos: [],
  total: 0,

  nuevo: {
    visible: false,
    getEventoGrupo: undefined
  }
}

export function adminEventosReducer(state = initialState, action: AdminEventosActionTypes): AdminEventosState {
  let newState: AdminEventosState = JSON.parse(JSON.stringify(state));
  newState.datos = parsearFechasEventoGrupo(newState.datos);
  newState.nuevo.getEventoGrupo = state.nuevo.getEventoGrupo;

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case GUARDAR_DATOS:
      newState.total = action.payload.total;
      newState.datos = parsearFechasEventoGrupo(action.payload.grupos);
      return newState;

    case MOSTRAR_NUEVO_EVENTO:
      newState.nuevo.visible = action.payload.mostrar || !state.nuevo;
      return newState;

    case GUARDAR_FUNCION_NUEVO_EVENTO:
      if (newState.nuevo.getEventoGrupo === undefined) newState.nuevo.getEventoGrupo = action.payload.fn;
      return newState;

    default:
      return state;
  }
}
