import {RouteComponentProps} from "react-router-dom";
import {Enlace} from "types/misc";

export interface AdminMesaEnlacesProps extends RouteComponentProps {
}

export interface AdminMesaEnlacesState {
  cargando: boolean;
  enlaces: Enlace[];
}

export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_ENLACES = 'GUARDAR_ENLACES';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando: boolean };
}

interface GuardarEnlacesAction {
  type: typeof GUARDAR_ENLACES;
  payload: { enlaces: Enlace[] };
}

export type AdminMesaEnlacesActionTypes = CambiarCargandoAction
  | GuardarEnlacesAction;
