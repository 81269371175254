import {
  CAMBIAR_CARGANDO,
  CARGAR_EVENTO_GRUPO,
  COMPROBAR_INSCRIPCIONES_ABIERTAS,
  EVENTO_MODALS,
  EventoActionTypes,
  GUARDAR_RESERVA,
  MOSTRAR_MODAL
} from "routes/evento/types";
import {Asistente, EventoGrupo, Reserva} from "types/eventos";
import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";

export function cambiarCargando(cargando: boolean): EventoActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function cargarGrupo(grupo: EventoGrupo): EventoActionTypes {
  return {
    type: CARGAR_EVENTO_GRUPO,
    payload: {grupo},
  }
}

export function comprobarInscripcionesAbiertas(abierto: boolean): EventoActionTypes {
  return {
    type: COMPROBAR_INSCRIPCIONES_ABIERTAS,
    payload: {abierto}
  }
}

export function guardarReserva(reserva: Reserva[], asistentes: Asistente[]): EventoActionTypes{
  return {
    type: GUARDAR_RESERVA,
    payload: {reserva, asistentes}
  }
}

export function mostrarModal(modal: EVENTO_MODALS, visible: boolean | Reserva | null): EventoActionTypes {
  return {
    type: MOSTRAR_MODAL,
    payload: {modal, visible}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.evento.cargando,
  grupo: state.evento.grupo,
  abierto: state.evento.abierto,

  reserva: state.evento.reserva,
  asistentes: state.evento.asistentes,

  modal: {
    reservar: state.evento.modal.reservar,
    reserva: state.evento.modal.reserva,
    confirmar: state.evento.modal.confirmar,
    pin: state.evento.modal.evento,
  }
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  cargarGrupo: cargarGrupo,
  comprobarInscripcionesAbiertas: comprobarInscripcionesAbiertas,
  guardarReserva: guardarReserva,
  mostrarModal: mostrarModal,
};

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
