import React from "react";
import {FormLoginCustomProps, FormLoginProps, FormLoginState} from "./types";
import {Button, Checkbox, Form, FormInstance, Input, notification} from "antd";
import {withRouter} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {compose} from "redux";
import {Endpoints, post} from "utils/http";
import {getNombre, guardarToken} from "utils/token";
import AppRoutes from "routes";

class AccesoLogin extends React.Component<FormLoginProps, FormLoginState> {
  constructor(props: FormLoginProps) {
    super(props);

    this.onIniciarSesion = this.onIniciarSesion.bind(this);
  }

  private FORM_LOGIN = React.createRef<FormInstance>();

  private onIniciarSesion() {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      return;
    }

    this.props.cambiarCargando(true).then(() => {
      executeRecaptcha('acceso_login').then(token => {
        post(Endpoints.ACCESO_LOGIN, {
          'dni': this.FORM_LOGIN.current?.getFieldValue('dni'),
          'clave': this.FORM_LOGIN.current?.getFieldValue('clave'),
          'recordar': this.FORM_LOGIN.current?.getFieldValue('recordar'),
          'recaptcha': token,
        })
          .then(res => this.props.cambiarCargando(false).then(() => {
            guardarToken(this.FORM_LOGIN.current?.getFieldValue('recordar'), res.token);
            notification.success({
              message: 'Bienvenido, ' + getNombre(),
              placement: 'topRight'
            });
            window.location.href = AppRoutes.YO;
          }))
          .catch(res => this.props.cambiarCargando(false).then(() => {
            notification.error({
              message: 'Error en el acceso',
              description: res.mensaje,
              placement: 'topRight'
            })
          }));
      });
    });
  }

  render() {
    return <>
      <Form
        name="login" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={{recordar: true}}
        size="large" onFinish={this.onIniciarSesion} ref={this.FORM_LOGIN}
      >
        <Form.Item
          label="DNI / NIE / Pasaporte" name="dni"
          rules={[{required: true, message: 'Introduce tu DNI, NIE o Pasaporte'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Clave" name="clave" tooltip="No son los códigos PIN de 4 dígitos"
          rules={[{required: true, message: 'Introduce tu contraseña'}]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item name="recordar" valuePropName="checked" wrapperCol={{offset: 8, span: 16}}>
          <Checkbox>Recordar mis datos</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit">
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha)(AccesoLogin) as React.ComponentType<FormLoginCustomProps>;
