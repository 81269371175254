import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";
import {IWithGoogleReCaptchaProps} from "react-google-recaptcha-v3";
import {Invitado, UsuarioBase} from "types/usuarios";

export interface ModalUsuarioCustomProps {
  // Si usuario no está fijado, se aceptan tanto invitados como usuarios
  tipo?: "usuario" | "invitado";
  visible: boolean;

  onCancelar(): void;

  onSeleccion(datos: UsuarioBase | Invitado): void;
}

export interface ModalUsuarioProps extends PropsFromRedux, IWithGoogleReCaptchaProps, RouteComponentProps, ModalUsuarioCustomProps {
}

export interface ModalUsuarioState {
  cargando: boolean;
  seleccion: Invitado | UsuarioBase | null;
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const GUARDAR_USUARIO = 'GUARDAR_USUARIO';
export const RESETEAR_SELECCION = 'RESETEAR_SELECCION';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface GuardarUsuarioAction {
  type: typeof GUARDAR_USUARIO;
  payload: { id: string };
}

interface ResetearSeleccionAction {
  type: typeof RESETEAR_SELECCION;
}


export type ModalUsuarioBusquedaActionTypes = CambiarCargandoAction
  | GuardarUsuarioAction
  | ResetearSeleccionAction;
