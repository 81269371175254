import {RouteComponentProps} from "react-router-dom";
import {PropsFromRedux} from "./actions";

export interface AdminSuperadminPermisosProps extends RouteComponentProps, PropsFromRedux {

}

export interface AdminSuperadminPermisosState {
  cargando: boolean;
  datos: PermisoDTO[];
  nuevo: boolean;
}

export interface PermisoDTO {
  id: string;
  nombre: string;
  permisos: string[];
}


export const CAMBIAR_CARGANDO = 'CAMBIAR_CARGANDO';
export const CAMBIAR_NUEVO_VISIBLE = 'CAMBIAR_NUEVO_VISIBLE';
export const GUARDAR_PERMISOS = 'GUARDAR_PERMISOS';

interface CambiarCargandoAction {
  type: typeof CAMBIAR_CARGANDO;
  payload: { cargando?: boolean }
}

interface CambiarNuevoVisible {
  type: typeof CAMBIAR_NUEVO_VISIBLE;
}

interface GuardarPermisosAction {
  type: typeof GUARDAR_PERMISOS;
  payload: { permisos: PermisoDTO[] };
}

export type AdminSuperadminPermisosActionTypes = CambiarCargandoAction
  | CambiarNuevoVisible
  | GuardarPermisosAction;
