import React from "react";
import {FabProps, FabState} from "components/fab/types";
import {Button} from "antd";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import {withRouter} from "react-router-dom";
import "./fab.less";

class Fab extends React.Component<FabProps, FabState> {
  constructor(props: FabProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <Button
      className="fab" type="primary" shape="round" icon={<Icon path={MDI.mdiFaceAgent} size={1}/>}
      size="large" target="_blank"
      href={"https://ayuda.pasoinfo.xyz/" + (this.props.location.pathname.startsWith("/admin") ? "admin/" : "")}
    >
      Ayuda
    </Button>;
  }
}

export default withRouter(Fab);
