import moment from "moment";
import {Asistente, Evento, EventoGrupo, Reserva} from "types/eventos";
import {Mesa} from "types/misc";

export function parsearFechasEvento(eventos: Evento[]) {
  eventos.forEach(e => {
    e.fecha = moment.utc(e.fecha);
  });
  return eventos;
}

export function parsearFechasEventoGrupo(grupos: EventoGrupo[]) {
  grupos.forEach(d => {
    d.fechaApertura = moment.utc(d.fechaApertura);
    d.fechaCierre = d.fechaCierre !== null ? moment.utc(d.fechaCierre) : null;
    d.eventos = parsearFechasEvento(d.eventos || []);
  });
  return grupos;
}

export function parsearFechasReservas(reservas: Reserva[]) {
  reservas.forEach(r => {
    r.fecha = moment.utc(r.fecha);
    r.fechaAsignadas = r.fechaAsignadas !== null ? moment.utc(r.fechaAsignadas) : null;
    r.evento = parsearFechasEvento([r.evento])[0];
  });
  return reservas;
}

export function parsearFechasAsistentes(asistentes: Asistente[]) {
  asistentes.forEach(a => {
    a.fecha = moment.utc(a.fecha);
    a.reserva = a.reserva !== null ? parsearFechasReservas([a.reserva])[0] : null;
    a.evento = parsearFechasEvento([a.evento])[0];

    a.fechaPago = a.fechaPago !== null ? moment.utc(a.fechaPago) : null;
    a.fechaEntrega = a.fechaEntrega !== null ? moment.utc(a.fechaEntrega) : null;
  });
  return asistentes;
}

export function parsearFechasReservasAsistentes(reservas: (Reserva | Asistente)[]) {
  reservas.forEach(e => {
    if ("numEntradas" in e) {
      e.fecha = moment.utc(e.fecha);
      e.fechaAsignadas = e.fechaAsignadas !== null ? moment.utc(e.fechaAsignadas) : null;
      e.evento = parsearFechasEvento([e.evento])[0];
    } else {
      e.fecha = moment.utc(e.fecha);
      e.reserva = e.reserva !== null ? parsearFechasReservas([e.reserva])[0] : null;
      e.evento = parsearFechasEvento([e.evento])[0];

      e.fechaPago = e.fechaPago !== null ? moment.utc(e.fechaPago) : null;
      e.fechaEntrega = e.fechaEntrega !== null ? moment.utc(e.fechaEntrega) : null;
    }
  });
  return reservas;
}

export function parsearFechasMesas(mesas: Mesa[]) {
  mesas.forEach(m => {
    m.inicio = moment.utc(m.inicio);
    m.fin = moment.utc(m.fin);
  });
  return mesas;
}
