import {Layout, Menu} from "antd";
import React from "react";
import {SidebarProps, SidebarState} from "components/sidebar/types";
import {withRouter} from "react-router-dom";
import {AppRoutesMenu, mapKeysToRoutes, mapRoutesToKeys} from "routes";
import * as MDI from "@mdi/js";
import Icon from "components/icon";
import {getNombre} from "utils/token";


export class Sidebar extends React.Component<SidebarProps, SidebarState> {
  constructor(props: SidebarProps) {
    super(props);
    this.state = {};
  }

  private navigateTo(key: string): void {
    this.props.history.push(mapKeysToRoutes(key));
  }

  render() {
    return (
      <Layout.Sider breakpoint="lg">
        <Menu
          theme="dark" mode="inline"
          selectedKeys={[mapRoutesToKeys(this.props.location.pathname)]}
          onClick={e => this.navigateTo(e.key.toString())}
        >
          <Menu.Item key={AppRoutesMenu.INICIO} icon={<Icon path={MDI.mdiViewDashboardOutline} size={1}/>}>
            Inicio
          </Menu.Item>
          <Menu.Item key={AppRoutesMenu.EVENTOS} icon={<Icon path={MDI.mdiCalendar} size={1}/>}>
            Eventos
          </Menu.Item>
          {getNombre() !== null ? <Menu.Item key={AppRoutesMenu.RESERVAS} icon={<Icon path={MDI.mdiAccountSupervisor} size={1}/>}>
            Mis Reservas
          </Menu.Item> : <></>}
        </Menu>
      </Layout.Sider>
    );
  }
}

export default withRouter(Sidebar);
