import React from "react";
import {InicioProps, InicioState} from "./types";
import {inicioReducer, initialState} from "./reducers";
import {cambiarCargando, CONNECTOR, guardarEnlaces, guardarEventos, guardarMesas, guardarReservas} from "./actions";
import {Link, withRouter} from "react-router-dom";
import {Endpoints, get} from "utils/http";
import {
  Avatar,
  Card,
  Col,
  Collapse,
  Descriptions,
  Empty,
  List,
  notification,
  Row,
  Spin,
  Statistic,
  Tag,
  Typography
} from "antd";
import moment from "moment";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import AppRoutes from "routes/index";
import {isSesionUsuario} from "utils/token";
import assert from "assert";
import {getEstadoReserva} from "routes/admin-reservas";
import "./index.less";

class Inicio extends React.Component<InicioProps, InicioState> {
  constructor(props: InicioProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const usaReservas: boolean = isSesionUsuario();
    let reqs: Promise<any>[];
    if (usaReservas) {
      reqs = [get(Endpoints.EVENTOS_INICIO), get(Endpoints.RESERVAS_INICIO), get(Endpoints.MISC_ENLACES), get(Endpoints.MISC_MESAS)]
    } else {
      reqs = [get(Endpoints.EVENTOS_INICIO), get(Endpoints.MISC_ENLACES), get(Endpoints.MISC_MESAS)];
    }

    Promise.all(reqs)
      .then(r => {
        let eventos, reservas, asistentes, enlaces, mesas;
        if (usaReservas) {
          [eventos, reservas, enlaces, mesas] = r;
          [reservas, asistentes] = reservas;
        } else {
          [eventos, enlaces, mesas] = r;
        }

        let state = inicioReducer(this.state, guardarEventos(eventos));
        if (usaReservas) state = inicioReducer(state, guardarReservas(reservas, asistentes));
        state = inicioReducer(state, guardarEnlaces(enlaces));
        state = inicioReducer(state, guardarMesas(mesas));
        state = inicioReducer(state, cambiarCargando(false));
        this.setState(state);
      })
      .catch(e => {
        notification.error({message: "Error descargando los datos", description: `"${e.mensaje}"`})
      });
  }

  render() {
    return <>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Spin spinning={this.state.cargando}>
            <Card bodyStyle={this.state.eventos.length > 0 ? {padding: 0} : undefined} title="Eventos próximos...">
              {this.state.eventos.length > 0 ? <List
                bordered
                itemLayout="horizontal"
                dataSource={this.state.eventos}
                renderItem={(evento, key) => {
                  return <Link to={AppRoutes.EVENTO.replace(":uuid", evento.id)} key={key}>
                    <List.Item
                      actions={evento.fechaApertura.isAfter(moment.utc()) ?
                        [<>Apertura: <Typography.Text>{evento.fechaApertura.local().fromNow()}</Typography.Text></>] :
                        undefined}
                    >
                      <List.Item.Meta
                        avatar={evento.imagen ? <Avatar src={evento.imagen}/> : undefined}
                        title={<>
                          {evento.eventos ? <Tag>{evento.eventos[0].fecha.format("L")}</Tag> : ""} {evento.nombre}
                        </>}
                        description={<Typography.Text ellipsis className="description-color">
                          {evento.descripcion}
                        </Typography.Text>}
                      />
                    </List.Item>
                  </Link>;
                }}
              /> : <Empty description="No hay eventos todavía, pero pronto los habrá :)"/>}
            </Card>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} xl={11} className="mobile-margin">
          {this.state.enlaces.length > 0 ? <Card bodyStyle={{padding: 0}}>
            <List
              bordered
              itemLayout="horizontal"
              dataSource={this.state.enlaces}
              renderItem={(enlace, key) => {
                const matches = enlace.enlace.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
                const domain = matches && matches[1];
                
                return <a target="_blank" href={enlace.enlace} rel="noreferrer">
                  <List.Item key={key}>
                    <List.Item.Meta
                      avatar={enlace.imagen ? <Avatar src={enlace.imagen}/> : undefined}
                      title={enlace.nombre}
                      description={domain || enlace.enlace}
                    />
                  </List.Item>
                </a>;
              }}
            />
          </Card> : <></>}
        </Col>
      </Row>

      <Row justify="space-between" style={{marginTop: 20}}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {isSesionUsuario() ? <>
            <Spin spinning={this.state.cargando}>
              <Card
                bodyStyle={this.state.reservas.length > 0 ? {padding: 0} : undefined} title="Mis futuras reservas..."
              >
                {this.state.reservas.length > 0 ? <List
                  bordered
                  itemLayout="horizontal"
                  dataSource={this.state.reservas}
                  renderItem={(r, key) => {
                    const evento = r.evento;
                    assert(evento.grupo !== undefined);
                    return <Link to={AppRoutes.EVENTO.replace(":uuid", evento.grupo.id)} key={key}>
                      <List.Item actions={[<Typography.Text>{r.evento.fecha.local().format('llll')}</Typography.Text>]}>
                        <List.Item.Meta
                          avatar={evento.grupo.imagen ? <Avatar src={evento.grupo.imagen}/> : undefined}
                          title={<>{getEstadoReserva(r)} {evento.nombre}</>}
                          description={evento.grupo.nombre}
                        />
                      </List.Item>
                    </Link>;
                  }}
                /> : <Empty description="No tienes ninguna reserva pendiente :("/>}
              </Card>
            </Spin>
          </> : <></>}
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} xl={11} className="mobile-margin">
          {this.state.mesas.length > 0 ? <Collapse bordered={false} defaultActiveKey={0}>
              {this.state.mesas.map((m, i) => {
                return <Collapse.Panel key={i} header={m.inicio.local().format("LLLL")}>
                  <Row>
                    <Col span={12}>
                      <Statistic
                        title={<><Icon path={MDI.mdiMapMarker} size={0.8}/> Localización</>}
                        value={m.localizacion}
                      />
                    </Col>
                    <Col span={12}>
                      {m.inicio.isAfter(moment.utc()) ?
                        <Statistic.Countdown title="Abre en..." value={m.inicio.unix() * 1000}/> :
                        <Statistic.Countdown title="Cierra en..." value={m.fin.unix() * 1000}/>}
                    </Col>
                  </Row>
                  <Row style={{marginTop: 10}}>
                    <Col>
                      <Descriptions bordered>
                        <Descriptions.Item label={<Typography.Text strong>Apertura</Typography.Text>}>
                          {m.inicio.local().format("LLLL")}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>Cierre</Typography.Text>}>
                          {m.fin.local().format("LLLL")}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                </Collapse.Panel>
              })}
            </Collapse> :
            <Empty description="Todavía no hemos publicado los horarios de la Mesa; consúltalo más tarde."/>}
        </Col>
      </Row>
    </>;
  }
}

export default CONNECTOR(withRouter(Inicio));
