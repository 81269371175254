import React from "react";
import {initialState, modalReservarReducer} from "components/modal-reservar/reducers";
import {CONNECTOR} from "components/modal-confirmar/actions";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {
  Alert,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Spin
} from "antd";
import {parseIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js/max";
import {ModalReservarCustomProps, ModalReservarProps, ModalReservarState} from "components/modal-reservar/types";
import {cambiarCargando, guardarGrupo, resetearGrupo} from "components/modal-reservar/actions";
import {Endpoints, get} from "utils/http";
import {EstadoEntrada, EventoGrupo, Reserva} from "types/eventos";
import assert from "assert";
import {Invitado} from "types/usuarios";
import moment from "moment";
import {isSesionUsuario} from "utils/token";

class ModalReservar extends React.Component<ModalReservarProps, ModalReservarState> {
  constructor(props: ModalReservarProps) {
    super(props);
    this.state = initialState;

    this.onFinish = this.onFinish.bind(this);
  }

  private FORM_REF = React.createRef<FormInstance>();

  private onFinish() {
    this.FORM_REF.current?.validateFields()
      .then(res => {
        let reservas: Reserva[] = [];
        assert(this.state.grupo && this.state.grupo.eventos);

        let invitado: Invitado | null = null;
        if (!this.props.permitirAnonimo || !isSesionUsuario()) {
          invitado = {
            pin: "",
            dni: this.FORM_REF.current?.getFieldValue('dni'),
            nombre: this.FORM_REF.current?.getFieldValue('nombre'),
            apellidos: this.FORM_REF.current?.getFieldValue('apellidos'),
            email: this.FORM_REF.current?.getFieldValue('email'),
            telefono: this.FORM_REF.current?.getFieldValue('telefono'),
          }
        }

        for (let evento of this.state.grupo.eventos) {
          let val = this.FORM_REF.current?.getFieldValue('e' + evento.id);
          if (!val || val === 0) continue;

          reservas.push({
            id: 0,
            evento: evento,
            usuario: null,
            invitado: invitado,
            fecha: moment.utc(),
            numEntradas: val,
            notas: "",
            fechaAsignadas: null,
            estado: EstadoEntrada.EN_COLA
          })
        }

        if (reservas.length === 0) {
          notification.warning({
            message: "Error",
            description: "Debes especificar al menos una entrada para un evento"
          });
          return;
        }

        this.props.onReservar(reservas);
      })
      .catch(res => {
      })
  }

  componentDidMount() {
    if (this.props.evento !== undefined) {
      get(Endpoints.EVENTO.replace(":uuid", this.props.evento))
        .then((res: EventoGrupo) => {
          this.setState(modalReservarReducer(modalReservarReducer(
            this.state,
            guardarGrupo(res)),
            cambiarCargando(false)));
        });
      return;
    }
  }

  componentDidUpdate(prevProps: Readonly<ModalReservarProps>, prevState: Readonly<ModalReservarState>, snapshot?: any) {
    if (prevProps.evento !== this.props.evento) {
      if (this.props.evento === undefined) {
        this.setState(modalReservarReducer(this.state, resetearGrupo()));
        return;
      }

      this.setState(modalReservarReducer(this.state, cambiarCargando(true)), () => {
        assert(this.props.evento !== undefined);
        get(Endpoints.EVENTO.replace(":uuid", this.props.evento))
          .then((res: EventoGrupo) => {
            this.setState(modalReservarReducer(modalReservarReducer(
              this.state,
              guardarGrupo(res)),
              cambiarCargando(false)));
          });
      });
      return;
    }
  }

  render() {
    return <Modal
      title={"Nueva Reserva" + (this.state.grupo !== null ? (" en " + this.state.grupo.nombre) : "")}
      visible={this.props.mostrar}
      onOk={() => this.onFinish()}
      onCancel={() => this.props.onCancelar()}
      width={1000}
    >
      <Form
        name="asistentes" ref={this.FORM_REF} labelCol={{span: 6}} wrapperCol={{span: 18}}
        initialValues={{'prefijo': '34'}} onFinish={() => this.onFinish()}
      >
        <Row>
          <Col xs={0} sm={0} md={0} lg={2} xl={2}/>
          <Col xs={24} sm={24} md={24} lg={20} xl={20}>
            {this.props.permitirAnonimo && isSesionUsuario() ? <></> : <>
              {this.props.permitirAnonimo ? <Alert
                message="Si creas una cuenta no tendrás que cubrir estos campos ninguna otra vez y podrás reservar las entradas mucho más rápido."
                type="info"
                showIcon
                style={{marginBottom: 30}}
              /> : <></>}

              <Form.Item label="Nombre Completo" name="nombreCompleto" style={{marginBottom: 0}} required={true}>
                <Form.Item
                  style={{display: 'inline-block', width: 'calc(35% - 12px)'}} name="nombre"
                  rules={[{required: true, message: 'Introduce el nombre'}]} hasFeedback
                >
                  <Input placeholder="Nombre"/>
                </Form.Item>
                <span style={{display: 'inline-block', width: '24px'}}/>
                <Form.Item
                  style={{display: 'inline-block', width: 'calc(65% - 12px)'}} name="apellidos"
                  rules={[{required: true, message: 'Introduce los apellidos'}]} hasFeedback
                >
                  <Input placeholder="Apellidos"/>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label="DNI / NIE / Pasaporte" name="dni"
                rules={[{required: true, message: 'Introduce el DNI, NIE o Pasaporte'}]}
              >
                <Input placeholder="12346578Z"/>
              </Form.Item>

              <Form.Item
                label="Correo Electrónico" name="email" hasFeedback
                rules={[
                  {required: true, message: 'Introduce tu correo electrónico'},
                  {type: "email", message: 'Introduce un email válido'}
                ]}
              >
                <Input placeholder="Email"/>
              </Form.Item>

              <Form.Item
                name="telefono" label="Número de Móvil" hasFeedback
                rules={[
                  {
                    validator: (rule: any, value: string) => {
                      let prefijo = this.FORM_REF.current?.getFieldValue("prefijo");
                      try {
                        let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + value));
                        if (!numero || !numero.isValid()) throw new Error('Número no válido');
                        return Promise.resolve();
                      } catch (error) {
                      }
                      return Promise.reject();
                    }, message: 'Introduce un número de móvil válido', required: true
                  }
                ]}
              >
                <Input addonBefore={<Form.Item noStyle name="prefijo">
                  <Select style={{width: 100}}>
                    <Select.Option value="34">+34</Select.Option>
                  </Select>
                </Form.Item>} style={{width: '100%'}} onChange={e => {
                  let prefijo = this.FORM_REF.current?.getFieldValue("prefijo");
                  try {
                    let numero = parsePhoneNumber(parseIncompletePhoneNumber("+" + prefijo + e.target.value));
                    if (numero) {
                      let valores: any = {};
                      valores['telefono'] = numero.formatNational();
                      this.FORM_REF.current?.setFieldsValue(valores);
                    }
                  } catch (error) {
                  }
                }}/>
              </Form.Item>
            </>}

            <Divider orientation="left"># de Entradas</Divider>

            <Spin spinning={this.state.cargando}>
              {this.state.grupo?.eventos?.map(e => <Form.Item name={"e" + e.id} label={e.nombre}>
                <InputNumber min={0} max={e.entradasMax > 0 ? e.entradasMax : undefined} defaultValue={0}/>
              </Form.Item>)}
            </Spin>
          </Col>
          <Col xs={0} sm={0} md={0} lg={2} xl={2}/>
        </Row>
      </Form>
    </Modal>;
  }
}

export default compose(withRouter, CONNECTOR)(ModalReservar) as React.ComponentType<ModalReservarCustomProps>;
