import React from "react";
import {AdminMesaMesasProps, AdminMesaMesasState} from "routes/admin-mesa-mesas/types";
import {compose} from "redux";
import {cambiarCargando, CONNECTOR, guardarMesas} from "routes/admin-mesa-mesas/actions";
import {withRouter} from "react-router-dom";
import {adminMesaMesasReducer, initialState} from "routes/admin-mesa-mesas/reducers";
import {Button, Col, notification, Row, Space, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import {Mesa} from "types/misc";
import {EditableCell, EditableRow} from "components/editable-cell";
import {Endpoints, get, post} from "utils/http";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
// import {MenuOutlined} from '@ant-design/icons';
import {SortableContainer as SC, SortableElement,} from 'react-sortable-hoc';

// TODO: Dragging
// const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);
const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainer = SC((props: any) => <tbody {...props} />);

class AdminMesaMesas extends React.Component<AdminMesaMesasProps, AdminMesaMesasState> {
  constructor(props: AdminMesaMesasProps) {
    super(props);
    this.state = initialState;

    this.getColumnasMesa = this.getColumnasMesa.bind(this);

    this.handleAddMesas = this.handleAddMesas.bind(this);
    this.handleSaveMesas = this.handleSaveMesas.bind(this);
    this.handleDeleteMesas = this.handleDeleteMesas.bind(this);

    this.actualizarMesas = this.actualizarMesas.bind(this);
  }

  private DraggableContainer = (props: any) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={props.onSortEnd}
      {...props}
    />
  );

  private DraggableBodyRow = ({className, style, ...restProps}: any) => {
    const {mesas} = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = mesas.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  private getColumnasMesa(): (ColumnsType<Mesa> & { editable?: boolean }) {
    return [
      /* {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      }, */
      {
        title: 'Inicio',
        dataIndex: 'inicio',
        key: 'inicio',
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'inicio',
          title: 'Inicio',
          handleSave: this.handleSaveMesas,
          timePicker: true,
        }),
        render: (v, r) => r.inicio.local().format("DD/MM/YYYY HH:mm"),
      },
      {
        title: 'Fin',
        dataIndex: 'fin',
        key: 'fin',
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'fin',
          title: 'Fin',
          handleSave: this.handleSaveMesas,
          timePicker: true,
        }),
        render: (v, r) => r.fin.local().format("DD/MM/YYYY HH:mm"),
      },
      {
        title: 'Localización',
        dataIndex: 'localizacion',
        key: 'localizacion',
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'localizacion',
          title: 'Localización',
          handleSave: this.handleSaveMesas,
        }),
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        editable: false,
        fixed: 'right',
        width: 75,
        align: "center",
        render: (visible, value) => {
          return <Space>
            <Tooltip title={value.visible ? "Ocultar" : "Mostrar"}>
              <Button
                type={value.visible ? "primary" : "default"}
                icon={<Icon
                  path={value.visible ? MDI.mdiEye : MDI.mdiEyeOff} size={0.6} style={{marginLeft: 4, marginTop: -5}}
                />}
                onClick={() => this.handleSaveMesas({...value, visible: !value.visible})} size="small"
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                danger icon={<Icon path={MDI.mdiTrashCan} size={0.6} style={{marginLeft: 4, marginTop: -5}}/>}
                onClick={() => this.handleDeleteMesas(value)} size="small"
              />
            </Tooltip>
          </Space>
        }
      }
    ]
  }

  private handleAddMesas() {
    this.setState(adminMesaMesasReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_MESA.replace(":id", "0"))
        .then(() => this.actualizarMesas());
    });
  }

  private handleSaveMesas(mesa: Mesa) {
    this.setState(adminMesaMesasReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_MESA.replace(":id", mesa.id.toString()), mesa)
        .then(() => this.actualizarMesas());
    });
  }

  private handleDeleteMesas(mesa: Mesa) {
    this.setState(adminMesaMesasReducer(this.state, cambiarCargando(true)), () => {
      post(Endpoints.ADMIN_MESA_MESA_ELIMINAR.replace(":id", mesa.id.toString()))
        .then(() => this.actualizarMesas());
    });
  }

  private actualizarMesas() {
    get(Endpoints.ADMIN_MESA_MESAS)
      .then((res: Mesa[]) => {
        let state = adminMesaMesasReducer(this.state, guardarMesas(res));
        this.setState(adminMesaMesasReducer(state, cambiarCargando(false)));
      })
  }

  componentDidMount() {
    get(Endpoints.ADMIN_MESA_MESAS)
      .then(mesas => {
        let state = adminMesaMesasReducer(this.state, guardarMesas(mesas));
        this.setState(adminMesaMesasReducer(state, cambiarCargando(false)));
      })
      .catch(e => {
        notification.error({message: 'Error', description: 'Error descargando los datos'});
      });
  }

  render() {
    return <Row justify="space-between">
      <Col span={24} style={{marginBottom: 10}}>
        <Table
          key='id'
          loading={this.state.cargando}
          columns={this.getColumnasMesa()}
          size="middle"
          rowClassName={() => 'editable-row'}

          components={{
            body: {
              // wrapper: this.DraggableContainer,
              row: EditableRow,
              cell: EditableCell
            }
          }}
          dataSource={this.state.mesas}
          scroll={{x: 1000}}
          pagination={false}
          footer={() => <Button
            icon={<Icon path={MDI.mdiPlus}/>}
            onClick={() => this.handleAddMesas()}
          >
            Nueva Mesa
          </Button>}
        />
      </Col>
    </Row>;
  }
}

export default compose(CONNECTOR, withRouter)(AdminMesaMesas) as React.ComponentType;
