import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {
  AdminEventosActionTypes,
  CAMBIAR_CARGANDO,
  GUARDAR_DATOS,
  GUARDAR_FUNCION_NUEVO_EVENTO,
  MOSTRAR_NUEVO_EVENTO,
  RespuestaDTO
} from "./types";
import {EventoGrupo} from "types/eventos";

export function cambiarCargando(cargando?: boolean): AdminEventosActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarDatos(respuesta: RespuestaDTO): AdminEventosActionTypes {
  return {
    type: GUARDAR_DATOS,
    payload: {...respuesta}
  }
}

export function mostrarNuevoEvento(mostrar?: boolean): AdminEventosActionTypes {
  return {
    type: MOSTRAR_NUEVO_EVENTO,
    payload: {mostrar}
  }
}

export function guardarFuncionNuevoEvento(fn: () => Promise<EventoGrupo>): AdminEventosActionTypes {
  return {
    type: GUARDAR_FUNCION_NUEVO_EVENTO,
    payload: {fn}
  }
}


const mapState = (state: RootState) => ({
  cargando: state.adminEventos.cargando,
  datos: state.adminEventos.datos,
  total: state.adminEventos.total,

  nuevo: {
    visible: state.adminEventos.visible,
    getEventoGrupo: state.adminEventos.getEventoGrupo
  },
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarDatos: guardarDatos,

  mostrarNuevoEvento: mostrarNuevoEvento,
  guardarFuncionNuevoEvento: guardarFuncionNuevoEvento,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
