import React from "react";
import {
  DrawerSelectorEventoCustomProps,
  DrawerSelectorEventoProps,
  DrawerSelectorEventoState
} from "components/drawer-selector-evento/types";
import {drawerSelectorEventoReducer, initialState} from "components/drawer-selector-evento/reducers";
import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Form,
  FormInstance,
  Input,
  List,
  notification,
  Popover, Typography
} from "antd";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {cambiarCargando, CONNECTOR, guardarDatos} from "components/drawer-selector-evento/actions";
import assert from "assert";
import Icon from "components/icon";
import * as MDI from "@mdi/js";
import logoPng from "assets/paso.png";
import {Endpoints, get} from "utils/http";
import {Moment} from "moment";
import {EventoGrupo} from "types/eventos";

class DrawerSelectorEvento extends React.Component<DrawerSelectorEventoProps, DrawerSelectorEventoState> {
  constructor(props: DrawerSelectorEventoProps) {
    super(props);
    this.state = initialState;

    this.onCancelar = this.onCancelar.bind(this);
    this.onAceptar = this.onAceptar.bind(this);
    this.onBuscar = this.onBuscar.bind(this);
  }

  private FORM_REF = React.createRef<FormInstance>();

  private onCancelar() {
    this.props.onCerrar();
  }

  private onAceptar() {
    assert(this.state.seleccion !== null);
    this.props.onEvento(this.state.seleccion);
  }

  private onBuscar() {
    this.setState(drawerSelectorEventoReducer(this.state, cambiarCargando(true)), () => {
      let nombre = this.FORM_REF.current?.getFieldValue('nombre');
      if (!nombre || nombre.trim() === "") {
        nombre = undefined;
      }

      let dateRange = this.FORM_REF.current?.getFieldValue('fechas');
      let inicial: Moment | undefined = undefined, final: Moment | undefined = undefined;
      if (dateRange) {
        inicial = dateRange[0];
        final = dateRange[0];
      }

      let url = Endpoints.ADMIN_EVENTOS_BUSCAR + "?";
      if (nombre) url += "nombre=" + encodeURIComponent(nombre) + "&";
      if (inicial) url += "fechaInicio=" + inicial.unix() + "&";
      if (final) url += "fechaFin=" + final.unix() + "&";
      get(url)
        .then((res: EventoGrupo[]) => {
          let state = drawerSelectorEventoReducer(this.state, guardarDatos(res));
          state = drawerSelectorEventoReducer(state, cambiarCargando(false));
          this.setState(state);
        })
        .catch(e => {
          notification.error({message: "Error", description: e.descripcion});
          this.setState(drawerSelectorEventoReducer(this.state, cambiarCargando(false)));
        });
    });
  }

  render() {
    return <Drawer
      title="Seleccionar evento..."
      width={756}
      onClose={this.onCancelar}
      visible={this.props.visible}
    >
      <Form
        name="buscador_evento" onFinish={this.onBuscar} labelCol={{span: 8}} wrapperCol={{span: 16}}
        ref={this.FORM_REF}
      >
        <Form.Item label="Búsqueda por nombre" name="nombre" hasFeedback>
          <Input placeholder="Nombre" disabled={this.state.cargando}/>
        </Form.Item>

        <Form.Item name="fechas" label="Búsqueda por fecha">
          <DatePicker.RangePicker style={{width: "100%"}} allowEmpty={[true, true]} disabled={this.state.cargando}/>
        </Form.Item>

        <Button
          type="primary" htmlType="submit" block icon={<Icon path={MDI.mdiMagnify}/>} loading={this.state.cargando}
        >
          Buscar
        </Button>

        <Divider/>

        {this.state.grupos.length === 0 ? <Empty description="Sin resultados"/> : <List>
          {this.state.grupos.map(g => <List.Item onClick={() => this.props.onEvento(g)} style={{cursor: "pointer"}}>
            <Popover title={"Apertura: " + g.fechaApertura.local().format("DD/MM/YYYY HH:mm")} content={<List>
              {g.eventos?.map(e => <List.Item>
                <Typography.Text type="secondary">
                  {e.fecha.local().format("DD/MM/YYYY HH:mm")}
                </Typography.Text> {e.nombre}
              </List.Item>)}
            </List>}>
              <List.Item.Meta
                avatar={<Avatar src={g.imagen || logoPng}/>}
                title={g.nombre}
                description={g.descripcion}
              />
            </Popover>
          </List.Item>)}
        </List>}
      </Form>
    </Drawer>;
  }
}

export default compose(withRouter, CONNECTOR)(DrawerSelectorEvento) as React.ComponentType<DrawerSelectorEventoCustomProps>;
