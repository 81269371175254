import React from "react";
import {AccesoVerificarProps, AccesoVerificarState} from "./types";
import {accesoVerificarReducer, initialState} from "./reducers";
import {cambiarCargando, CONNECTOR} from "./actions";
import {withRouter} from "react-router-dom";
import {Button, Card, Form, FormInstance, Input, notification, Spin} from "antd";
import {Endpoints, get, post} from "utils/http";
import AppRoutes from "routes";
import {compose} from "redux";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";

class AccesoVerificar extends React.Component<AccesoVerificarProps, AccesoVerificarState> {
  constructor(props: AccesoVerificarProps) {
    super(props);
    this.state = initialState;

    this.onVerificar = this.onVerificar.bind(this);
  }

  private FORM_VERIFICAR = React.createRef<FormInstance>();

  componentDidMount() {
    get(Endpoints.ACCESO_VERIFICAR + "?token=" + this.props.match.params.token)
      .then(res => {
        this.FORM_VERIFICAR.current?.setFieldsValue({dni: res.dni, email: res.email});
        this.setState(accesoVerificarReducer(this.state, cambiarCargando(false)));
      })
      .catch(res => {
        notification.error({
          message: 'Error',
          description: res.mensaje,
          placement: 'topRight'
        });
        this.props.history.push(AppRoutes.ACCESO);
      })
  }

  private onVerificar() {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      return;
    }

    this.setState(accesoVerificarReducer(this.state, cambiarCargando(true)), () => {
      executeRecaptcha('acceso_verificar').then(token => {
        post(Endpoints.ACCESO_VERIFICAR, {
          'token': this.props.match.params.token,
          'clave': this.FORM_VERIFICAR.current?.getFieldValue('clave'),
          'recaptcha': token,
        })
          .then(res => this.setState(accesoVerificarReducer(this.state, cambiarCargando(false)), () => {
            notification.success({
              message: 'Contraseña guardada',
              placement: 'topRight'
            });
            this.props.history.push(AppRoutes.ACCESO);
          }))
          .catch(res => this.setState(accesoVerificarReducer(this.state, cambiarCargando(false)), () => {
            notification.error({
              message: 'Error en el guardado de la contraseña',
              description: res.mensaje,
              placement: 'topRight'
            })
          }));
      });
    });
  }

  render() {
    return <>
      <Spin tip="Cargando..." spinning={this.state.cargando} size="large">
        <Card>
          <Form
            name="login" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={{recordar: true}}
            size="large" onFinish={this.onVerificar} ref={this.FORM_VERIFICAR}
          >
            <Form.Item label="DNI / NIE / Pasaporte" name="dni"><Input disabled/></Form.Item>
            <Form.Item label="Email" name="email"><Input disabled/></Form.Item>

            {/* TODO: Validar seguridad de la contraseña */}
            <Form.Item
              label="Nueva Clave" name="clave" hasFeedback
              rules={[{required: true, message: 'Introduce la contraseña que quieras'}]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item
              label="Repetir Clave" name="clave2" hasFeedback
              rules={[
                {required: true, message: 'Confirma tu nueva contraseña'},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('clave') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                  },
                }),
              ]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </>;
  }
}

export default compose(withRouter, withGoogleReCaptcha, CONNECTOR)(AccesoVerificar) as React.ComponentType;
