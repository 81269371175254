import {RootState} from "store/reducer";
import {connect, ConnectedProps} from "react-redux";
import {CAMBIAR_CARGANDO, GUARDAR_GRUPO, ModalReservarActionTypes} from "components/modal-reservar/types";
import {EventoGrupo} from "types/eventos";

export function cambiarCargando(cargando: boolean): ModalReservarActionTypes {
  return {
    type: CAMBIAR_CARGANDO,
    payload: {cargando}
  }
}

export function guardarGrupo(grupo: EventoGrupo): ModalReservarActionTypes {
  return {
    type: GUARDAR_GRUPO,
    payload: {grupo}
  }
}

export function resetearGrupo(): ModalReservarActionTypes {
  return {
    type: GUARDAR_GRUPO,
    payload: {grupo: undefined}
  }
}

const mapState = (state: RootState) => ({
  cargando: state.modalReservar.cargando,
  grupo: state.modalReservar.grupo,
});

const mapDispatch = {
  cambiarCargando: cambiarCargando,
  guardarGrupo: guardarGrupo,
}

export const CONNECTOR = connect(mapState, mapDispatch);
export type PropsFromRedux = ConnectedProps<typeof CONNECTOR>;
