import {
  AdminVentasActionTypes,
  AdminVentasState,
  CAMBIAR_CARGANDO,
  CAMBIAR_DRAWER_VISIBLE,
  CAMBIAR_LECTOR_VISIBLE,
  GUARDAR_DATOS,
  GUARDAR_FILTROS,
  MOSTRAR_NUEVO,
  SELECCIONAR_ASISTENTES
} from "routes/admin-ventas/types";
import {parsearFechasAsistentes} from "components/form-evento-admin/utils";

export const DEFAULT_FILTROS_PAGE = 1;
export const DEFAULT_FILTROS_SIZE = 50;

export const initialState: AdminVentasState = {
  cargando: true,
  datos: [],
  total: 0,
  drawerVisible: false,
  lectorVisible: false,

  filtros: {
    page: DEFAULT_FILTROS_PAGE,
    size: DEFAULT_FILTROS_SIZE,
  },

  seleccionados: [],
  nuevo: false
}


export function adminVentasReducer(state = initialState, action: AdminVentasActionTypes): AdminVentasState {
  let newState: AdminVentasState = JSON.parse(JSON.stringify(state));
  newState.datos = parsearFechasAsistentes(newState.datos);
  newState.seleccionados = parsearFechasAsistentes(newState.seleccionados);

  switch (action.type) {
    case CAMBIAR_CARGANDO:
      newState.cargando = action.payload.cargando || !state.cargando;
      return newState;

    case GUARDAR_DATOS:
      newState.total = action.payload.total;
      newState.datos = parsearFechasAsistentes(action.payload.asistentes);
      return newState;

    case CAMBIAR_DRAWER_VISIBLE:
      newState.drawerVisible = action.payload.visible;
      return newState;

    case CAMBIAR_LECTOR_VISIBLE:
      newState.lectorVisible = action.payload.visible;
      return newState;

    case GUARDAR_FILTROS:
      newState.filtros = {
        page: action.payload.page,
        size: action.payload.size,
        sort: action.payload.sort,
        order: action.payload.order,
        filtroEstado: action.payload.filtroEstado,
      }
      return newState;

    case SELECCIONAR_ASISTENTES:
      newState.seleccionados = action.payload.asistentes;
      return newState;

    case MOSTRAR_NUEVO:
      newState.nuevo = action.payload.mostrar;
      return newState;

    default:
      return state;
  }
}
